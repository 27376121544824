
import { Box, Button, Link, MenuItem, Paper, Popover, TextField, useMediaQuery } from "@mui/material";
import { BoxFC, BoxFR } from "components/BoxCustom";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import ShippingJobDialog from "./ShippingJobDialog";
import { shippingProcessColumns } from "./columns";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { shippingApiNm } from "./shippingApiNm";
import { addIdForDataGrid } from "utils";
import PackingListDialog from "./PackingListDialog";
import { clearFilterData, initFitlterData } from "./initData";
import SearchButton from "components/buttons/SearchButton";
import ClearButton from "components/buttons/ClearButton";
import { shippingInvoiceColNm } from "./constant";
import ShippingInvoiceDialog from "./ShippingInvoiceDialog";
import AddFileWithSendDialog from "./AddFileWithSendDialog";
import ED0209Dialog from "./ED0209Dialog";
import DateTimeSelect from "components/DateTimeSelect/DateTimeSelect";
import { UserContext } from "contexts/UserContext";
import UpdateTextDialog from "./UpdateTextDialog";
import { blue, blueGrey, cyan, deepPurple, green, lightGreen, red, teal } from "@mui/material/colors";
import ApproveDialog from "./ApproveDialog";
import dayjs from "dayjs";
import RejectDetailDialog from "./RejectDetailDialog";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ApproveCDDialog from "./ApproveCDDialog";
import { AddCircleRounded, ArticleRounded, EmailRounded, FilterListRounded } from "@mui/icons-material";
import ReceiveEDMallConfirmDialog from "./CCEDMallConfirmDialog";
import PopoverBarebone from "components/PopoverBarebone";
import ComboBox from "components/ComboBox";
import AddShippingInvoiceDialog from "./AddShippingInvoiceDialog";

let lastFilter = null
let selectedId = null
let selectedJobId = null
let dialogTitle = ""
let selectedColNm = ""
let selectedCusId = null
let oldTextData = ""
let filePopoverArr = []
let shippingAccounts = []
let localAccountCombo = []

let shpFileTypId = null

const ShippingProcess = () => {
  const { ax, msData } = useContext(GlobalStateContext)
  const { user } = useContext(UserContext)

  const readOnlyUser = useMemo(() => user.AccTypId !== 1, [user])

  const { queryParam, queryValue } = useParams()

  const [dialogShippingJobOpen, setDialogShippingJobOpen] = useState(false)
  const [dialogPLOpen, setDialogPLOpen] = useState(false)
  const [dialogApproveOpen, setDialogApproveOpen] = useState(false)
  const [dialogCDApproveOpen, setDialogCDApproveOpen] = useState(false)
  const [dialogShippingInvoiceOpen, setDialogShippingInvoiceOpen] = useState(false)
  const [dialogAddFileWithSendOpen, setDialogAddFileWithSendOpen] = useState(false)
  const [dialogED0209Open, setDialogED0209Open] = useState(false)
  const [dialogUpdateTextOpen, setDialogUpdateTextOpen] = useState(false)
  const [dialogRejectDetailOpen, setDialogRejectDetailOpen] = useState(false)
  const [dialogCCEDMailConfirm, setDialogCCEDMailConfirm] = useState(false)
  const [dialogAddShippingProcessOpen, setDialogAddShippingProcessOpen] = useState(false)
  const [dataTable, setDataTable] = useState([])
  const [cusShippingCombo, setCusShippingCombo] = useState([])
  // const [anchorElEdit, setAnchorElEdit] = useState(null)
  const [filterData, setFilterData] = useState({ ...initFitlterData })

  const [anchorEl, setAnchorEl] = useState(null);

  const w800 = useMediaQuery('(min-width:800px)');

  const popOverOpen = Boolean(anchorEl);


  const getData = useCallback((filter) => {
    ax.post(shippingApiNm.getShippingInvoice, filter).then(value => {
      if (value.data) {
        for (const row of value.data) {
          if (row.EDCfs) {
            const edCfs = JSON.parse(row.EDCfs)
            let lastIsCf = null
            let runTm = dayjs(0)
            let numRj = 0
            for (const edCf of edCfs) {
              edCf.CreTm = dayjs(edCf.CreTm)
              if (edCf.IsCf === 0) {
                numRj++
              }
              if (edCf.CreTm.isAfter(runTm)) {
                runTm = edCf.CreTm
                lastIsCf = edCf.IsCf
              }
            }
            row.EDCfs = edCfs
            row.LastIsCf = lastIsCf
            row.NumRj = numRj
          }
        }
        setDataTable(addIdForDataGrid(value.data, "ShpInvId"))
        lastFilter = { ...filter }
      }
    })
  }, [ax])

  const filterProps = useCallback((name) => ({
    size: "small",
    label: shippingInvoiceColNm[name],
    value: filterData[name] || "",
    sx: { width: 150 },
    onChange: (e) => setFilterData(o => ({ ...o, [name]: e.target.value || null })),
    onKeyUp: e => { if (e.code === "Enter" || e.code === "NumpadEnter") { getData(filterData) } },
  }), [filterData, getData])

  const filterPropsMobile = useCallback((name) => ({
    ...filterProps(name),
    sx: { width: "100%" },
  }), [filterProps])
  const dateTimeFilterProps = useCallback((label, filterSt, filterEn) => {
    return {
      sx: { width: 180 },
      label: label,
      start: filterData[filterSt],
      end: filterData[filterEn],
      setSelectDate: (start, end) => setFilterData(o => ({ ...o, [filterSt]: start, [filterEn]: end })),
      getData: () => getData(filterData)
    }
  }, [filterData, getData])

  const handleSearchButtonClick = useCallback(() => {
    getData(filterData)
  }, [getData, filterData])

  const handleClearFilterData = useCallback(() => {
    setFilterData({ ...clearFilterData })
  }, [])

  // const handleAddShippingProcess = useCallback(() => {
  //   //TODO:: add dialog add shipping process
  // }, [])

  const handleOpenShippingJob = useCallback(() => {
    setDialogShippingJobOpen(true)
  }, [])

  const onFinish = useCallback((data) => {
    if (data) {
      setDataTable(addIdForDataGrid(data, "ShpInvId"))
    } else {
      getData({ ...lastFilter })
    }
  }, [getData])

  const handleFileClick = useCallback((shpInvId, values, field, row) => (e) => {
    console.log("values::", values)
    if (field === "NumRj") {
      selectedId = shpInvId
      setDialogRejectDetailOpen(true)
    } else {
      if (values.length === 1) {
        window.open(`${window.location.origin}/file/shipping/${shpInvId}/${values[0].fileNm}`)
      } else {
        filePopoverArr = values.map(file => ({
          url: `${window.location.origin}/file/shipping/${shpInvId}/${file.fileNm}`,
          label: file.label
        }))
        setAnchorEl(e.currentTarget)
      }
    }
    // return;
    // const fileNms = fileNmStr.split(",")
    // console.log({fileNms})
    // if(fileNms.length === 1){
    //   window.open(`${window.location.origin}/file/shipping/${shpInvId}/${fileNms[0]}`)
    // } else{
    //   fileLinks = fileNms.map(fileNm => `${window.location.origin}/file/shipping/${shpInvId}/${fileNm}`)
    //   setAnchorEl(e.currentTarget)
    // }
  }, [])

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, [])

  const handleDeleteTime = useCallback((colNm, row) => async (e) => {
    const postData = {
      ShpInvId: row.id,
      ColValues: {
        [colNm]: null,
      },
      getArgs: lastFilter
    }

    if (colNm === "ED0409CreTm") {
      postData.ColValues.ED0409SndTm = null
      await ax.post(shippingApiNm.deleteFileByType, { ShpInvId: row.id, ShpFileTypId: 2 })
    } else if (colNm === "ED0309CreTm") {
      await ax.post(shippingApiNm.deleteFileByType, { ShpInvId: row.id, ShpFileTypId: 2 })
      postData.ColValues.ED0309SndTm = null
    } else if (colNm === "ED0209CreTm") {
      await ax.post(shippingApiNm.deleteFileByType, { ShpInvId: row.id, ShpFileTypId: 2 })
      postData.ColValues.ED0209SndTm = null
      postData.ColValues.EDNo = null
    } else if (colNm === "CDCreTm") {
      await ax.post(shippingApiNm.deleteFileByType, { ShpInvId: row.id, ShpFileTypId: 3 })
      postData.ColValues.CDSndTm = null
      postData.ColValues.CDCreAccId = null
      postData.ColValues.CDAprvTm = null
    } else if (colNm === "EDCreTm") {
      await ax.post(shippingApiNm.deleteFileByType, { ShpInvId: row.id, ShpFileTypId: 2 })
      postData.ColValues.EDSndTm = null
      postData.ColValues.EDCreAccId = null
      postData.ColValues.EDAprvTm = null
      postData.ColValues.EDCfTm = null
    } else if (colNm === "EDSndTm") {
      postData.ColValues.EDCfTm = null
    } else if (colNm === "PLCreTm") {

      await ax.post(shippingApiNm.deleteFileByType, { ShpInvId: row.id, ShpFileTypId: 1 })
      postData.ColValues.IP_InvNo = null
      postData.ColValues.IP_PO = null
      postData.ColValues.IP_ETD = null
      postData.ColValues.IP_InvDte = null
      postData.ColValues.IP_Csgn = null
      postData.ColValues.OwnAccId = null
      postData.ColValues.OwnAccNm = null
      postData.ColValues.OwnAccEmail = null

    }
    ax.post(shippingApiNm.updateShippingInvoiceColumns, postData).then(value => {
      if (value.data) {
        setDataTable(addIdForDataGrid(value.data, "ShpInvId"))
      }
    })

  }, [ax])

  const handleCellEdit = useCallback((params) => {
    // setDataRows(o => {
    //   // console.log("in handleCellEdit o::", o)
    //   const result = [...o]
    //   const foundRow = result.find(item => item.id === params.id)
    //   if (foundRow) {
    //     foundRow[params.field] = params.value
    //     if (params.field === "Total") {
    //       foundRow.WhtPr = params.value * foundRow.WhtPrct / 100
    //     }
    //   }
    //   return result
    // })
  }, [])

  const handleRowDoubleClick = useCallback((params) => {
    selectedId = params.id
    setDialogShippingInvoiceOpen(true)
  }, [])

  const updateTime = useCallback((id, colNm) => {
    const postData = {
      ShpInvId: id,
      ColNm: colNm,
      getArgs: lastFilter
    }
    ax.post(shippingApiNm.updateShippingInvoiceTime, postData).then(value => {
      if (value.data) {
        setDataTable(addIdForDataGrid(value.data, "ShpInvId"))
      }
    })
  }, [ax])

  const handleCellClick = useCallback((params) => {
    if (["Note", "Rmk", "EDNo", "Cnsg", "PO", "InvNo"].includes(params.field)) {
      selectedId = params.id
      selectedColNm = params.field
      oldTextData = params.value
      setDialogUpdateTextOpen(true)
    }
  }, [])

  const handleButtonClick = useCallback((colNm, row) => (e) => {
    console.log({ colNm, row })

    selectedId = row.id
    selectedCusId = row.CusId
    selectedJobId = row.JobId
    if (colNm === "PLCreTm") {
      setDialogPLOpen(true)
    } else if (colNm === "EDCreTm") {
      dialogTitle = "เพิ่ม Draf ใบขน"
      selectedColNm = "EDCreTm"
      shpFileTypId = 2
      setDialogAddFileWithSendOpen(true)
    } else if (colNm === "CDCreTm") {
      dialogTitle = "ใบกำกับตู้"
      selectedColNm = "CDCreTm"
      shpFileTypId = 3
      setDialogAddFileWithSendOpen(true)
    } else if (colNm === "ED0309CreTm") {
      dialogTitle = "ใบขน 0309"
      selectedColNm = "ED0309CreTm"
      shpFileTypId = 2
      setDialogAddFileWithSendOpen(true)
    } else if (colNm === "ED0409CreTm") {
      dialogTitle = "ใบขน 0409"
      selectedColNm = "ED0409CreTm"
      shpFileTypId = 2
      setDialogAddFileWithSendOpen(true)
    } else if (colNm === "PayCstmTm") {
      dialogTitle = "ใบเสร็จศุลกากร"
      selectedColNm = "PayCstmTm"
      shpFileTypId = 5
      setDialogAddFileWithSendOpen(true)
    } else if (colNm === "EDAprvTm") {
      setDialogApproveOpen(true)
    } else if (colNm === "CDAprvTm") {
      setDialogCDApproveOpen(true)
    } else if (colNm === "ED0209CreTm") {
      setDialogED0209Open(true)
    } else if (["EDCfTm"].includes(colNm)) {
      updateTime(row.id, colNm)
    } else if (["EDSndTm", "ED0209SndTm", "ED0309SndTm", "ED0409SndTm", "CDSndTm"].includes(colNm)) {
      const postData = {
        ShpInvId: selectedId,
        ColNm: colNm,
        getArgs: lastFilter
      }
      ax.post(shippingApiNm.sendEmail, postData).then(value => {
        if (value.data) {
          setDataTable(addIdForDataGrid(value.data, "ShpInvId"))
        }
      })
    }

  }, [updateTime, ax])

  const handleAddShippingProcess = useCallback(() => {
    setDialogAddShippingProcessOpen(true)
  }, [])

  useEffect(() => {
    const filter = { ...initFitlterData }
    if (queryParam && queryValue) {
      filter[queryParam] = queryValue
      setFilterData(filter)
    }
    getData(filter)
  }, [getData, queryParam, queryValue])

  useEffect(() => {
    ax.post(shippingApiNm.getShippingAccount, {}).then(value => {
      if (value.data) {
        shippingAccounts = value.data.shippingAccounts
        localAccountCombo = value.data.localAccounts?.map(acc => ({ id: acc.AccId, label: `${acc.FName} ${acc.LName}(${acc.NName})` })) || []
      }
    })

    ax.post(shippingApiNm.getShippingJob, {}).then(value => {
      if(value.data){
        const sjIds = value.data.map(sj => sj.JobId)
        const cusIds = msData.jobs.filter(job=>sjIds.includes(job.JobId)).map(job=>job.CusId)
        setCusShippingCombo(msData.cusCombo.filter(cus => cusIds.includes(cus.id)))
      } else {
        setCusShippingCombo(msData.cusCombo)
      }
    })
  }, [ax, msData.cusCombo, msData.jobs])

  console.log("dataTable::", dataTable)
  return (
    <BoxFC height="100%">
      {w800 ? (
        <BoxFR>
          {!readOnlyUser &&
            <BoxFR>
              {/* <AddButton onClick={handleAddShippingProcess}>เพิ่ม Invoice</AddButton> */}
              <Button variant="contained" onClick={handleAddShippingProcess}><AddCircleRounded sx={{ mr: 1 }} /> เพิ่ม Shipping Process </Button>
              <Button variant="contained" onClick={handleOpenShippingJob}><ArticleRounded sx={{ mr: 1 }} /> งาน-Shipping </Button>
              <Button variant="contained" onClick={() => { setDialogCCEDMailConfirm(true) }}><EmailRounded sx={{ mr: 1 }} />กำหนด Email ผู้รับยืนยันใบขน</Button>
            </BoxFR>
          }
          <Box flex={1} />
          <TextField {...filterProps("Sts")} label="สถานะ" select>
            <MenuItem value={null}>ไม่ระบุ</MenuItem>
            <MenuItem value="NotFin">ยังไม่จบ</MenuItem>
            <MenuItem value="Fin">จบแล้ว</MenuItem>
            <MenuItem value="PL">รอ Packing list</MenuItem>
            <MenuItem value="ED">รอ Draf ใบขน</MenuItem>
            <MenuItem value="ED0209">รอใบขน 0209</MenuItem>
            <MenuItem value="ED0309">รอใบขน 0309</MenuItem>
            <MenuItem value="ED0409">รอใบขน 0409</MenuItem>
            <MenuItem value="PayCstm">รอจ่ายเงิน</MenuItem>
          </TextField>
          <DateTimeSelect {...dateTimeFilterProps("วันที่นัดเข้า รง.", "AptTmSt", "AptTmEn")} />
          {/* <DateTimeSelect {...dateTimeFilterProps("วันที่รับ PL", "PLCreTmSt", "PLCreTmEn")} />
        <DateTimeSelect {...dateTimeFilterProps("วันที่ Draf ใบขน", "EDCreTmSt", "EDCreTmEn")} /> */}
          
          <TextField {...filterProps("OwnAccNm")} />
          <TextField {...filterProps("Bkg")} />
          <TextField {...filterProps("InvNo")} />
          <ComboBox sx={{ width: 180 }} options={cusShippingCombo} label="ลูกค้า"
            selectedId={filterData.CusId}
            setSelectedId={(id) => setFilterData(o => ({ ...o, CusId: id }))}
          />
          <SearchButton onClick={handleSearchButtonClick} />
          <ClearButton onClick={handleClearFilterData} />
        </BoxFR>
      ) : (
        <PopoverBarebone
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          component={<Button variant="contained" sx={{ width: 150 }}>
            <FilterListRounded sx={{ mr: 1 }} /> FILTER
          </Button>}
        >
          <Paper sx={{ p: 2, width: 230 }}>
            <BoxFC sx={{ gap: 1 }}>
              {!readOnlyUser &&
                <BoxFR>
                  <Button variant="contained" onClick={handleOpenShippingJob}><ArticleRounded sx={{ mr: 1 }} /> งาน-Shipping </Button>
                  <Button variant="contained" onClick={() => { setDialogCCEDMailConfirm(true) }}><EmailRounded sx={{ mr: 1 }} />กำหนด Email ผู้รับยืนยันใบขน</Button>
                </BoxFR>
              }
              <Box flex={1} />
              <TextField {...filterPropsMobile("Sts")} label="สถานะ" select>
                <MenuItem value={null}>ไม่ระบุ</MenuItem>
                <MenuItem value="NotFin">ยังไม่จบ</MenuItem>
                <MenuItem value="Fin">จบแล้ว</MenuItem>
                <MenuItem value="PL">รอ Packing list</MenuItem>
                <MenuItem value="ED">รอ Draf ใบขน</MenuItem>
                <MenuItem value="ED0209">รอใบขน 0209</MenuItem>
                <MenuItem value="ED0309">รอใบขน 0309</MenuItem>
                <MenuItem value="ED0409">รอใบขน 0409</MenuItem>
                <MenuItem value="PayCstm">รอจ่ายเงิน</MenuItem>
              </TextField>
              <DateTimeSelect {...dateTimeFilterProps("วันที่นัดเข้า รง.", "AptTmSt", "AptTmEn")} />
              <TextField {...filterPropsMobile("OwnAccNm")} />
              <TextField {...filterPropsMobile("Bkg")} />
              <TextField {...filterPropsMobile("IP_InvNo")} />
              <SearchButton onClick={handleSearchButtonClick} />
              <ClearButton onClick={handleClearFilterData} />
            </BoxFC>
          </Paper>
        </PopoverBarebone>
      )}

      <Box width='100%' mt={w800 ? 2 : 0} flexGrow={1} sx={{
        '& .pl-col': { backgroundColor: deepPurple[100] },
        '& .ed-col': { backgroundColor: cyan[50] },
        '& .ed0209-col': { backgroundColor: teal[100] },
        '& .ed0309-col': { backgroundColor: lightGreen[100] },
        '& .ed0409-col': { backgroundColor: green[100] },
        '& .cd-col': { backgroundColor: blue[100] },
        '& .ed-cf-reject': { backgroundColor: red[100] },
        '& .MuiDataGrid-row:hover': {
          backgroundColor: blueGrey[50],
          '& .normal-col': { backgroundColor: blueGrey[50] },
          '& .pl-col': { backgroundColor: deepPurple[200] },
          '& .ed-col': { backgroundColor: cyan[100] },
          '& .ed0209-col': { backgroundColor: teal[200] },
          '& .ed0309-col': { backgroundColor: lightGreen[200] },
          '& .ed0409-col': { backgroundColor: green[200] },
          '& .ed-cf-reject': { backgroundColor: red[200] },
          '& .cd-col': { backgroundColor: blue[200] }
        },
        '& .Mui-selected': {
          backgroundColor: blueGrey[50],
          '& .pl-col': { backgroundColor: deepPurple[200] },
          '& .ed-col': { backgroundColor: cyan[100] },
          '& .ed0209-col': { backgroundColor: teal[200] },
          '& .ed0309-col': { backgroundColor: lightGreen[200] },
          '& .ed0409-col': { backgroundColor: green[200] },
          '& .ed-cf-reject': { backgroundColor: red[200] },
          '& .cd-col': { backgroundColor: blue[200] }
        }
      }}>
        <DataGridCellExpand
          hideFooter
          density={w800 ? "standard" : "compact"}
          rows={dataTable}
          columns={shippingProcessColumns(readOnlyUser, handleFileClick, handleButtonClick, handleDeleteTime, user.Email)}
          onCellEditCommit={handleCellEdit}
          onRowDoubleClick={!readOnlyUser ? handleRowDoubleClick : null}
          onCellClick={!readOnlyUser ? handleCellClick : null}
          disableSelectionOnClick={false}
          columnVisibilityModel={{
            EDAprvTm: !readOnlyUser,
            CDAprvTm: !readOnlyUser,
            Note: !readOnlyUser,
          }} />
      </Box>
      <Popover
        open={popOverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Paper sx={{ p: 1 }}>
          <BoxFC sx={{ gap: 1 }}>
            {
              filePopoverArr.map((file, index) => (
                <Link href={file.url} target="_blank" key={index}>
                  <Button variant="outlined" size="small">{file.label}</Button>
                </Link>
              ))
            }
          </BoxFC>
        </Paper>
      </Popover>
      <ShippingJobDialog
        dialogOpen={dialogShippingJobOpen}
        setDialogOpen={setDialogShippingJobOpen}
        onFinish={onFinish} />
      <PackingListDialog
        selectedId={selectedId}
        selectedJobId = {selectedJobId}
        dialogOpen={dialogPLOpen}
        cusId={selectedCusId}
        setDialogOpen={setDialogPLOpen}
        onFinish={onFinish}
        lastFilter={lastFilter}
        shippingAccounts={shippingAccounts} />
      <ShippingInvoiceDialog
        selectedId={selectedId}
        dialogOpen={dialogShippingInvoiceOpen}
        setDialogOpen={setDialogShippingInvoiceOpen}
        onFinish={onFinish}
        lastFilter={lastFilter}
        localAccountCombo={localAccountCombo} />
      <AddFileWithSendDialog
        shpmFileTypId={shpFileTypId}
        title={dialogTitle}
        colNm={selectedColNm}
        selectedId={selectedId}
        dialogOpen={dialogAddFileWithSendOpen}
        setDialogOpen={setDialogAddFileWithSendOpen}
        onFinish={onFinish}
        lastFilter={lastFilter} />
      <ED0209Dialog
        selectedId={selectedId}
        dialogOpen={dialogED0209Open}
        setDialogOpen={setDialogED0209Open}
        onFinish={onFinish}
        lastFilter={lastFilter} />
      <UpdateTextDialog
        selectedId={selectedId}
        oldData={oldTextData}
        colNm={selectedColNm}
        dialogOpen={dialogUpdateTextOpen}
        setDialogOpen={setDialogUpdateTextOpen}
        onFinish={onFinish}
        lastFilter={lastFilter} />
      <ApproveDialog
        selectedId={selectedId}
        dialogOpen={dialogApproveOpen}
        setDialogOpen={setDialogApproveOpen}
        onFinish={onFinish}
        lastFilter={lastFilter} />
      <ApproveCDDialog
        selectedId={selectedId}
        dialogOpen={dialogCDApproveOpen}
        setDialogOpen={setDialogCDApproveOpen}
        onFinish={onFinish}
        lastFilter={lastFilter} />
      <RejectDetailDialog
        selectedId={selectedId}
        dialogOpen={dialogRejectDetailOpen}
        setDialogOpen={setDialogRejectDetailOpen} />
      <ReceiveEDMallConfirmDialog
        dialogOpen={dialogCCEDMailConfirm}
        setDialogOpen={setDialogCCEDMailConfirm} />
      <AddShippingInvoiceDialog 
        dialogOpen={dialogAddShippingProcessOpen}
        setDialogOpen={setDialogAddShippingProcessOpen}
        onFinish={onFinish}
        lastFilter={lastFilter} />
    </BoxFC>
  )
}

export default ShippingProcess;