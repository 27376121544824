import { Box, Button, Dialog, DialogContent, Tooltip, Typography } from "@mui/material"
import { BoxFC, BoxFR } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { shippingApiNm } from "./shippingApiNm"
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand"
import { shippingJobColumns } from "./columns"
import { addIdForDataGrid } from "utils"
import ComboBox from "components/ComboBox"
import { AddCircle } from "@mui/icons-material"
import { alertConfirm, alertConfirmDelete, alertWarning } from "components/Alert"
import EditCCEmailDialog from "./EditCCEmailDialog"

let selectedCCEmail = ""
let editJobId = null

const ShippingJobDialog = ({ dialogOpen, setDialogOpen }) => {

  const { ax, msData } = useContext(GlobalStateContext)
  const [dataTable, setDataTable] = useState([])
  const [selectedCusId, setSelectedCusId] = useState(null)
  const [selectedJobId, setSelectedJobId] = useState(null)
  const [jobCombo, setJobCombo] = useState([])
  const [dialogCCEmailOpen, setDialogCCEmailOpen] = useState(false)

  const handleDeleteShippingJob = useCallback((id) => {
    alertConfirmDelete(()=>{
      ax.post(shippingApiNm.deleteShippingJob, { JobId: id }).then(value => {
        if (value.data) {
          setDataTable(addIdForDataGrid(value.data, "JobId"))
        }
      })
    })
  }, [ax])

  const handleSelectedCusId = useCallback((id) => {
    setSelectedCusId(id)
    setJobCombo(msData.jobCombo.filter(x => x.refId === id))
  }, [msData.jobCombo])

  const handleInsertJob = useCallback(() => {
    ax.post(shippingApiNm.insertShippingJob, { JobIds: [selectedJobId] }).then(value => {
      if (value.data) {
        setDataTable(addIdForDataGrid(value.data, "JobId"))
      }
    })
  }, [selectedJobId, ax])

  const localInsertByCustomer = useCallback(() => {

    ax.post(shippingApiNm.insertShippingJob, { JobIds: jobCombo.map(job => job.id) }).then(value => {
      if (value.data) {
        setDataTable(addIdForDataGrid(value.data, "JobId"))
      }
    })
  }, [ax, jobCombo])
  const handleInsertByCustomer = useCallback(() => {
    if (!jobCombo || jobCombo.length === 0) {
      alertWarning("ไม่พบงานในลูกค้า")
      return
    }

    if (jobCombo.length > 5) {
      alertConfirm("จำนวนงานมากกว่า 5 งาน คุณต้องการที่จะเพิ่มทุกงานในลูกค้าหรือไม่", () => {
        localInsertByCustomer()
      })
    } else {
      localInsertByCustomer()
    }

  }, [jobCombo, localInsertByCustomer])

  const handleEditCCEmails = useCallback((row) => () => {
    selectedCCEmail = row.CCEmails || ""
    editJobId = row.JobId
    setDialogCCEmailOpen(true)
  }, [])

  const onCCEmailDialogFinish = useCallback((ccEmail) => {
    const postData = {
      JobId: editJobId,
      ColValues: { CCEmails: ccEmail }
    }
    ax.post(shippingApiNm.updateShippingJobColumns, postData).then(value => {
      if (value.data) {
        setDataTable(addIdForDataGrid(value.data, "JobId"))
      }
    })
  }, [ax])

  useEffect(() => {
    if (dialogOpen) {
      ax.post(shippingApiNm.getShippingJob, {}).then(value => {
        if (value.data) {
          setDataTable(addIdForDataGrid(value.data, "JobId"))
        }
      })
    }
  }, [dialogOpen, ax])

  // const comboProp = useMemo(() => ({
  //   options: msData.cusCombo,
  //   label: "ลูกค้า",
  //   sx: { width: 200},
  //   selectedId: cusId,
  //   setSelectedId: (id) => setCusId(id)
  // }), [cusId, msData.cusCombo])
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='md' >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={"งาน Shipping"}
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC mt={2} sx={{ gap: 0 }}>
          <Typography variant="h6" >เพิ่มงาน Shipping</Typography>
          <BoxFC >
            {/* <TextField size="small" variant="outlined" label="Email ส่งเอกสาร" helperText="ในกรณีหลาย Email คั่นด้วยจุลภาค (,)"/> */}
            <BoxFR>
              <ComboBox sx={{ flex: 1 }} options={msData.cusCombo} label="ลูกค้า" selectedId={selectedCusId} setSelectedId={handleSelectedCusId} />
              <Tooltip title="เพิ่มทุกงานในลูกค้า">
                <Button sx={{ minWidth: 0 }} variant="contained" onClick={handleInsertByCustomer}><AddCircle  /> </Button>
              </Tooltip>
              <Box width={16} />
              <ComboBox sx={{ flex: 1 }} options={jobCombo} label="งาน" selectedId={selectedJobId} setSelectedId={setSelectedJobId} />
              <Tooltip title="เพิ่มงาน">
                <Button sx={{ minWidth: 0 }} variant="contained" onClick={handleInsertJob}><AddCircle /> </Button>
              </Tooltip>
            </BoxFR>
          </BoxFC>

          <Typography mt={2} variant="h6" >รายละเอียดงาน Shipping</Typography>
          <Box width='100%' height={500} flexGrow={1}>
            <DataGridCellExpand
              hideFooter
              rows={dataTable}
              columns={shippingJobColumns(handleDeleteShippingJob, handleEditCCEmails)} />
          </Box>
        </BoxFC>
        <EditCCEmailDialog 
          initCCEmail={selectedCCEmail}
          dialogOpen={dialogCCEmailOpen} 
          setDialogOpen={setDialogCCEmailOpen} 
          onFinish={onCCEmailDialogFinish} />
      </DialogContent>
      <DialogFooter okText={""}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={null} />
    </Dialog>
  )
}

export default ShippingJobDialog