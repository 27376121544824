import dayjs from 'dayjs';


import { readPDFLineData } from './readPDFLineData';
import { toNumber } from 'utils';
import {getDocument, GlobalWorkerOptions} from 'pdfjs-dist/build/pdf';
GlobalWorkerOptions.workerSrc = "https://unpkg.com/pdfjs-dist@4.3.136/build/pdf.worker.min.mjs";

const initExcelData = {
  IP_Shpr: "",
  IP_Csgn: "", //Consignee
  IP_NofyPrty: "", //Notify Party, usually same of consignee
  IP_PoL: "", //Port of Loading
  IP_CoL: "", //Country of Loading
  IP_Vssl: "", //Vessel
  IP_ETD: "", //Estimated Time of Departure
  IP_InvNo: "",
  IP_InvDte: "",
  IP_PayTrm: "", //Payment Term
  IP_NmPlc: "", //Name of Place
  IP_CoO: "", //Country of Origin
  IP_CoC: "Thailand", //Country of Consignee
  IP_Rmk: "",
  IP_FinlDst: "", //Final Destination
  IP_CoD: "", //Country of Destination
  IP_BkgNo: "",
  IP_ShpmTrm: "", //Shipment Term
  IP_PO: "",
  IP_PoD: "", //Place of Delivery 
  rowData: []
}

const initRowDataObj = {

  No: 0,
  id: 0,
  Mrk: "",
  Dscp: "",
  QtyCT: "",
  QtyPCS: "",
  Unit: "",
  Price: "",
  Amnt: "",
  NW: "",
  QtyM: "",
  GW: "",
  M3: "",
  Rmk: "",
}

const readHeader = (lineObjs) => {
  const tmpExcelData = { ...initExcelData }
  tmpExcelData.IP_InvNo = lineObjs[1].objs[1].str
  const dteStr = `${lineObjs[2].objs[4].str} ${lineObjs[2].objs[1].str} ${lineObjs[2].objs[2].str}`
  console.log('dteStr::', dteStr)
  tmpExcelData.IP_InvDte = dayjs(dteStr, "YYYY MMMM DD", "en").format("YYYY.MM.DD")
  tmpExcelData.IP_Csgn = `${lineObjs[5].objs[1].str}\n${lineObjs[6].objs[0].str}`

  let runLine = 7
  for (let i = 7; i < lineObjs.length; i++) {
    if (lineObjs[i].objs[0].str.startsWith("SHIPPED BY")) {
      runLine = i
      tmpExcelData.IP_Vssl = lineObjs[i].objs[1].str
      tmpExcelData.IP_PO = lineObjs[i].objs[3].str.replace("PO", "").trim()
      break
    } else {
      tmpExcelData.IP_Csgn += `\n${lineObjs[i].objs[0].str}`
    }
  }
  runLine++
  const pol = lineObjs[runLine].objs[1].str
  const pols = pol.split(",")
  if (pols.length > 1) {
    tmpExcelData.IP_CoL = pols[pols.length - 1].trim()
  }
  tmpExcelData.IP_PoL = pols.slice(0, -1).join(", ").trim()
  tmpExcelData.IP_PoD = lineObjs[runLine].objs[3].str
  runLine++
  tmpExcelData.IP_ShpmTrm = lineObjs[runLine].objs[1].str
  return tmpExcelData
}


const readHeaderDraft = (lineObjs) => {
  const tmpExcelData = { ...initExcelData }
  tmpExcelData.IP_InvNo = ""
  const dteStr = `${lineObjs[0].objs[2].str}`
  // console.log('dteStr::', dteStr)
  tmpExcelData.IP_InvDte = dayjs(dteStr, "DD/M/YYYY", "en").format("YYYY.MM.DD")
  tmpExcelData.IP_Csgn = `${lineObjs[3].objs[1].str}\n${lineObjs[4].objs[0].str}`

  let runLine = 5
  for (let i = 5; i < lineObjs.length; i++) {
    const line = lineObjs[i]
    for(let j = 0; j<line.objs.length; j++){
      if(line.objs[j].str.startsWith("P.O")){
        if(line.objs.length > j+1){
          tmpExcelData.IP_PO = line.objs[j+1].str
        }
        runLine = i
        break;
      }
    }
    if(tmpExcelData.IP_PO !== ""){
      break;
    }
    
  }
  runLine++
  runLine++
  tmpExcelData.IP_ShpmTrm = lineObjs[runLine].objs[1].str
  for(let j = 2; j<lineObjs[runLine].objs.length; j++){
    const line = lineObjs[runLine]
    if(line.objs[j].str.startsWith("FROM PORT") && j+1 < line.objs.length){
      tmpExcelData.IP_PoL = line.objs[j+1].str
      break
    }
  }
  runLine++
  for(let j = 0; j<lineObjs[runLine].objs.length; j++){
    const line = lineObjs[runLine]
    if(line.objs[j].str.startsWith("TO PORT") && j+1 < line.objs.length){
      tmpExcelData.IP_PoD = line.objs[j+1].str
      break
    }
  }
  runLine++
  tmpExcelData.IP_PayTrm = lineObjs[runLine].objs[1].str
  return tmpExcelData
}


const readTable = (lineObjs) => {
  let runLine = 0
  for (let i = 0; i < lineObjs.length; i++) {
    const obj = lineObjs[i]
    if (obj.objs[0].str.toLowerCase().trim() === "description") {
      runLine = i + 2
      break;
    }
  }
  if (runLine === 0) {
    return []
  }

  const rowData = []

  for (let i = runLine; i < lineObjs.length; i++) {
    if (lineObjs[i].objs[0].str.startsWith("TOTAL")) {
      break
    }

    const rowDataObj = { ...initRowDataObj }
    const dscp = lineObjs[i].objs[0].str.trim()
    if ((dscp.startsWith("[") && dscp.endsWith("]"))
      || dscp.toUpperCase().indexOf("FREIGHT SERVICE") >= 0
      || dscp.toUpperCase().indexOf("INSURANCE") >= 0) {
      continue
    }
    rowDataObj.Dscp = dscp

    /**
     * Some pdf file has not same column position
     * better check x position of header and x position of obj
     */
    for (let j = 1; j < lineObjs[i].objs.length; j++) {
      const obj = lineObjs[i].objs[j]
      if (obj.x >= 280 && obj.x <= 335) {
        if (rowDataObj.Mrk === "")
          rowDataObj.Mrk = obj.str.trim()
      } else if (obj.x >= 340 && obj.x <= 395) {
        if (rowDataObj.QtyPCS === "")
          rowDataObj.QtyPCS = toNumber(obj.str.trim())
      } else if (obj.x >= 400 && obj.x <= 439) {
        if (rowDataObj.Unit === "")
          rowDataObj.Unit = toNumber(obj.str.trim())
      } else if (obj.x >= 455 && obj.x <= 510) {
        if (rowDataObj.Price === "")
          rowDataObj.Price = toNumber(obj.str.trim())
      } else if (obj.x >= 515 && obj.x <= 575) {
        if (rowDataObj.Amnt === "")
          rowDataObj.Amnt = toNumber(obj.str.trim())
      }
    }
    if (rowDataObj.QtyPCS !== "" || rowDataObj.Price !== "" || rowDataObj.Amnt !== "") {
      rowData.push(rowDataObj)
    } else if (rowDataObj.Dscp !== "" && rowDataObj.QtyPCS === "" && rowDataObj.Price === ""
      && rowDataObj.Amnt === "" && rowData.length >= 1) {
      const lastChar = rowData[rowData.length - 1].Dscp.slice(-1);
      if (/[a-zA-Z0-9]/.test(lastChar)) {
        rowData[rowData.length - 1].Dscp += ` ${rowDataObj.Dscp}`;
      } else {
        rowData[rowData.length - 1].Dscp += rowDataObj.Dscp;
      }
    }
  }
  return rowData
}
const readTableDraft = (lineObjs) => {
  let runLine = 0
  for (let i = 0; i < lineObjs.length; i++) {
    const line = lineObjs[i]
    for (const obj of line.objs) {
      if (obj.str.toLowerCase().trim() === "description") {
        runLine = i + 2
      }
    }
    // if (obj.objs[0].str.toLowerCase().trim() === "description") {
    //   runLine = i + 2
    //   break;
    // }
  }
  if (runLine === 0) {
    return []
  }

  const rowData = []

  for (let i = runLine; i < lineObjs.length; i++) {
    const line = lineObjs[i]
    if (line.objs[0].str.startsWith("TOTAL")) {
      break
    }

    const rowDataObj = { ...initRowDataObj }

    /**
     * Some pdf file has not same column position
     * better check x position of header and x position of obj
     */
    for (let j = 0; j < line.objs.length; j++) {
      const obj = line.objs[j]
      if (obj.x > 0 && obj.x < 70) {
        rowDataObj.Code = obj.str.trim()
      } else if (obj.x >= 70 && obj.x <= 140) {
        // this is customer not mark
        // if (rowDataObj.Mrk === "")
        //   rowDataObj.Mrk = obj.str.trim()
      } else if (obj.x >= 140 && obj.x <= 320) {
        rowDataObj.Dscp = obj.str.trim()
      } else if (obj.x >= 320 && obj.x <= 380) {
        if (rowDataObj.Unit === "")
          rowDataObj.Unit = obj.str.trim()
      } else if (obj.x >= 380 && obj.x <= 439) {
        if (rowDataObj.QtyPCS === "")
          rowDataObj.QtyPCS = toNumber(obj.str.trim())
      } else if (obj.x >= 455 && obj.x <= 510) {
        if (rowDataObj.Price === "")
          rowDataObj.Price = toNumber(obj.str.trim())
      } else if (obj.x >= 515 && obj.x <= 575) {
        if (rowDataObj.Amnt === "")
          rowDataObj.Amnt = toNumber(obj.str.trim())
      }
    }
    if (rowDataObj.QtyPCS !== "" || rowDataObj.Price !== "") {
      rowData.push(rowDataObj)
    } else if (rowDataObj.Dscp !== "" && rowDataObj.QtyPCS === "" && rowDataObj.Price === ""
      && rowDataObj.Amnt === "" && rowData.length >= 1) {
      const lastChar = rowData[rowData.length - 1].Dscp.slice(-1);
      if (/[a-zA-Z0-9]/.test(lastChar)) {
        rowData[rowData.length - 1].Dscp += ` ${rowDataObj.Dscp}`;
      } else {
        rowData[rowData.length - 1].Dscp += rowDataObj.Dscp;
      }
    }
  }
  return rowData
}

export const readInvNCR = async (dataInv, fromUrl) => {
  // console.log('ncrInv::', ncrInv)

  return new Promise((resolve, reject) => {

    const reader = new FileReader();
    reader.onload = async (e) => {

      try {
        const contents = e.target.result;
        const pdf = await getDocument(contents).promise
        const numPages = pdf.numPages;
        console.log("numPages::", numPages)
        let tmpExcelData = { ...initExcelData }
        for (let i = 1; i <= numPages; i++) {
          const page = await pdf.getPage(i); // get first page
          const content = await page.getTextContent();
          console.log('page::', page)
          console.log('content::', content)
          const lineObjs = readPDFLineData(content.items)
          console.log('lineObjs::', lineObjs)
          if (lineObjs.length === 0) {
            reject('No data found.')
            return
          }
          const title = lineObjs[0].objs[0].str
          if (title === "COMMERCIAL INVOICE") {

            if (i === 1) {
              tmpExcelData = readHeaderDraft(lineObjs)
            }
            tmpExcelData.rowData = [...tmpExcelData.rowData, ...readTableDraft(lineObjs)]
          } else if (title === "INVOICE") {
            if (i === 1) {
              tmpExcelData = readHeader(lineObjs)
            }
            tmpExcelData.rowData = [...tmpExcelData.rowData, ...readTable(lineObjs)]
          } else {
            reject('Wrong File Type')
            return
          }
        }
        resolve(tmpExcelData)
      } catch (err) {
        reject(err.message)
      }

    }

    reader.readAsArrayBuffer(dataInv);

    reader.onerror = (error) => {
      console.log('Error: ', error)
      reject(error)
    };
  })


  // const lineObjs = readLineData(ncrInv.items)

  // const tmpExcelData = readHeader(lineObjs)

  // tmpExcelData.rowData = readTable(lineObjs)

  // console.log('tmpExcelData::', tmpExcelData)
}