import { jobOrderColNm } from "constants/jobOrderConstant";
import { shipmentColNm } from "constants/shipmentConstant";

export const shippingInvoiceColNm = {
  IP_InvNo: "Invoice No.",
  IP_InvDte: "วันที่ Invoice",
  Bkg: "Booking",
  IP_Csgn: "Consignee",
  IP_ETD: "ETD",
  Note: "Note",
  IP_PO:"PO",
  PLCreTm: "เวลารับ PL",
  OwnAccId: "ผู้ส่ง PL",
  OwnAccNm: "ผู้ส่ง PL",
  OwnAccEmail: "Email ผู้ส่ง PL",
  EDCreTm: "เวลาเพิ่ม Draf ใบขน",
  EDCreAccId: "ผู้สร้างใบขน",
  EDCreAccNNm: "ผู้สร้างใบขน",
  EDNo: "เลขที่ใบขน",
  EDAprvTm: "เวลาตรวจสอบใบขน",
  EDSndTm: "เวลาส่งใบขน",
  EDCfTm: "เวลายืนยันใบขน",
  ED0209CreTm: "เวลาเพิ่มสถานะ0209",
  ED0209SndTm: "เวลาส่งสถานะ0209",
  ED0309CreTm: "เวลาเพิ่มสถานะ0309",
  ED0309SndTm: "เวลาส่งสถานะ0309",
  ED0409CreTm: "เวลาเพิ่มสถานะ0409",
  ED0409SndTm: "เวลาส่งสถานะ0409",
  CDCreTm: "เวลาสร้างใบกำกับตู้",
  CDCreAccId: "ผู้สร้างใบกำกับตู้",
  CDCreAccNNm: "ผู้สร้างใบกำกับตู้",
  CDAprvTm: "เวลาตรวจสอบใบกำกับตู้",
  CDSndTm: "เวลาส่งใบกำกับตู้",
  PayCstmTm: "เวลาจ่ายศุลกากร",
  Rmk: "หมายเหตุ",
  RtnPlc: jobOrderColNm.RtnPlc,
  Vssl: shipmentColNm.Vssl,
  Voy: shipmentColNm.Voy,
  Port: shipmentColNm.Port,
  ContNo: jobOrderColNm.ContNo,
  ISOCd: jobOrderColNm.ISOCd,
  SealNo: jobOrderColNm.SealNo,
  TareWt: jobOrderColNm.TareWt,
  GrossWt: "G.W.",
  TotalGW: "Total G.W.",
  CT: "จำนวนหีบห่อ/หน่วย",
  KGM: "น้ำหนักรวมหีบห่อ/หน่วย",
  TotalKGM: "VGM WEIGHT",

}