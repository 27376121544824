import { Button, Dialog, DialogContent, TextField } from "@mui/material"
import { BoxFR } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { shippingApiNm } from "./shippingApiNm"

const AddShippingInvoiceDialog = ({ dialogOpen, setDialogOpen, onFinish, lastFilter }) => {

  const { ax } = useContext(GlobalStateContext)
  const [jobNo, setJobNo] = useState("")
  
  const handleAddShippingProcess = useCallback(() => {
    ax.post(shippingApiNm.insertShippingInvoice, { JobNo:jobNo, getArgs: lastFilter }).then(value => {
      if(value.data){
        onFinish(value.data)
        setDialogOpen(false)
      }
    })
  }, [ax, jobNo, lastFilter, onFinish, setDialogOpen])
  useEffect(() => {
    if (!dialogOpen) {
      setJobNo("")
    }
  }, [dialogOpen])
  
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xs' 
      >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={"เพิ่ม Shipping Process"}
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFR mt={2} justifyContent="center">
          <TextField label="Job No" variant="outlined" size="small" 
          value={jobNo} onChange={(e) => setJobNo(e.target.value)} />
          <Button variant="contained" onClick={handleAddShippingProcess}>เพิ่ม</Button>
        </BoxFR>
      </DialogContent>
      <DialogFooter okText={""}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={null} />
    </Dialog>
  )
}

export default React.memo(AddShippingInvoiceDialog)