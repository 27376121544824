import { invoiceColNm } from "constants/invoiceConstant";
import { jobOrderColNm } from "constants/jobOrderConstant";
import dayjs from "dayjs";
import { numberFormat } from "utils";


export const columns  = [
  {
    field: 'No',
    headerName: 'ลำดับ',
    width: 70,
    align: 'center',
  },
  {
    field: 'CusSNm',
    headerName: jobOrderColNm.CusSNm,
    flex: 1,
  },
  {
    field: 'JobSNm',
    headerName: jobOrderColNm.JobSNm,
    flex: 1,
  },
  {
    field: 'InvNo',
    headerName: invoiceColNm.InvNo,
    width: 140,
  },
  {
    field: 'InvDte',
    headerName: invoiceColNm.InvDte,
    width: 100,
    type: "dateTime",
    valueGetter: (params) => params.value  && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM/YYYY") : "",
  },
  {
    field: 'JobNo',
    headerName: invoiceColNm.JobNo,
    width: 150,
  },
  {
    field: 'ContNo',
    headerName: invoiceColNm.ContNo,
    width: 120,
  },
  {
    field: 'MinAptTm',
    headerName: "วันืที่นัด",
    width: 100,
    type: "dateTime",
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM/YYYY") : "",
  },
  {
    field: 'OwnRcptAmnt',
    headerName: invoiceColNm.OwnRcptAmnt,
    width: 110,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "0.00",
  },
  {
    field: 'CusRcptAmnt',
    headerName: invoiceColNm.CusRcptAmnt,
    width: 110,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "0.00",
  },
  {
    field: 'WhtPr',
    headerName: invoiceColNm.WhtPr,
    width: 90,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "0.00",
  },
  {
    field: 'Total',
    headerName: invoiceColNm.Total,
    width: 110,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "0.00",
  },
]

