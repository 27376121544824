import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, Button, IconButton, Typography, Tooltip } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { shippingInvoiceColNm } from './constant';
import { ArticleRounded, EditRounded } from '@mui/icons-material';
import dayjs from 'dayjs';
import { BoxFR } from 'components/BoxCustom';
import { numberFormat, numberFormatNoFlotingPoint, toNumber } from 'utils';

export const shippingJobColumns = (handleDeleteShippingJob, handleEditCCEmails) => [
  {
    field: 'No',
    headerName: 'ลำดับ',
    width: 70,
    align: 'center',
  },
  {
    field: 'CusSNm',
    headerName: 'ชื่อลูกค้า',
    width: 150,
  },
  {
    field: 'JobSNm',
    headerName: 'ชื่องาน',
    width: 150,
  },
  {
    field: 'CCEmails',
    headerName: "CC Emails",
    flex: 1,
    renderCell: (params) => (
      <BoxFR width="100%">
        <IconButton sx={{ p: 0 }} onClick={handleEditCCEmails(params.row)}>
          <EditRounded fontSize='small' />
        </IconButton>
        <Typography variant='body2'>{params.value}</Typography>
        <Box flexGrow={1} />
      </BoxFR>
    )
  },
  // {
  //   field: 'Emails',
  //   headerName: 'Email ส่งเอกสาร',
  //   flex: 1,
  // },
  {
    field: "actions",
    type: "actions",
    width: 30,
    getActions: (params) => [
      <GridActionsCellItem
        icon={<DeleteForeverIcon />}
        label="Delete"
        onClick={() => handleDeleteShippingJob(params.id)}
      />
    ],
  }
]

const buttonProps = (isDisable) => ({ variant: "contained", disabled: isDisable, sx: { textTransform: "none", size: "small" } })

const generateButtonCell = (params, label, color, refDeleteCol, isDisable, readOnlyUser, handleDeleteTime, handleButtonClick) =>
  params.value ?
    <BoxFR>
      {dayjs(params.value).format("DD/MM HH:mm")} {!readOnlyUser && !params.row[refDeleteCol] &&
        <IconButton sx={{ p: 0 }} onClick={handleDeleteTime(params.field, params.row)}>
          <DeleteForeverIcon />
        </IconButton>}
    </BoxFR>
    : readOnlyUser ? "-" : <Button color={color} onClick={handleButtonClick(params.field, params.row)} {...buttonProps(isDisable)}>{label}</Button>



const iconCell = (handleFileClick) => (params) => (

  <BoxFR>
    <IconButton sx={{ p: 0 }} onClick={params.row[params.field] ? handleFileClick(params.id, params.row[params.field], params.field, params.row) : null}>
      <ArticleRounded color={params.row[params.field] ? "primary" : "inherit"} />
    </IconButton>
    {params.field === "NumRj" && params.row.NumRj > 0 && <Box >({params.row.NumRj})</Box>}
  </BoxFR>
)

const calculatePLTooltip = (row) => {
  const foundPLFileTypeId1 = row.PLFileNms?.find(file => file.fileTypId === 1)
  const foundPLFileTypeId6 = row.PLFileNms?.find(file => file.fileTypId === 6)
  let tooltipArr = []
  if (foundPLFileTypeId1 && row.NumPLItm === 0) {
    tooltipArr.push("ยังไม่ใส่ข้อมูล INV/PL")
  }
  if (foundPLFileTypeId6 && row.NumMultItm === 0) {
    tooltipArr.push("ยังไม่ใส่ข้อมูล Multi Container")
  }
  if (tooltipArr.length === 0) return null
  else return (
    <Tooltip title={tooltipArr.join(", ")} placement='top' followCursor>
      <Box position="absolute" top={-10} right={-10} sx={{ cursor: "help" }}>
        <Typography variant='body' fontSize="1.3rem" fontWeight="bold" color="red">*</Typography>
      </Box>
    </Tooltip>
  )

}
/** Have to value getter to string coz when export they will show in string instead of [object object] */
const formaterTime = (params) => params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-"
const getterTime = (params) => params.value ? dayjs(params.value).format("YYYY-MM-DD HH:mm") : null

export const shippingProcessColumns = (readOnlyUser, handleFileClick, handleButtonClick, handleDeleteTime, userEmail) => [
  {
    field: 'No',
    cellClassName: "normal-col",
    headerName: 'ลำดับ',
    width: 70,
    align: 'center',
  },
  {
    field: 'CusSNm',
    cellClassName: "normal-col",
    headerName: 'ชื่อลูกค้า',
    width: 150,
  },
  {
    field: 'Bkg',
    cellClassName: "normal-col",
    headerName: 'Booking',
    width: 150,
  },
  {
    field: 'AptTm',
    cellClassName: "normal-col",
    headerName: 'วันที่นัดเข้า รง.',
    width: 120,
    type: 'date',
    valueGetter: getterTime,
    valueFormatter: (params) => params.value ? dayjs(params.value).format("DD/MM/YYYY") : "-",
  },
  {
    field: 'NumJobOrd',
    cellClassName: "normal-col",
    headerName: 'จำนวนตู้',
    align: 'center',
    valueGetter: (params) => `${params.row.NumJobOrd}`,
    width: 70,
  },
  {
    field: 'ISOCds',
    cellClassName: "normal-col",
    headerName: 'ISO Code',
    align: 'center',
    width: 70,
  },
  {
    field: 'NumContNo',
    cellClassName: "normal-col",
    headerName: 'รับตู้',
    align: 'center',
    valueGetter: (params) => `${params.row.NumContNo}/${params.row.NumJobOrd}`,
    width: 70,
  },
  {
    field: 'NumFinDte',
    cellClassName: "normal-col",
    headerName: 'คืนตู้',
    align: 'center',
    valueGetter: (params) => `${params.row.NumFinDte}/${params.row.NumJobOrd}`,
    width: 70,
  },
  {
    field: 'IP_InvNo',
    cellClassName: "normal-col",
    headerName: shippingInvoiceColNm.IP_InvNo,
    width: 155,
  },
  {
    field: 'IP_InvDte',
    cellClassName: "normal-col",
    headerName: shippingInvoiceColNm.IP_InvDte,
    type: 'date',
    valueGetter: (params) => params.value ? dayjs(params.value).format("YYYY-MM-DD") : null,
    valueFormatter: (params) => params.value ? dayjs(params.value).format("DD/MM/YYYY") : "-",
    width: 100,
  },
  {
    field: 'OwnAccNm',
    cellClassName: "normal-col",
    headerName: shippingInvoiceColNm.OwnAccNm,
    width: 120,
  },
  {
    field: 'OwnAccEmail',
    cellClassName: "normal-col",
    headerName: shippingInvoiceColNm.OwnAccEmail,
    width: 120,
  },
  {
    field: 'IP_Csgn',
    cellClassName: "normal-col",
    headerName: shippingInvoiceColNm.IP_Csgn,
    width: 150,
  },
  {
    field: 'IP_PO',
    cellClassName: "normal-col",
    headerName: shippingInvoiceColNm.IP_PO,
    width: 150,
  },
  {
    field: 'IP_ETD',
    cellClassName: "normal-col",
    headerName: shippingInvoiceColNm.IP_ETD,
    width: 120,
    type: 'date',
    valueGetter: getterTime,
    valueFormatter: formaterTime,
  },
  {
    field: 'Note',
    cellClassName: "normal-col",
    headerName: shippingInvoiceColNm.Note,
    width: 120,
  },
  {
    field: 'PLFileNms',
    cellClassName: "normal-col",
    headerName: 'INV/PL',
    width: 70,
    align: 'center',
    valueGetter: (params) => params.value ? params.value.length : "0",
    renderCell: (params) => (
      <Box position="relative">
        {iconCell(handleFileClick)(params)}
        {
          calculatePLTooltip(params.row)
        }

      </Box>
    )
  },
  {
    field: 'EDFileNms',
    cellClassName: "normal-col",
    headerName: 'ใบขน',
    width: 70,
    align: 'center',
    valueGetter: (params) => params.value ? params.value.length : "0",
    renderCell: iconCell(handleFileClick)
  },
  {
    field: 'CDFileNms',
    cellClassName: "normal-col",
    headerName: 'ใบกำกับ',
    width: 70,
    align: 'center',
    valueGetter: (params) => params.value ? params.value.length : "0",
    renderCell: iconCell(handleFileClick)
  },
  {
    field: 'RCFileNms',
    cellClassName: "normal-col",
    headerName: 'ใบเสร็จ',
    width: 70,
    align: 'center',
    valueGetter: (params) => params.value ? params.value.length : "0",
    renderCell: iconCell(handleFileClick)
  },
  {
    field: 'PLCreTm',
    cellClassName: "pl-col",
    headerName: shippingInvoiceColNm.PLCreTm,
    width: 120,
    type: 'date',
    valueGetter: getterTime,
    valueFormatter: formaterTime, //**check OwnEmail with userEmail in buttonProps() after client can confirm  */
    renderCell: (params) => generateButtonCell(
      params, "เพิ่ม PL", "primary", "EDCreTm", readOnlyUser, readOnlyUser, handleDeleteTime, handleButtonClick
    )
  },
  {
    field: 'EDCreTm',
    cellClassName: "ed-col",
    headerName: shippingInvoiceColNm.EDCreTm,
    width: 140,
    type: 'date',
    valueGetter: getterTime,
    valueFormatter: formaterTime,
    renderCell: (params) => generateButtonCell(
      params, "เพิ่ม Draf ใบขน", "primary", "ED0209CreTm", !params.row.PLCreTm, readOnlyUser, handleDeleteTime, handleButtonClick
    )
  },
  {
    field: 'EDCreAccNNm',
    cellClassName: "ed-col",
    headerName: shippingInvoiceColNm.EDCreAccNNm,
    width: 150,
  },
  {
    field: 'EDAprvTm',
    cellClassName: "ed-col",
    headerName: shippingInvoiceColNm.EDAprvTm,
    width: 140,
    type: 'date',
    valueGetter: getterTime,
    valueFormatter: formaterTime,
    renderCell: (params) => generateButtonCell(
      params, "ตรวจสอบใบขน", "secondary", "ED0209CreTm", !params.row.EDCreTm, readOnlyUser, handleDeleteTime, handleButtonClick
    )
  },
  {
    field: 'EDSndTm',
    cellClassName: "ed-col",
    headerName: shippingInvoiceColNm.EDSndTm,
    width: 140,
    type: 'date',
    valueGetter: getterTime,
    valueFormatter: formaterTime,
    renderCell: (params) => generateButtonCell(
      params, "ส่งใบขน", "primary", "ED0209CreTm", !params.row.EDCreTm, readOnlyUser, handleDeleteTime, handleButtonClick
    )
  },
  {
    field: 'EDCfTm',
    cellClassName: (params) => params.row.LastIsCf === 0 ? "ed-cf-reject" : "ed-col",
    headerName: shippingInvoiceColNm.EDCfTm,
    width: 140,
    type: 'date',
    valueGetter: getterTime,
    valueFormatter: formaterTime, //*** use this after client can confirm !params.row.EDSndTm || (readOnlyUser && params.row.OwnAccEmail !== userEmail)
    renderCell: (params) => generateButtonCell(
      params, "ลูกค้ายืนยันใบขน", "success", "ED0209CreTm", !params.row.EDSndTm, readOnlyUser, handleDeleteTime, handleButtonClick
    )
  },
  {
    field: 'NumRj',
    cellClassName: "ed-col",
    headerName: "Rejected",
    width: 80,
    align: 'center',
    renderCell: iconCell(handleFileClick)
  },
  {
    field: 'ED0209CreTm',
    cellClassName: "ed0209-col",
    headerName: shippingInvoiceColNm.ED0209CreTm,
    width: 140,
    type: 'date',
    valueGetter: getterTime,
    valueFormatter: formaterTime,
    renderCell: (params) => generateButtonCell(
      params, "เพิ่มใบขน0209", "primary", "ED0309CreTm", !params.row.EDCreTm, readOnlyUser, handleDeleteTime, handleButtonClick
    )
  },
  {
    field: 'EDNo',
    cellClassName: "ed0209-col",
    headerName: shippingInvoiceColNm.EDNo,
    width: 140,
  },
  {
    field: 'ED0209SndTm',
    cellClassName: "ed0209-col",
    headerName: shippingInvoiceColNm.ED0209SndTm,
    width: 140,
    type: 'date',
    valueGetter: getterTime,
    valueFormatter: formaterTime,
    renderCell: (params) => generateButtonCell(
      params, "ส่ง0209+ใบกำกับ", "primary", "ED0309CreTm", !params.row.EDCreTm, readOnlyUser, handleDeleteTime, handleButtonClick
    )
  },
  {
    field: 'CDCreTm',
    cellClassName: "cd-col",
    headerName: shippingInvoiceColNm.CDCreTm,
    width: 140,
    type: 'date',
    valueGetter: getterTime,
    valueFormatter: formaterTime,
    renderCell: (params) => generateButtonCell(
      params, "เพิ่มใบกำกับ", "primary", "ED0309CreTm", !params.row.EDCreTm, readOnlyUser, handleDeleteTime, handleButtonClick
    )
  },
  {
    field: 'CDAprvTm',
    cellClassName: "cd-col",
    headerName: shippingInvoiceColNm.CDAprvTm,
    width: 140,
    type: 'date',
    valueGetter: getterTime,
    valueFormatter: formaterTime,
    renderCell: (params) => generateButtonCell(
      params, "ตรวจสอบใบกำกับ", "secondary", "ED0309CreTm", !params.row.CDCreTm || !params.row.EDCreTm, readOnlyUser, handleDeleteTime, handleButtonClick
    )
  },
  {
    field: 'CDSndTm',
    cellClassName: "cd-col",
    headerName: shippingInvoiceColNm.CDSndTm,
    width: 140,
    type: 'date',
    valueGetter: getterTime,
    valueFormatter: formaterTime,
    renderCell: (params) => generateButtonCell(
      params, "ส่งใบกำกับ", "primary", "ED0309CreTm", !params.row.CDCreTm, readOnlyUser, handleDeleteTime, handleButtonClick
    )
  },
  {
    field: 'ED0309CreTm',
    cellClassName: "ed0309-col",
    headerName: shippingInvoiceColNm.ED0309CreTm,
    width: 140,
    type: 'date',
    valueGetter: getterTime,
    valueFormatter: formaterTime,
    renderCell: (params) => generateButtonCell(
      params, "เพิ่มใบขน0309", "primary", "ED0409CreTm", !params.row.ED0209CreTm, readOnlyUser, handleDeleteTime, handleButtonClick
    )
  },
  {
    field: 'ED0309SndTm',
    cellClassName: "ed0309-col",
    headerName: shippingInvoiceColNm.ED0309SndTm,
    width: 140,
    type: 'date',
    valueGetter: getterTime,
    valueFormatter: formaterTime,
    renderCell: (params) => generateButtonCell(
      params, "ส่งใบขน0309", "primary", "ED0409CreTm", !params.row.ED0309CreTm, readOnlyUser, handleDeleteTime, handleButtonClick
    )
  },
  {
    field: 'ED0409CreTm',
    cellClassName: "ed0409-col",
    headerName: shippingInvoiceColNm.ED0409CreTm,
    width: 140,
    type: 'date',
    valueGetter: getterTime,
    valueFormatter: formaterTime,
    renderCell: (params) => generateButtonCell(
      params, "เพิ่มใบขน0409", "primary", "PayCstmTm", !params.row.ED0309CreTm, readOnlyUser, handleDeleteTime, handleButtonClick
    )
  },
  {
    field: 'ED0409SndTm',
    cellClassName: "ed0409-col",
    headerName: shippingInvoiceColNm.ED0409SndTm,
    width: 140,
    type: 'date',
    valueGetter: getterTime,
    valueFormatter: formaterTime,
    renderCell: (params) => generateButtonCell(
      params, "ส่งใบขน0409", "primary", "PayCstmTm", !params.row.ED0409CreTm, readOnlyUser, handleDeleteTime, handleButtonClick
    )
  },
  {
    field: 'PayCstmTm',
    cellClassName: "normal-col",
    headerName: shippingInvoiceColNm.PayCstmTm,
    width: 140,
    type: 'date',
    valueGetter: getterTime,
    valueFormatter: formaterTime,
    renderCell: (params) => generateButtonCell(
      params, "ใบเสร็จ", "primary", "NotTing", !params.row.ED0209CreTm, readOnlyUser, handleDeleteTime, handleButtonClick
    )
  },
  {
    field: 'Rmk',
    cellClassName: "normal-col",
    headerName: shippingInvoiceColNm.Rmk,
    width: 140,
    flex: 1
  },
]

export const approveCDColumns = (editable) => [
  {
    field: 'No',
    headerName: 'No.',
    width: 50,
  },
  {
    field: 'ContNo',
    headerName: shippingInvoiceColNm.ContNo,
    width: 120,
  },
  {
    field: 'TareWt',
    headerName: shippingInvoiceColNm.TareWt,
    flex: 1,
    valueGetter: (params) => toNumber(params.value),
    valueFormatter: (params) => +params.value ? numberFormatNoFlotingPoint(+params.value) : "",
    align: "right",
  },
  {
    field: 'GW',
    headerName: shippingInvoiceColNm.GrossWt,
    flex: 1,
    valueGetter: (params) => +params.value,
    valueFormatter: (params) => numberFormat(+params.value),
    align: "right",
    editable: editable,
  },
  {
    field: 'TotalGW',
    headerName: shippingInvoiceColNm.TotalGW,
    valueGetter: (params) => toNumber(params.row.GW) + toNumber(params.row.TareWt),
    valueFormatter: (params) => +params.value ? numberFormat(+params.value) : "",
    flex: 1,
    align: "right",
  },
  {
    field: 'PSC',
    headerName: 'PSC',
    flex: 1,
    align: "right",
    valueGetter: (params) => +params.value,
    valueFormatter: (params) => numberFormatNoFlotingPoint(+params.value),
    editable: editable,
  },
  {
    field: 'CNT',
    headerName: 'CNT',
    flex: 1,
    align: "right",
    valueGetter: (params) => +params.value,
    valueFormatter: (params) => numberFormatNoFlotingPoint(+params.value),
    editable: editable,
  },
  {
    field: 'CBM',
    headerName: 'CBM',
    flex: 1,
    align: "right",
    valueGetter: (params) => +params.value,
    valueFormatter: (params) => numberFormat(+params.value),
    editable: editable,
  },

]