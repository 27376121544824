export const initExcelData = {
  
  IP_Shpr: "",
  IP_Csgn: "",
  IP_NofyPrty: "",
  IP_PoL: "",
  IP_CoL: "",
  IP_Vssl: "",
  IP_ETD: "",
  IP_InvNo: "",
  IP_InvDte: "",  
  IP_PayTrm: "",
  IP_NmPlc: "",
  IP_CoO: "",
  IP_CoC: "",
  IP_Rmk: "",
  IP_FinlDst: "",
  IP_CoD: "",
  IP_BkgNo: "",
  IP_ShpmTrm: "",
  IP_PO: "",
  IP_PoD: "",
  EDAprvTm: null,
}