import {  Dialog, DialogContent, TextField } from "@mui/material"
import { alertSuccess } from "components/Alert"
import { BoxFC } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { coreApiNm } from "constants/serverConstant"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useState } from "react"

const CCEDMallConfirmDialog = ({ dialogOpen, setDialogOpen }) => {

  const { ax, msData, setMSData } = useContext(GlobalStateContext)
  const [email, setEmail] = useState("")

  const handleSave = useCallback(() => {
    const postData = {
      ...msData.serverData,
      Shipping: {
        ...msData.serverData.Shipping,
        CCEDMailConfirm: email
      }
    }
    ax.post(coreApiNm.saveServerData, postData).then(value => {
      if (value.data) {
        alertSuccess("บันทึกข้อมูลเรียบร้อยแล้ว")
        setDialogOpen(false)

        setMSData(o=>{
          const newMSData = {...o, serverData: postData}
          localStorage.setItem("msData", JSON.stringify(newMSData))
          return newMSData
        })
        localStorage.setItem("msData", JSON.stringify(msData))
      }
    })
  }, [ax, email, msData, setDialogOpen, setMSData])
  useEffect(() => {
    if (dialogOpen) {
      setEmail(msData.serverData.Shipping?.CCEDMailConfirm || "")
    }
  }, [dialogOpen, ax, msData.serverData])


  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xs'

    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="กำหนด Email ผู้รับยืนยันใบขน"
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC mt={2} >
          <TextField size="small" label="Email ผู้รับการยืนยันใบขน" multiline rows={2}
            value={email} onChange={e => { setEmail(e.target.value) }} helperText="หากมี Email ผู้รับหลายคนคั่นด้วยจุลภาค ( , )" />
        </BoxFC>
      </DialogContent>
      <DialogFooter okText={"บันทึก"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleSave} />
    </Dialog>
  )
}

export default React.memo(CCEDMallConfirmDialog)