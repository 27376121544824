import { CircularProgress, FormControl, FormControlLabel, FormLabel, InputAdornment, MenuItem, Radio, RadioGroup, TextField, Typography } from "@mui/material"
import { BoxFC, BoxFR } from "components/BoxCustom"
import DateTimePickerCustomTime from "components/DateTimePickerCustomTime"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import { UserContext } from "contexts/UserContext"
import React, { useCallback, useContext, useState } from "react"
import { pettyCashApiNm, pettyCashColNm } from "./constant"
import ComboBox from "components/ComboBox"
import { alertError } from "components/Alert"
import { CancelRounded, CheckCircleRounded } from "@mui/icons-material"
import { expensePOIApiNm } from "pages/Menu4/ExpensePOI/constant"
import { red, green, grey } from "@mui/material/colors"
import { expenseJobOrderColNm } from "constants/expenseJobOrderConstant"

const PayComponent = ({ dialogData, setDialogData, isItemDialog, isRequriedUsrAccId, usrAccId, jobOrdId, isHideJobOrdId }) => {
  const { user } = useContext(UserContext)
  const { msData, ax } = useContext(GlobalStateContext)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingUntPr, setIsLoadingUntPr] = useState(false)

  const textFieldProps = useCallback((name, isRequired) => ({
    fullWidth: true,
    required: isRequired,
    label: pettyCashColNm[name],
    size: "small",
    error: isRequired && !dialogData[name],
    value: dialogData[name] ?? "",
    onChange: (e) => setDialogData(o => ({ ...o, [name]: e.target.value })),
  }), [dialogData, setDialogData])

  const dateTimePickerProp = useCallback((name, isRequired) => ({
    label: pettyCashColNm[name],
    inputFormat: "DD/MM/YYYY HH:mm",
    mask: "__/__/____ __:__",
    value: dialogData[name],
    onChange: (newValue) => { setDialogData(o => ({ ...o, [name]: newValue })) },
    renderInput: (params) => <TextField size="small" {...params} fullWidth required={isRequired} error={isRequired && !dialogData[name]} />,
  }), [dialogData, setDialogData])

  const comboboxProps = useCallback((nameId, nameNm, options) => ({
    label: pettyCashColNm[nameId],
    sx: { width: "100%" },
    options: options,
    selectedId: dialogData[nameId],
    setSelectedId: (id) => setDialogData(o => ({ ...o, [nameId]: id, [nameNm]: options.find(option => option.id === id)?.label })),
  }), [dialogData, setDialogData])

  const setUntPrByJobOrdIdExpId = useCallback((jobOrdId, expId) => {
    console.log("setUntPrByJobOrdIdExpId", jobOrdId, expId)
    if (expId) {

      setIsLoadingUntPr(true)
      ax.post(expensePOIApiNm.getUntPrByJobOrdIdExpId, { JobOrdId: jobOrdId || null, ExpId: expId }, false).then(value => {
        setIsLoadingUntPr(false)
        if (value.data && value.data.length === 1) {
          setDialogData(o => ({
            ...o,
            Amnt: value.data[0].UntPr,
            IsCost: value.data[0].IsCost,
            VatPrct: value.data[0].VatPrct,
            WhtPrct: value.data[0].WhtPrct,
            IsOwnRcpt: value.data[0].IsOwnRcpt,
          }))
        } else {
          setDialogData(o => ({
            ...o,
            Amnt: "",
            IsCost: 0,
            VatPrct: 0,
            WhtPrct: 0,
            IsOwnRcpt: 0,
          }))
        }
      })
    }
  }, [ax, setDialogData])

  const handleJobOrdIdBlur = useCallback((e) => {
    if (e.target.value === "") {
      setDialogData(o => ({
        ...o,
        LocText: "",
        isJobOrdIdCorrect: null,
      }))
      return
    }
    setIsLoading(true)
    ax.post(pettyCashApiNm.getJobOrderLocation, { JobOrdId: e.target.value }, false).then(value => {
      setIsLoading(false)
      if (value.data) {
        if (value.data.length !== 1) {
          setDialogData(o => ({
            ...o,
            LocText: "",
            isJobOrdIdCorrect: false,
          }))
          alertError("ไม่พบข้อมูล เลขที่ใบงานนี้")
          return
        } else {
          const data = value.data[0]
          setDialogData(o => ({
            ...o,
            ...data,
            isJobOrdIdCorrect: true,
            LocText: `(${data.ContSizeFull || ""})${data.TakePlc || ""}-${data.Loc || ""}-${data.RtnPlc || ""}`
          }))
          if (dialogData.ExpId) {
            setUntPrByJobOrdIdExpId(e.target.value, dialogData.ExpId)
          }
        }
      }
    })
  }, [ax, setDialogData, dialogData.ExpId, setUntPrByJobOrdIdExpId])


  const handleExpChange = useCallback((id) => {
    console.log("handleExpChange", id)
    setDialogData(o => {
      setUntPrByJobOrdIdExpId(o.JobOrdId, id)
      return { ...o, ExpId: id }
    })
  }, [setDialogData, setUntPrByJobOrdIdExpId])

  console.log("PayComponent jobOrdID::", jobOrdId)
  return (
    <BoxFC width={400} sx={{ gap: 1 }}>
      <BoxFR width="100%">
        {!isItemDialog &&
          <ComboBox {...comboboxProps("UsrAccId", "UsrAccNm", msData.accountAllCombo)}
            required={isRequriedUsrAccId ?? false}
            selectedId={dialogData.UsrAccId} />
        }
        <DateTimePickerCustomTime {...dateTimePickerProp("PayTm", true)} />
      </BoxFR>
      {isHideJobOrdId ? null :
        <BoxFR>
          <TextField {...textFieldProps("JobOrdId")} disabled={jobOrdId ? true : false}
            onBlur={handleJobOrdIdBlur} type="number"
            onChange={(e) => setDialogData(o => ({ ...o, JobOrdId: e.target.value || null }))}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  {isLoading ? <CircularProgress size={20} />
                    : !dialogData.JobOrdId || dialogData.isJobOrdIdCorrect === null ? null
                      : dialogData.isJobOrdIdCorrect ? <CheckCircleRounded color="success" /> : <CancelRounded color="error" />}
                </InputAdornment>
            }} />
          <TextField {...textFieldProps("LocText")} onChange={null}
            inputProps={{ style: { fontSize: "0.8em" } }} />
        </BoxFR>
      }

      <BoxFR>
        <ComboBox {...comboboxProps("ExpId", "ExpNm", msData.expOprCombo)}
          setSelectedId={handleExpChange}
          required={true} />
        <TextField {...textFieldProps("Amnt", true)} type="number" disabled={isLoadingUntPr}
          inputProps={{ style: { textAlign: "right" } }}
          InputProps={{
            endAdornment:
              <InputAdornment position="end">
                {isLoadingUntPr ? <CircularProgress size={20} /> : "บาท"}
              </InputAdornment>
          }} />
      </BoxFR>
      {isItemDialog &&
        <BoxFR>
          <TextField {...textFieldProps("DocNo")} />
          <DateTimePickerCustomTime {...dateTimePickerProp("DocDte")} inputFormat={"DD/MM/YYYY"} mask={"__/__/____"} />
        </BoxFR>
      }
      <BoxFR>
        <TextField {...textFieldProps("IsCost")} label="การวางบิลลูกค้า" disabled={dialogData.InOutTyp === "I"}
          select sx={{ bgcolor: dialogData.InOutTyp === "I" ? grey[100] : dialogData.IsCost === 1 ? red[50] : green[50] }}>
          <MenuItem value={0}>วางบิลได้(รายได้)</MenuItem>
          <MenuItem value={1}>วางบิลไม่ได้(ต้นทุน)</MenuItem>
        </TextField>
        <TextField {...textFieldProps("Dscp")} />
      </BoxFR>
      {
        !dialogData.IsCost &&

        <FormControl component="fieldset">
          <FormLabel component="legend">{expenseJobOrderColNm.IsOwnRcpt}</FormLabel>
          <RadioGroup row name='IsOwnRcpt' value={dialogData.IsOwnRcpt}
            onChange={(e) => { setDialogData((oldState) => ({ ...oldState, IsOwnRcpt: e.target.value })) }}>
            <FormControlLabel value={1} control={<Radio />} label="ใบเสร็จบริษัท" />
            <FormControlLabel value={0} control={<Radio />} label="ใบเสร็จลูกค้า" />
          </RadioGroup>
        </FormControl>
      }
      <BoxFR>
        {!isItemDialog &&
          <ComboBox {...comboboxProps("PCJnlId", "PCJnlNm", msData.pcJournalCombo)} required={true} />
        }
        <TextField {...textFieldProps("Rmk")} />
      </BoxFR>
      <Typography ><strong>ผู้ทำรายการ: </strong>{user.FName} {user.LName} ({user.NName})</Typography>
    </BoxFC>
  )
}

export default PayComponent