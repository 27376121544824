import { DesktopDatePicker } from '@mui/x-date-pickers';
import { Box, Button, TextField, Typography } from "@mui/material";
import { BoxFC, BoxFR } from "components/BoxCustom";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import dayjs from "dayjs";
import React, { useCallback, useContext } from "react";
import { useMemo } from "react";
import { useState } from "react";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { addIdForDataGrid } from "utils";
import { columns } from "./columns";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import { expenseJobOrderApiNm } from "constants/expenseJobOrderConstant";
import ComboBox from "components/ComboBox";

const ContainerExpenseReport = () =>{

  const { ax, msData } = useContext(GlobalStateContext)

  const [cusId, setCusId] = useState(null)
  const [invDte, setInvDte] = useState(dayjs())
  const [dataTable, setDataTable] = useState([])
  
  const getData = useCallback((selectedMonth, cusId) => {
    const postData = {
      CusId: cusId,
      InvDteSt: selectedMonth.format("YYYY-MM-01 23:59:59.997"),
      InvDteEn: selectedMonth.endOf("month").format("YYYY-MM-DD 00:00:00"),
    }
    ax.post(expenseJobOrderApiNm.getContainerExpense, postData).then(value => {
      if (value.data) {
        value.data.forEach(data => {
          data.MinJobOrdId = `${data.InvNo}_${data.MinJobOrdId}`
        });
        setDataTable(addIdForDataGrid(value.data, "MinJobOrdId"))
      }
    })
  }, [ax])

  const datePickerProp = useMemo(() => ({          
    openTo: "month",
    views: ["year", "month"],
    inputFormat: "MMMM YYYY",
    mask: "____ ____",
    value: invDte,
    onChange: (newValue) => setInvDte(newValue),
    renderInput: (params) => <TextField sx={{ width: 180 }} size="small" {...params} />
  }), [invDte, ])

  const comboProp = useMemo(() => ({
    options: msData.cusCombo,
    label: "ลูกค้า",
    sx: { width: 200},
    selectedId: cusId,
    setSelectedId: (id) => setCusId(id)
  }), [cusId, msData.cusCombo])


  return (
    <BoxFC p={2} height="100%">
      <BoxFR>
        <Typography variant="h6">รายงาน ค่าใช้จ่าย แยกตามตู้</Typography>
        <DesktopDatePicker  {...datePickerProp} />
        <ComboBox {...comboProp}/>
        <Button variant="contained" onClick={() => getData(invDte, cusId)}>
          <SearchRoundedIcon mr={1}/> ค้นหา
        </Button>
      </BoxFR>
      <Box width="100%" flexGrow={1} >
        <DataGridCellExpand
          hideFooter
          rows={dataTable}
          columns={columns} />
      </Box>
    </BoxFC>
  )
}

export default ContainerExpenseReport