import { Dialog, DialogContent, TextField } from "@mui/material"
import { BoxFR } from "components/BoxCustom"
import DateTimePickerCustomTime from "components/DateTimePickerCustomTime"
import PaperComponent from "components/PaperComponent"
import ClearButton from "components/buttons/ClearButton"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import React, { useCallback, useEffect, useMemo, useState } from "react"

const DatetimeDialog = ({ oldValue, dialogOpen, setDialogOpen, onFinish }) => {

  const [dateTimeValue, setDateTimeValue] = useState(null)
  
  const dateTimePickerProp = useMemo(() => ({
    label: "วัน-เวลา",
    inputFormat: "DD/MM/YYYY HH:mm",
    mask: "__/__/____ __:__",
    value: dateTimeValue,
    onChange: (newValue) => { setDateTimeValue(newValue) },
    renderInput: (params) => <TextField size="small" {...params}  />,
  }), [dateTimeValue])

  const handleDialogOk = useCallback(()=>{
    onFinish(dateTimeValue)
    setDialogOpen(false)
  }, [onFinish, dateTimeValue, setDialogOpen])
  
  useEffect(() => {
    if (dialogOpen) {
      if(oldValue) {
        setDateTimeValue(oldValue)
      } 
    } else {
      setDateTimeValue(null)
    }
  }, [dialogOpen, oldValue])
  
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xs' 
      >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="กำหนดเวลา"
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFR pt={2} sx={{justifyContent: "center"}} >
          <DateTimePickerCustomTime {...dateTimePickerProp} /> <ClearButton onClick={() => setDateTimeValue(null)} />
        </BoxFR>
      </DialogContent>
      <DialogFooter okText="แก้ไขข้อมูล"
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleDialogOk} />
    </Dialog>
  )
}

export default React.memo(DatetimeDialog)