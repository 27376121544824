const TAKE_WAIT_AMNT_ID = 94
const LOC_WAIT_AMNT_ID = 95
const TAKE_AMNT_ID = 92
export const LOC_AMNT_ID = 88
const RTN_AMNT_ID = 93
const OT_AMNT_ID = 87
const LASH_AMNT_ID = 86
const XRAY_AMNT_ID = 14

export const allFixColExpIds = [TAKE_AMNT_ID, LOC_AMNT_ID, RTN_AMNT_ID, LASH_AMNT_ID
  , TAKE_WAIT_AMNT_ID, LOC_WAIT_AMNT_ID, OT_AMNT_ID, XRAY_AMNT_ID]

export const colId ={
  TakeAmnt: TAKE_AMNT_ID,
  LocAmnt: LOC_AMNT_ID,
  RtnAmnt: RTN_AMNT_ID,
  LashAmnt: LASH_AMNT_ID,
  TakeWaitAmnt: TAKE_WAIT_AMNT_ID,
  LocWaitAmnt: LOC_WAIT_AMNT_ID,
  OTAmnt: OT_AMNT_ID,
  XrayAmnt: XRAY_AMNT_ID
}