import { sortObj, sortObjDesc } from "utils"

export const readPDFLineData = (items) => {

  const lineObjs = []
  for (const obj of items) {
    if (obj.str.trim() === "") continue
    if (obj.transform[5] < 75) continue // end of page
    let found = false
    if (obj.str === "FLEXTRAL") {
      console.log('obj::', obj)
    }
    if (obj.transform[5] === 417.88) {
      console.log('obj::', obj)
    }
    for (const lineObj of lineObjs) {
      if (obj.transform[5] + 4 > lineObj.y && obj.transform[5] - 4 < lineObj.y) {
        found = true
        lineObj.objs.push({
          str: obj.str,
          x: obj.transform[4],
          original: obj
        })
        break
      }
    }
    if (!found) {
      lineObjs.push({
        y: obj.transform[5], objs: [{
          str: obj.str,
          x: obj.transform[4],
          original: obj
        }]
      })
    }
  }
  sortObjDesc(lineObjs, 'y')
  for (const lineObj of lineObjs) {
    sortObj(lineObj.objs, 'x')
  }
  return lineObjs
}