import { DesktopDatePicker } from '@mui/x-date-pickers';
import { Box, Button, TextField, Typography } from "@mui/material";
import { BoxFC, BoxFR } from "components/BoxCustom";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import dayjs from "dayjs";
import React, { useCallback, useContext } from "react";
import { useMemo } from "react";
import { useState } from "react";
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import { jobOrderApiNm } from "constants/jobOrderConstant";
import { addIdForDataGrid } from "utils";
import { columns } from "./columns";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import { useEffect } from "react";

const HalfTripReport = () => {

  const { ax } = useContext(GlobalStateContext)

  const [aptTm, setAptTm] = useState(dayjs())
  const [dataTable, setDataTable] = useState([])

  const getData = useCallback((selectedMonth) => {
    const postData = {
      TagIds: [1],
      AptTmSt: selectedMonth.format("YYYY-MM-01 23:59:59.997"),
      AptTmEn: selectedMonth.endOf("month").format("YYYY-MM-DD 00:00:00"),
    }
    ax.post(jobOrderApiNm.getJobOrderJobTag, postData).then(value => {
      if (value.data) {
        setDataTable(addIdForDataGrid(value.data, "MaxJobOrdId"))
      }
    })
  }, [ax])

  const handleDatePickerChange = useCallback((newValue)=>{
    setAptTm(newValue)
    getData(newValue)

  }, [getData])
  const datePickerProp = useMemo(() => ({      
    openTo: "month",
    views: ["year", "month"],
    label: "ประจำเดือน",
    inputFormat: "MMMM YYYY",
    mask: "____ ____",
    value: aptTm,
    onChange: handleDatePickerChange,
    renderInput: (params) => <TextField sx={{ width: 180 }} size="small" {...params} />
  }), [aptTm, handleDatePickerChange])


  useEffect(()=>{
    getData(dayjs())
  }, [getData])
  return (
    <BoxFC p={2} height="100%">
      <BoxFR>
        <Typography variant="h6">รายงานเที่ยวเต็มเที่ยวตัด</Typography>
        <DesktopDatePicker  {...datePickerProp} />
        <Button variant="contained" onClick={() => getData(aptTm)}>
          <RefreshRoundedIcon />
        </Button>
      </BoxFR>
      <Box width={800} flexGrow={1} >
        <DataGridCellExpand
          hideFooter
          rows={dataTable}
          columns={columns} />
      </Box>
    </BoxFC>
  )
}

export default HalfTripReport