export const noYearColNms = [
  "AptTm",
  "ArvTm",
  "LevTm",
  "TakeDteSt",
  "TakeDteEn",
  "RtnDteSt",
  "RtnDteEn",
  "ContDoc",
  "ExpDte",
  "FinDte",
  "ModDte",
  "AddTm",
  "FuelTm",
  "PLCreTm",
  "EDCreTm",
  "EDAprvTm",
  "EDSndTm",
  "EDCfTm",
  "CDCreTm",
  "CDAprvTm",
  "CDSndTm",
  "RtnDocCreTm",
  "RtnDocSndTm",
  "FinDocCreTm",
  "FinDocSndTm",
  "PayCstmTma",
  "MemTm"
]