import { Dialog, DialogContent} from "@mui/material"
import { BoxFC  } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import FullScreenImage from "pages/FullScreenImage/FullScreenImage"
import React from "react"

const FullScreenImageDialog = ({ linkUrl, dialogOpen, setDialogOpen }) => {
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='lg'
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="รุปภาพ"
        onDeleteClick={null}
      />
      <DialogContent sx={{p:0}}>
        <BoxFC p={0} height={770}>
          <FullScreenImage linkUrl={linkUrl} />
        </BoxFC>
      </DialogContent>
      <DialogFooter okText={""}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={null} />
    </Dialog>
  )
}

export default React.memo(FullScreenImageDialog)