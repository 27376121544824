import React, { useMemo, useState } from "react";
import { Box, Button, CircularProgress, Dialog, DialogContent,  Typography } from '@mui/material'
import { DialogFooter, DialogHeader } from 'components/dialog/DialogHeader';
import { addIdForDataGrid } from "utils";
import PaperComponent from "components/PaperComponent";
import { useEffect } from "react";
import dayjs from "dayjs";
import ComboBox from "components/ComboBox";
import { useContext } from "react";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { jobOrderColNm } from "constants/jobOrderConstant";
import { BoxFC, BoxFR } from "components/BoxCustom";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import { driverIncomeDailyColumn } from "./columns";
import { useCallback } from "react";
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded';
import { branchApiNm } from "branch/constant/branchApiNm";
import SearchButton from "components/buttons/SearchButton";
import DateTimeSelect from "components/DateTimeSelect";
import { blue, green, grey, purple, yellow } from "@mui/material/colors";
import { LOC_AMNT_ID, colId } from "./constant";
import { pettyCashApiNm } from "pages/Menu1/PettyCash/constant";
import { UserContext } from "contexts/UserContext";
import { BuildCircleRounded } from "@mui/icons-material";
import ExpensePOIDialog from "pages/Menu4/ExpensePOI/ExpensePOIDialog";
import OtherDialog from "./OtherDialog";

const initData = {
  DrvId: null,
  AptTmSt: dayjs().format("YYYY-MM-DD 00:00:00.000"),
  AptTmEn: dayjs().format("YYYY-MM-DD 23:59:59.997"),
}
const buttonProps = {
  variant: "contained",
}

let initExpPOIData = null
// let deleteIds = []

let selectedJobOrdId = null

const takeCols = ["TakeAmnt", "TakeWaitAmnt", "LashAmnt"]
const locCols = ["LocAmnt", "LocWaitAmnt", "OTAmnt"]
const rtnCols = ["RtnAmnt", "XrayAmnt"]
const AddFromDailyJobDialog = ({ dialogOpen, setDialogOpen, onFinish }) => {
  const { user } = useContext(UserContext)
  const { ax, msData } = useContext(GlobalStateContext)

  const [filterData, setFilterData] = useState({ ...initData })
  const [dataTable, setDataTable] = useState([])
  const [driverCombo, setDriverCombo] = useState([])
  const [selectionModel, setSelectionModel] = useState([])
  const [numSave, setNumSave] = useState(0)
  const [dialogExpensePOIOpen, setDialogExpensePOIOpen] = useState(false)
  const [dialogOtherOpen, setDialogOtherOpen] = useState(false)


  const getDataFromDailyJob = useCallback((filter) => {

    const postData = {
      AptTmSt: dayjs(filter.AptTmSt).format("YYYY-MM-DD 00:00:00.000"),
      AptTmEn: dayjs(filter.AptTmEn).format("YYYY-MM-DD 23:59:59.997"),
      DrvId: filter.DrvId,
    }
    ax.post(branchApiNm.getDriverIncomeFromDailyJob, postData).then(value => {
      if (value.data) {
        for (const data of value.data) {
          data.ContStsObj = data.ContStses.reduce((prev, cur) => {
            prev[cur.ContStsId] = cur
            return prev
          }, {})
          data.PCTrnsObj = data.PCTrns?.reduce((prev, cur) => {
            prev[cur.ExpId] = cur
            return prev
          }, {}) || {}

          data.TakeDiff = ""
          data.LocDiff = ""
          if (data.TakeArvTm && data.TakeLevTm) {
            const numDiff = dayjs(data.TakeLevTm).diff(dayjs(data.TakeArvTm), "minute")
            data.TakeDiff = numDiff > 270 ? `${numDiff - 270}(${Math.ceil((numDiff - 270) / 60)})` : ""
          }
          if (data.ArvTm && data.LevTm) {
            const arvTm = dayjs(data.ArvTm)
            const aptTm = dayjs(data.AptTm)
            const numDiff = dayjs(data.LevTm).diff(arvTm.isBefore(aptTm) ? aptTm : arvTm, "minute")
            data.LocDiff = numDiff > 270 ? `${numDiff - 270}(${Math.ceil((numDiff - 270) / 60)})` : ""
          }
        }
        setDataTable(addIdForDataGrid(value.data, "JobOrdId"))
      }
    })
  }, [ax])

  const handleCellEditCommit = useCallback((params) => {
    const { id, field, value } = params
    setDataTable(o => {
      const result = [...o]
      const foundRow = result.find(item => item.id === id)
      let drvId = null
      if (takeCols.includes(field)) {
        if (foundRow.ContStsObj[1]) {
          drvId = foundRow.ContStsObj[1].DrvId
        }
      }
      if (foundRow) {
        if (foundRow[field] === value) return o
        foundRow[field] = value
        foundRow.PCTrnsObj[colId[field]] = {
          Amnt: +value,
          Dscp: "",
          ExpId: colId[field],
          PCJnlId: 100,
          UsrAccId: drvId,
          PayTm: dayjs(foundRow.AptTm).endOf('month').format("YYYY-MM-DD 23:59:59"),
        }
      }

      setNumSave(o => o + 1)
      if (value) {

        const postData = {
          argsArr: [{
            AdmAccId: user.AccId,
            UsrAccId: drvId,
            InOutTyp: "O",
            ExpId: colId[field],
            PCJnlId: 100,
            JobOrdId: foundRow.JobOrdId,
            PayTm: dayjs(foundRow.AptTm).endOf('month').format("YYYY-MM-DD 23:59:59"),
            Amnt: value,
            IsCost: 1,
            IsNoDupJOEX: 1
          }]
        }
        ax.post(pettyCashApiNm.insertPCTransactionBulk, postData, false).then(value => {
          setNumSave(o => o - 1)
          if (value.data) {

          }
        })
      } else {
        const postData = {
          ExpId: colId[field],
          JobOrdId: foundRow.JobOrdId,
        }
        ax.post(pettyCashApiNm.deletePCTransactionByJobOrdAndExpId, postData, false).then(value => {
          setNumSave(o => o - 1)
          if (value.data) {

          }
        })
      }

      return result
    })
  }, [ax, user.AccId])

  const handleSelectDriverCombo = useCallback((id) => {
    setFilterData(o => ({ ...o, DrvId: id }))
  }, [])

  const isCellEditable = useCallback(({ row, field }) => (
    (takeCols.includes(field) && row.ContStsObj[1]?.DrvId && row.ContStsObj[1]?.IsSub === 0)
    || (locCols.includes(field) && row.ContStsObj[3]?.DrvId && row.ContStsObj[1]?.IsSub === 0)
    || (rtnCols.includes(field) && row.ContStsObj[5]?.DrvId && row.ContStsObj[1]?.IsSub === 0))
    ? true : false, [])

  const handleJobDriverPayClick = useCallback(() => {
    if(dataTable.length === 0) return
    const jobIds = dataTable.reduce((prev, cur) => {
      prev.add(cur.JobId)
      return prev
    }, new Set())
    // dataTable.forEach(item => {
    //   if (!jobIds.includes(item.JobId)) {
    //     jobIds.push(item.JobId)
    //   }
    // })
    if (jobIds.length === 0) return

    const postData = {
      JobIds: Array.from(jobIds),
      ExpIds: [...takeCols.map(col => colId[col]), ...locCols.map(col => colId[col]), ...rtnCols.map(col => colId[col])],
    }
    ax.post(branchApiNm.getExpensePOIByJobIdOrExpId, postData).then(value => {
      if (value.data) {
        // const expObj = value.data.reduce((prev, cur) => {
        //   prev[cur.ExpId] = cur
        //   return prev
        // }, {})

        const focusField = [...takeCols, ...locCols, ...rtnCols]
        setDataTable(o => {
          const result = [...o]
          const argsArr = []
          for (const row of result) {
            for (const field of focusField) {

              if (row.PCTrnsObj[colId[field]]?.Amnt) continue

              if (isCellEditable({ row, field })) {
                let drvId = null
                if (takeCols.includes(field)) {
                  drvId = row.ContStsObj[1]?.DrvId
                } else if (locCols.includes(field)) {
                  drvId = row.ContStsObj[3]?.DrvId
                } else if (rtnCols.includes(field)) {
                  drvId = row.ContStsObj[5]?.DrvId
                }

                const foundExp = value.data.find(item => {
                  if (colId[field] === 86) {
                    console.log(item.ExpId === colId[field]
                      , (!item.JobId || item.JobId === row.JobId)
                      , (!item.TlrTypId || item.TlrTypId === row.TlrTypId)
                      , (!item.ContSize || item.ContSize === row.ContSize)
                      , (!item.ContTyp || item.ContTyp === row.ContTyp)
                      , (!item.ShpmTypId || item.ShpmTypId === row.ShpmTypId)
                      , (!item.POIId || item.POIId === row.POIId))
                  }
                  let poiid = null
                  if(takeCols.includes(field)){
                    poiid = row.TakePOIId
                  } else if(locCols.includes(field)){
                    poiid = row.LocPOIId
                  } else if(rtnCols.includes(field)){
                    poiid = row.RtnPOIId
                  }
                  return item.ExpId === colId[field]
                    && (!item.JobId || item.JobId === row.JobId)
                    && (!item.TlrTypId || item.TlrTypId === row.TlrTypId)
                    && (!item.ContSize || item.ContSize === row.ContSize)
                    && (!item.ContTyp || item.ContTyp === row.ContTyp)
                    && (!item.ShpmTypId || item.ShpmTypId === row.ShpmTypId)
                    && (!item.POIId || item.POIId === poiid)
                }
                )
                if (foundExp) {
                  row.PCTrnsObj[colId[field]] = {
                    Amnt: foundExp.UntPr,
                    Dscp: "",
                    ExpId: colId[field],
                    PCJnlId: 100,
                    UsrAccId: drvId,
                    PayTm: dayjs(row.AptTm).endOf('month').format("YYYY-MM-DD 23:59:59"),
                  }
                  
                  argsArr.push({
                    AdmAccId: user.AccId,
                    UsrAccId: drvId,
                    InOutTyp: "O",
                    ExpId: colId[field],
                    PCJnlId: 100,
                    JobOrdId: row.JobOrdId,
                    PayTm: dayjs(row.AptTm).endOf('month').format("YYYY-MM-DD 23:59:59"),
                    Amnt: foundExp.UntPr,
                    IsCost: 1,
                    IsNoDupJOEX: 1
                  })
                }
              }
            }
          }
          if(argsArr.length > 0){
            const postData = {
              argsArr: argsArr
            }
            setNumSave(o => o + 1)
            ax.post(pettyCashApiNm.insertPCTransactionBulk, postData, false).then(value => {
              setNumSave(o => o - 1)
              if (value.data) {
    
              }
            })

          }
          return result
        })
      }
    })
    // ax.post(driverIncomeApiNm.getJobDriverPay, { JobIds: jobIds }).then(value => {
    //   if (value.data) {
    //     setDataTable(o => {
    //       const result = [...o]
    //       result.forEach(row => {
    //         // if(row.Amnt) return

    //         const foundJDPs = value.data.filter(item => item.JobId === row.JobId)
    //         for (const item of foundJDPs) {
    //           if ((item.ContSize && item.ContSize !== row.ContSize)
    //             || (item.ContTyp && item.ContTyp !== row.ContTyp)
    //             || (item.TlrTypId && item.TlrTypId !== row.TlrTypId)) continue;

    //           row.Amnt = item.Amnt
    //           row.ExtAmnt = item.ExtAmnt
    //           row.ExtTxt = item.ExtTxt
    //           return;
    //         }
    //       })
    //       return result
    //     })
    //   }
    // })
  }, [ax, dataTable, isCellEditable, user.AccId])

  const handleOpenExpensePOI = useCallback(() => {
    initExpPOIData = null
    setDialogExpensePOIOpen(true)
  }, [])

  const handleSetAmnt = useCallback((row) => {
    initExpPOIData = {
      ExpId: LOC_AMNT_ID,
      JobId: row.JobId,
      IsCost: 1,
    }
    setDialogExpensePOIOpen(true)
  }, [])


  const getCellClassName = useCallback(({ row, field }) => {
    if ([...takeCols, ...locCols, ...rtnCols].includes(field) && !isCellEditable({ row, field })) {
      return "no-edit"
    }
    if ([...takeCols, "TakeDrvNm", "TakePlc", "TakeDiff", "TakeArvTm", "TakeLevTm"].includes(field)) {
      return "group-take"
    }
    if ([...locCols, "LocDrvNm", "Loc", "LocDiff", "ArvTm", "LevTm", "AptTmTimeOnly"].includes(field)) {
      return "group-loc"
    }
    if ([...rtnCols, "RtnDrvNm", "RtnPlc"].includes(field)) {
      return "group-take"
    }
  }, [isCellEditable])

  const handleShowOtherAmnt = useCallback((row)=>{
    selectedJobOrdId = row.JobOrdId
    setDialogOtherOpen(true)
  }, [])

  const onOtherDialogFinish = useCallback(()=>{
    getDataFromDailyJob(filterData)
  }, [getDataFromDailyJob, filterData])

  const driverIncomeDailyColumnMemo = useMemo(() => driverIncomeDailyColumn({ drvId: filterData.DrvId, handleSetAmnt, handleShowOtherAmnt })
    , [filterData.DrvId, handleSetAmnt, handleShowOtherAmnt])

  useEffect(() => {
    if (dialogOpen === false) {
      setFilterData(initData)
      setDataTable([])
      initExpPOIData = null
    } else {
      setFilterData(o => ({ ...o, AptTm: dayjs().add(-1, 'month').startOf('month') }))
      setDriverCombo(msData.driverOnlyCombo)
    }
  }, [dialogOpen, msData.driverOnlyCombo])
  console.log("dataTable:: ", dataTable)

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='false'
      PaperProps={{
        style: {
          minHeight: '90%',
          maxHeight: '90%',
          minWidth: '95%',
          maxWidth: '95%',
        }
      }}>
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="เพิ่มรายการจากงานประจำวัน"
        onDeleteClick={null}
      />
      <DialogContent sx={{ display: "flex" }}>
        <BoxFC flex={1} sx={{ position: "relative" }}>
          <BoxFR mt={2}>
            <DateTimeSelect
              sx={{ width: 180 }}
              label="วันที่วิ่งงาน"
              start={filterData.AptTmSt}
              end={filterData.AptTmEn}
              setSelectDate={(st, en) => { setFilterData(o => ({ ...o, AptTmSt: st, AptTmEn: en })) }}
              getData={() => { getDataFromDailyJob(filterData) }}
            />
            <ComboBox options={driverCombo} label={jobOrderColNm.DrvNm} sx={{ width: 200 }}
              selectedId={filterData.DrvId}
              setSelectedId={handleSelectDriverCombo} />
            <SearchButton onClick={() => getDataFromDailyJob(filterData)} />
            <Box flex={1} />
            <Button {...buttonProps} onClick={handleOpenExpensePOI}><BuildCircleRounded sx={{ mr: 1 }} />กำหนดค่าใช้จ่าย</Button>
            <Button {...buttonProps} onClick={handleJobDriverPayClick}><CloudDownloadRoundedIcon sx={{ mr: 1 }} />ดึงข้อมูลรับส่งคืน</Button>
          </BoxFR>
          <Box width="100%" flex={1}
            sx={{
              "& .no-amnt": {
                bgcolor: yellow[100],
                "&:hover": { bgcolor: yellow[200] }
              },
              "& .no-edit": { bgcolor: grey[300] },
              "& .MuiDataGrid-row:hover .no-edit, .Mui-selected .no-edit": { bgcolor: grey[400] },
              "& .group-take": { bgcolor: blue[50] },
              "& .MuiDataGrid-row:hover .group-take, .Mui-selected .group-take": { bgcolor: blue[100] },
              "& .group-loc": { bgcolor: purple[50] },
              "& .MuiDataGrid-row:hover .group-loc, .Mui-selected .group-loc": { bgcolor: purple[100] },
              "& .group-rtn": { bgcolor: green[50] },
              "& .MuiDataGrid-row:hover .group-rtn, .Mui-selected .group-rtn": { bgcolor: green[100] },
            }}>
            <DataGridCellExpand
              checkboxSelection
              disableSelectionOnClick
              selectionModel={selectionModel}
              onSelectionModelChange={(ids) => { setSelectionModel(ids) }}
              hideFooter
              rows={dataTable}
              columns={driverIncomeDailyColumnMemo}
              // onCellEditStop={(params) => { handleCellEditStop(params) }}
              onCellEditCommit={(params) => { handleCellEditCommit(params) }}
              isCellEditable={isCellEditable}
              getCellClassName={getCellClassName} />
          </Box>
          {numSave > 0 ?

            <BoxFR sx={{ position: "absolute", width: "100%", justifyContent: "center", top: 0, left: 0 }}>
              <BoxFR sx={{ px: 1, py: 0.5, mt: 0.5, borderRadius: 1, bgcolor: grey[200], position: "relative" }}>
                <CircularProgress size={20} />
                <Typography variant="caption">กำลังบันทึกข้อมูล...</Typography>
                <Typography variant="caption" sx={{ position: "absolute", left: 14, top: 5 }}>{numSave}</Typography>
              </BoxFR>
            </BoxFR> : null
          }
        </BoxFC>
        <ExpensePOIDialog dialogOpen={dialogExpensePOIOpen}
          setDialogOpen={setDialogExpensePOIOpen}
          initData={initExpPOIData} />
        <OtherDialog dialogOpen={dialogOtherOpen}
          setDialogOpen={setDialogOtherOpen}
          jobOrdId={selectedJobOrdId}
          onFinish={onOtherDialogFinish} />
      </DialogContent>
      <DialogFooter cancelText="ปิด"
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={null} />
    </Dialog>
  )
}

export default React.memo(AddFromDailyJobDialog)