
import { alertWarning } from 'components/Alert';
import { read } from 'xlsx';

/**
 * 
 * @param {*} data can be file or arrayBuffer 
 */
export const readPL = async (data) => {
  const arrayBuffer = await data.arrayBuffer();
  // const f = await(await fetch(`https://dev.emily.bittosen.com/file/shipping/${shpInvId}/${xlsxFiles[0]}`)).arrayBuffer();

  console.log("f::", arrayBuffer)
  const wb = read(arrayBuffer); // parse the array buffer
  const ws0 = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
  const ws1 = wb.Sheets[wb.SheetNames[1]]; // get the first worksheet

  // const xlsxData = utils.sheet_to_json(ws); // generate objects
  // console.log("xls data::", xlsxData)
  console.log("ws data::", ws0, ws1)
  const scanData = (ws, colSt, rowSt, colEn, rowEn, isNewLine) => {
    let composeData = ""
    for (let i = colSt.charCodeAt(0); i <= colEn.charCodeAt(0); i++) {
      for (let j = rowSt; j <= rowEn; j++) {
        // console.log(`${String.fromCharCode(i)}${j}`, ws[`${String.fromCharCode(i)}${j}`]) 
        if (ws[`${String.fromCharCode(i)}${j}`]) {
          composeData += ws[`${String.fromCharCode(i)}${j}`].v
          if (isNewLine) {
            composeData += "\n"
          } else {
            composeData += " "
          }
        }
      }
    }
    // console.log("composeData::", composeData)
    return composeData
  }
  const tmpExcelData = {
    IP_Shpr: scanData(ws0, "A", 4, "C", 8, true),
    IP_Csgn: scanData(ws0, "A", 10, "C", 15, true),
    IP_NofyPrty: scanData(ws0, "A", 17, "C", 21, true),
    IP_PoL: scanData(ws0, "A", 23, "A", 23, false),
    IP_CoL: scanData(ws0, "B", 23, "C", 23, false),
    IP_Vssl: scanData(ws0, "A", 25, "A", 25, false),
    IP_ETD: scanData(ws0, "B", 25, "C", 25, false),
    IP_InvNo: scanData(ws0, "D", 4, "D", 8, false),
    IP_InvDte: scanData(ws0, "F", 4, "I", 8, false),
    IP_PayTrm: scanData(ws0, "E", 9, "I", 10, false),
    IP_NmPlc: scanData(ws0, "E", 11, "I", 12, false),
    IP_CoO: scanData(ws0, "D", 14, "F", 15, false),
    IP_CoC: scanData(ws0, "G", 14, "I", 15, false),
    IP_Rmk: scanData(ws0, "E", 16, "I", 21, false),
    IP_FinlDst: scanData(ws0, "D", 23, "F", 23, false),
    IP_CoD: scanData(ws0, "G", 23, "I", 23, false),
    IP_BkgNo: scanData(ws0, "D", 25, "F", 25, false),
    IP_ShpmTrm: scanData(ws0, "G", 25, "I", 25, false),
    IP_PO: "",
    IP_PoD: ""
  }

  const rowData = []
  const START_ROW = 28
  let runRow = START_ROW
  let isRunnig = true
  let lastMark = ""
  let index = 0
  while (isRunnig) {
    const Dscp = ws0[`B${runRow}`]?.v
    
    if (Dscp && Dscp.toUpperCase().startsWith("TOTAL")) {
      break;
    }

    const QtyCT = +ws0[`D${runRow}`]?.v
    const QtyPCS = +ws0[`E${runRow}`]?.v
    const Price = +ws0[`F${runRow}`]?.v
    const Amnt = +ws0[`G${runRow}`]?.v
    const NW = +ws0[`H${runRow}`]?.v
    const QtyM = +ws0[`I${runRow}`]?.v
    const GW = +ws1[`G${runRow}`]?.v
    const M3 = +ws1[`H${runRow}`]?.v
    const Rmk = ws1[`I${runRow}`]?.v
    if (Dscp && !QtyCT && !QtyPCS && !Price) {
      lastMark = Dscp
    }
    if (Dscp && QtyCT && QtyPCS) {
      rowData.push({
        No: index + 1,
        id: index + 1,
        Mrk: lastMark,
        Dscp, QtyCT, QtyPCS, Price, Amnt, NW, QtyM, GW, M3, Rmk
      })
      index++
    }
    // console.log("Dscp::", Dscp, QtyCT, QtyPCS, Price, Amnt, NW, QtyM)
    if (Dscp) {
      if (Dscp.toUpperCase().startsWith("P.O") || Dscp.startsWith("PO")) {
        const pos = Dscp.split(":")
        tmpExcelData.IP_PO = pos.length > 1 ? pos[1].trim() : pos[0].trim()
        
      } else if (Dscp.toUpperCase().startsWith("PLACE OF DELIVERY")) {
        const pdds = Dscp.split(":")
        tmpExcelData.IP_PoD = pdds.length > 1 ? pdds[1].trim() : pdds[0].trim()
      } else if (Dscp.toUpperCase().startsWith("TOTAL")) {
        isRunnig = false
      }
    }

    runRow++
    if (runRow > 500) {
      isRunnig = false
    }
  }
  
  if(tmpExcelData.IP_PO.trim() === ""){
    const rmks = tmpExcelData.IP_Rmk.split("\n")
    const foundPO = rmks.find((rmk) => rmk.toUpperCase().indexOf("PO")>=0)
    if(foundPO){
      tmpExcelData.IP_PO = foundPO.replace("PO", "").replace("#", "").trim()
    } 
  }

  /**
   *  cannot use GW, M3, Rmk from prev loop coz some tiem they not in the same row
   * 
   *  then another problem is they have same Dscp with different row
   * 
   *  now match GW, M3, Rmk by index of ws0
   */
  isRunnig = true
  runRow = START_ROW
  const notFoundDscp = []
  let foundIndex = 0
  while (isRunnig) {
    const dscp = ws1[`B${runRow}`]?.v
    const GW = +ws1[`G${runRow}`]?.v
    const M3 = +ws1[`H${runRow}`]?.v
    if(dscp){
      if (dscp.toUpperCase().startsWith("TOTAL")) {
        break;
      }
      if(rowData[foundIndex]?.Dscp.trim().toUpperCase() === dscp.trim().toUpperCase()){
        rowData[foundIndex].GW = +ws1[`G${runRow}`]?.v
        rowData[foundIndex].M3 = +ws1[`H${runRow}`]?.v
        rowData[foundIndex].Rmk = ws1[`I${runRow}`]?.v
        foundIndex++ 
      } else if(dscp && GW && M3) {
        notFoundDscp.push(dscp)
      }
      // const found = rowData.find((row) => row.Dscp.trim().toUpperCase() === dscp.trim().toUpperCase())
      // if (found) {
      //   found.GW = +ws1[`G${runRow}`]?.v
      //   found.M3 = +ws1[`H${runRow}`]?.v
      //   found.Rmk = ws1[`I${runRow}`]?.v
      // } else if (dscp && qtyCT && qtyPCS) { 
      //   notFoundDscp.push(dscp)
      // }
    }
    runRow++
    if(runRow > 500) break;
  }
  if(notFoundDscp.length > 0){
    alertWarning(`มีข้อมูล ${notFoundDscp.join(",")} ใน Invoice แต่ไม่พบข้อมูลใน Packing List กรุณาตรวจสอบข้อมูลอีกครั้ง`)
  }

  tmpExcelData.rowData = rowData
  return tmpExcelData
}