import { GridActionsCellItem } from "@mui/x-data-grid";
import { jobDriverPayColNm } from "constants/driverIncomeConstant";
import { jobOrderColNm } from "constants/jobOrderConstant";
import { numberFormat, numberFormatNoFlotingPoint } from "utils";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import {  Button, IconButton, Typography } from "@mui/material";
import dayjs from "dayjs";
import { getPOIPlace } from "utils/getPOIPlace";
import { ArticleRounded, BuildCircleRounded, DeleteForever, RemoveCircle } from "@mui/icons-material";
import { BoxFR } from "components/BoxCustom";
import { allFixColExpIds, colId } from "./constant";

export const driverNoIncomeColumns = [
  {
    field: "No",
    headerName: "No.",
    width: 60,
    align: "center",
  },
]
export const jobDriverPayColumns = [
  {
    field: "No",
    headerName: "No.",
    width: 60,
    align: "center",
  },
  {
    field: "CusSNm",
    headerName: jobDriverPayColNm.CusSNm,
    width: 150,
  },
  {
    field: "JobSNm",
    headerName: jobDriverPayColNm.JobSNm,
    width: 150,
  },
  {
    field: "ContSize",
    headerName: jobDriverPayColNm.ContSize,
    valueFormatter: (params) => params.value || "-",
    width: 80,
  },
  {
    field: "ContTyp",
    headerName: jobDriverPayColNm.ContTyp,
    valueFormatter: (params) => params.value || "-",
    width: 80,
  },
  {
    field: "TlrTypNm",
    headerName: jobDriverPayColNm.TlrTypNm,
    valueFormatter: (params) => params.value || "-",
    width: 80,
  },
  {
    field: "Amnt",
    headerName: jobDriverPayColNm.Amnt,
    width: 100,
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "",
    align: "right",
    headerAlign: "left",
    type: "number",
    editable: true
  },
  {
    field: "ExtAmnt",
    headerName: jobDriverPayColNm.ExtAmnt,
    width: 100,
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "",
    align: "right",
    headerAlign: "left",
    type: "number",
    editable: true
  },
  {
    field: "ExtTxt",
    headerName: jobDriverPayColNm.ExtTxt,
    flex: 1,
    editable: true
  },
  {
    field: "Prty",
    headerName: jobDriverPayColNm.Prty,
    width: 80,
    align: "center",
    headerAlign: "left",
    type: "number",
    editable: true,

    valueGetter: (params) => params.value ? parseInt(params.value) : 100,
  },
  {
    field: "Rmk",
    headerName: jobDriverPayColNm.Rmk,
    flex: 1,
    editable: true
  },
]

export const jobDriverPayDailyColumns = (handleAddIconClick) => [
  ...jobDriverPayColumns,
  {
    field: "actions",
    type: "actions",
    width: 30,
    getActions: (params) => [
      <GridActionsCellItem
        showInMenu
        icon={<AddCircleRoundedIcon />}
        label="เพิ่ม งาน-ค่าเที่ยว"
        onClick={() => handleAddIconClick(params.row)}
      />
    ],
  }
]
// const driverIncomeColumnTemplate = [
//   {
//     field: "No",
//     headerName: "No.",
//     width: 60,
//     align: "center",
//   }, {
//     field: "JobOrdId",
//     headerName: jobOrderColNm.JobOrdId,
//     width: 85,
//     align: "center",
//   },
//   {
//     field: "AptTm",
//     headerName: "เวลานัด",
//     width: 100,
//     type: 'dateTime',
//     valueGetter: (params) => params.value && dayjs(params.value),
//     valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
//   },
//   {
//     field: "ArvTm",
//     headerName: "เวลาเข้า",
//     width: 100,
//     type: 'dateTime',
//     valueGetter: (params) => params.value && dayjs(params.value),
//     valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
//   },
//   {
//     field: "LevTm",
//     headerName: "เวลาออก",
//     width: 100,
//     type: 'dateTime',
//     valueGetter: (params) => params.value && dayjs(params.value),
//     valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
//   },
//   {
//     field: "ContNo",
//     headerName: jobOrderColNm.ContNo,
//     width: 120,
//   },
//   {
//     field: "TakePlc",
//     headerName: jobOrderColNm.TakePlc,
//     valueGetter: (params) => getPOIPlace(params.row, "TAKE"),
//     width: 90,
//   },
//   {
//     field: "Loc",
//     headerName: jobOrderColNm.Loc,
//     valueGetter: (params) => getPOIPlace(params.row, "LOC"),
//     width: 120,
//   },
//   {
//     field: "RtnPlc",
//     headerName: jobOrderColNm.RtnPlc,
//     valueGetter: (params) => getPOIPlace(params.row, "RTN"),
//     width: 90,
//   },
//   {
//     field: "ContSizeFull",
//     headerName: jobOrderColNm.ContSize,
//     width: 80,
//   },
//   {
//     field: "TlrTyp",
//     headerName: jobOrderColNm.TlrTyp,
//     width: 90,
//   },
//   {
//     field: "JORmk",
//     headerName: "หมายเหตุใบงาน",
//     flex: 1,
//   },
//   {
//     field: "Amnt",
//     headerName: jobDriverPayColNm.Amnt,
//     width: 90,
//     align: "right",
//     editable: true,
//     type: "number",
//     valueFormatter: (params) => params.value ? numberFormat(+params.value) : "",
//     // valueFormatter: (params) => `${params.value}`,
//   },
//   {
//     field: "Rmk",
//     headerName: jobDriverPayColNm.Rmk,
//     editable: true,
//     flex: 1,
//   },
// ]
// const isValidDriver = (row, contStsId) => row.ContStsObj[contStsId]?.DrvId && !row.ContStsObj[contStsId]?.IsSub

const driverIncomeDailyColumn = ({ drvId, handleSetAmnt, handleShowOtherAmnt }) => [
  {
    field: "No",
    headerName: "No.",
    width: 60,
    align: "center",
  },
  {
    field: "JobOrdId",
    headerName: jobOrderColNm.JobOrdId,
    width: 85,
    align: "center",
  },
  {
    field: "JobSNm",
    headerName: "งาน",
    width: 150,
    renderCell: ({ row, value }) => (
      <BoxFR sx={{ gap: 0 }}>
        <IconButton sx={{ p: 0 }} onClick={() => { handleSetAmnt(row) }}>
          <BuildCircleRounded fontSize="small" color="action" />
        </IconButton>
        <Typography variant="body2">{value}</Typography>
      </BoxFR>
    )
  },
  {
    field: "AptTm",
    headerName: "เวลานัด",
    width: 100,
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
  },
  {
    field: "ContNo",
    headerName: jobOrderColNm.ContNo,
    width: 120,
  },
  {
    field: "ContSizeFull",
    headerName: jobOrderColNm.ContSize,
    width: 80,
  },
  {
    field: "TlrTyp",
    headerName: jobOrderColNm.TlrTyp,
    width: 90,
  },
  {
    field: "TakeDrvNm",
    headerName: "พนักงานรับ",
    width: 80,
    valueGetter: ({ row }) => row.ContStsObj[1]?.DrvNNm || "",
    renderCell: ({ row }) => {
      return (
        <Button sx={{ width: 60, height: 30 }}
          variant={row.ContStsObj[1]?.DrvNNm ? "contained" : "outlined"}
          color={row.ContStsObj[1]?.IsSub ? "inherit" : !drvId ? row.ContStsObj[1]?.Clr : row.ContStsObj[1]?.DrvId === drvId ? row.ContStsObj[1].Clr : "inherit"}
        >
          {row.ContStsObj[1]?.DrvNNm || ""}
        </Button>
      )
    },
    // cellClassName: "group-take"
  },
  {
    field: "TakePlc",
    headerName: jobOrderColNm.TakePlc,
    valueGetter: (params) => getPOIPlace(params.row, "TAKE"),
    width: 90,
    // cellClassName: "group-take"
  },
  {
    field: "TakeAmnt",
    headerName: "ค่ารับ",
    width: 60,
    align: "right",
    editable: true,
    type: "number",
    valueFormatter: ({ value }) => value ? +value : "",
    valueGetter: ({ row }) => row.PCTrnsObj[colId.TakeAmnt]?.Amnt
    // cellClassName: ({value, row}) => row.ContStsObj[1] ? value ? "" : "no-amnt": "",
    // cellClassName: "group-take"
  },
  {
    field: "LashAmnt",
    headerName: "คลุมผ้า/Lashing",
    width: 60,
    align: "right",
    editable: true,
    type: "number",
    valueFormatter: ({ value }) => value ? +value : "",
    valueGetter: ({ row }) => row.PCTrnsObj[colId.LashAmnt]?.Amnt
    // cellClassName: "group-take"
  },
  {
    field: "TakeArvTm",
    headerName: "เข้ารับ",
    width: 60,
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("HH:mm") : "-",
    // cellClassName: "group-take"
  },
  {
    field: "TakeLevTm",
    headerName: "ออกรับ",
    width: 60,
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("HH:mm") : "-",
    // cellClassName: "group-take"
  },
  {
    field: "TakeDiff",
    headerName: ">4:30",
    width: 60,
    // cellClassName: "group-take"
  },
  {
    field: "TakeWaitAmnt",
    headerName: "ค่ารอรับ",
    width: 70,
    align: "right",
    editable: true,
    type: "number",
    valueFormatter: ({ value }) => value ? +value : "",
    valueGetter: ({ row }) => row.PCTrnsObj[colId.TakeWaitAmnt]?.Amnt
    // cellClassName: ({value, row}) => row.TakeDiff?  value ? "" : "no-amnt": "",
    // cellClassName: "group-take"
  },
  {
    field: "LocDrvNm",
    headerName: "พนักงานส่ง",
    width: 80,
    valueGetter: ({ row }) => row.ContStsObj[3]?.DrvNNm || "",
    renderCell: ({ row }) => {
      // console.log("row::", row)
      return (
        <Button sx={{ width: 60, height: 30 }}
          variant={row.ContStsObj[3]?.DrvNNm ? "contained" : "outlined"}
          color={row.ContStsObj[1]?.IsSub ? "inherit" : !drvId ? row.ContStsObj[3]?.Clr : row.ContStsObj[3]?.DrvId === drvId ? row.ContStsObj[3].Clr : "inherit"}
        >
          {row.ContStsObj[3]?.DrvNNm || ""}
        </Button>
      )
    },
    // cellClassName: "group-loc"
  },
  {
    field: "Loc",
    headerName: jobOrderColNm.Loc,
    valueGetter: (params) => getPOIPlace(params.row, "LOC"),
    width: 160,
    // cellClassName: "group-loc",

  },
  {
    field: "LocAmnt",
    headerName: "ค่าส่ง",
    width: 60,
    align: "right",
    editable: true,
    type: "number",
    valueFormatter: ({ value }) => value ? +value : "",
    valueGetter: ({ row }) => row.PCTrnsObj[colId.LocAmnt]?.Amnt
    // cellClassName: ({value, row}) => row.ContStsObj[3] ? value ? "" : "no-amnt": "",
    // cellClassName: "group-loc"
  },
  {
    field: "ArvTm",
    headerName: "เข้ารง",
    width: 60,
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("HH:mm") : "-",
    // cellClassName: "group-loc"
  },
  {
    field: "LevTm",
    headerName: "ออกรง",
    width: 60,
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("HH:mm") : "-",
    // cellClassName: "group-loc"
  },
  {
    field: "LocDiff",
    headerName: ">4:30",
    width: 60,
    // cellClassName: "group-loc"
  },
  {
    field: "LocWaitAmnt",
    headerName: "ค่ารอส่ง",
    width: 60,
    align: "right",
    editable: true,
    type: "number",
    valueFormatter: ({ value }) => value ? +value : "",
    valueGetter: ({ row }) => row.PCTrnsObj[colId.LocWaitAmnt]?.Amnt
    // cellClassName: "group-loc"
    // cellClassName: ({value, row}) => row.LocDiff?  value ? "" : "no-amnt": "",
  },
  {
    field: "AptTmTimeOnly",
    headerName: "เวลานัด",
    width: 60,
    type: 'dateTime',
    valueGetter: ({ row }) => row.AptTm && dayjs(row.AptTm),
    valueFormatter: (params) => params.value ? params.value.format("HH:mm") : "-",
    // cellClassName: "group-loc"
  },
  {
    field: "OTAmnt",
    headerName: "ค่า OT",
    width: 60,
    align: "right",
    editable: true,
    type: "number",
    valueFormatter: ({ value }) => value ? +value : "",
    valueGetter: ({ row }) => row.PCTrnsObj[colId.OTAmnt]?.Amnt
    // cellClassName: "group-loc"
  },
  {
    field: "RtnDrvNm",
    headerName: "พนักงานคืน",
    width: 80,
    valueGetter: ({ row }) => row.ContStsObj[5]?.DrvNNm || "",
    renderCell: ({ row }) => {
      return (
        <Button sx={{ width: 60, height: 30 }}
          variant={row.ContStsObj[5]?.DrvNNm ? "contained" : "outlined"}
          color={row.ContStsObj[1]?.IsSub ? "inherit" : !drvId ? row.ContStsObj[5]?.Clr : row.ContStsObj[5]?.DrvId === drvId ? row.ContStsObj[5].Clr : "inherit"}
        >
          {row.ContStsObj[5]?.DrvNNm || ""}
        </Button>
      )
    },
    // cellClassName: "group-rtn"
  },
  {
    field: "RtnPlc",
    headerName: jobOrderColNm.RtnPlc,
    valueGetter: (params) => getPOIPlace(params.row, "RTN"),
    width: 90,
    // cellClassName: "group-rtn"
  },
  {
    field: "RtnAmnt",
    headerName: "ค่าคืน",
    width: 60,
    align: "right",
    editable: true,
    type: "number",
    valueFormatter: ({ value }) => value ? +value : "",
    valueGetter: ({ row }) => row.PCTrnsObj[colId.RtnAmnt]?.Amnt
    // cellClassName: "group-rtn"
    // cellClassName: ({value, row}) => row.ContStsObj[1]? value ? "" : "no-amnt": "",
  },
  {
    field: "XrayAmnt",
    headerName: "ค่า Xray",
    width: 60,
    align: "right",
    editable: true,
    type: "number",
    valueFormatter: ({ value }) => value ? +value : "",
    valueGetter: ({ row }) => row.PCTrnsObj[colId.XrayAmnt]?.Amnt
    // cellClassName: "group-rtn"
  },
  {
    field: "JORmk",
    headerName: "หมายเหตุใบงาน",
    flex: 1,
    minWidth: 200,
  },
  {
    field: "Othamnt",
    headerName: "ค่า อื่นๆ",
    flex: 1,
    minWidth: 200,
    valueGetter: ({row }) => (row.PCTrns?.filter(item => !allFixColExpIds.includes(item.ExpId)) ||[])
      .map(item=>`${item.ExpNm}(${item.DrvNNm}):${item.Amnt}`).join(", "),
    renderCell: ({row, value}) =>(
      <BoxFR>
        <IconButton sx={{p: 0}}  onClick={()=>handleShowOtherAmnt(row)}>
          <ArticleRounded color="primary" size="small" />
        </IconButton>
        {value}
      </BoxFR>
    )
  },
  // {
  //   field: "Amnt",
  //   headerName: jobDriverPayColNm.Amnt,
  //   width: 90,
  //   align: "right",
  //   editable: true,
  //   type: "number",
  //   valueFormatter: (params) => params.value ? numberFormat(+params.value) : "",
  //   // valueFormatter: (params) => `${params.value}`,
  // },
  // {
  //   field: "Rmk",
  //   headerName: jobDriverPayColNm.Rmk,
  //   editable: true,
  //   flex: 1,
  // },
]
// driverIncomeDailyColumn.splice(5, 0,
//   {
//     field: "ContStses",
//     headerName: "พนักงานขับรถ",
//     width: 300,
//     valueGetter: (params) => params.value.map(item => item.DrvNNm || "---").join(","),
//     renderCell: (params) => {
//       return (
//         <Box display="flex" gap={0.4} >
//           {
//             params.row.ContStses.map((item, index) => (
//               <Button key={item.ContStsId} sx={{ width: 60, height: 30 }}
//                 variant={item.DrvNNm ? "contained" : "outlined"}
//                 color={item.Clr}>
//                 {item.DrvNNm}
//               </Button>
//             ))
//           }
//         </Box>
//       )
//     },
//   })
// const driverIncomeColumn = driverIncomeColumnTemplate.map(item=>({...item}))
// driverIncomeColumn.splice(1, 0, 
//   {
//     field: "NName",
//     headerName: "พนักงานขับรถ",
//     width: 90,
//   })
// driverIncomeColumn.forEach(item=>{
//   item.editable = false
// })
export { driverIncomeDailyColumn }


export const otherColumns = ({ showDetail, handleDelete }) => [
  {
    field: 'ShowDetail',
    headerName: '',
    width: 40,
    align: 'center',
    renderCell: (params) => {
      return (
        <IconButton size="small" onClick={() => showDetail(params.row)}>
          <ArticleRounded color="primary" />
        </IconButton>
      )
    }
  },
  {
    field: "No",
    headerName: "No.",
    width: 40,
    align: "center",
  },
  {
    field: "ExpNm",
    headerName: "ชื่อค่าใช้จ่าย",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "DrvNNm",
    headerName: "พนักงานขับรถ",
    width: 120,
  },
  {
    field: "Amnt",
    headerName: "ราคา",
    width: 70,
    align: "right",
  },
  {
    field: "Dscp",
    headerName: "รายละเอียด",
    minWidth: 120,
  },
  {
    field: 'delete',
    headerName: '',
    width: 40,
    align: 'center',
    renderCell: (params) => {
      return (
        <IconButton size="small" onClick={() => handleDelete}>
          <DeleteForever color="action" />
        </IconButton>
      )
    }
  },

]
export const printDriverIncomeColumns = [
  {
    field: "No",
    headerName: "No.",
    width: 60,
    align: "center",
  },
  {
    field: "AptTm",
    headerName: "เวลานัด",
    width: 100,
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
  },
  {
    field: "ContNo",
    headerName: jobOrderColNm.ContNo,
    width: 120,
  },
  {
    field: "TakePlc",
    headerName: jobOrderColNm.TakePlc,
    valueGetter: (params) => getPOIPlace(params.row, "TAKE"),
    width: 90,
  },
  {
    field: "Loc",
    headerName: jobOrderColNm.Loc,
    valueGetter: (params) => getPOIPlace(params.row, "LOC"),
    width: 120,
  },
  {
    field: "RtnPlc",
    headerName: jobOrderColNm.RtnPlc,
    valueGetter: (params) => getPOIPlace(params.row, "RTN"),
    width: 90,
  },
  {
    field: "TlrTyp",
    headerName: jobOrderColNm.TlrTyp,
    width: 90,
  },
  {
    field: "Amnt",
    headerName: jobDriverPayColNm.Amnt,
    width: 90,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "",
  },
  {
    field: "ExtAmnt",
    headerName: jobDriverPayColNm.ExtAmnt,
    width: 90,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "",
  },
  {
    field: "ExtTxt",
    headerName: jobDriverPayColNm.ExtTxt,
    width: 120,
  },
  {
    field: "Total",
    headerName: "รวม",
    width: 90,
    align: "right",
    valueGetter: (params) => +params.row.Amnt + +params.row.ExtAmnt,
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : ""
  },
  {
    field: "Rmk",
    headerName: jobDriverPayColNm.Rmk,
    flex: 1,
  },
]

export const printColumnHeader = [
  {
    id: "No",
    name: "No",
    prompt: "No.",
    width: 0.4,
    align: "center",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "AptTm",
    name: "AptTm",
    prompt: "วันที่เข้า รง.",
    width: 1,
    align: "center",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "ContNo",
    name: "ContNo",
    prompt: "หมายเลขตู้",
    width: 1.15,
    align: "center",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "Loc",
    name: "Loc",
    prompt: "สถานที่",
    width: 2.5,
    align: "center",
    padding: 0,
    separateRow: "#TEXT#"
  },
  {
    id: "TlrTyp",
    name: "TlrTyp",
    prompt: "หาง",
    width: 0.7,
    align: "center",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "Amnt",
    name: "Amnt",
    prompt: "ค่าเที่ยว",
    width: 0.9,
    align: "right",
    padding: 0,
    separateRow: "#SUM#"
  },
  {
    id: "ExtAmnt",
    name: "ExtAmnt",
    prompt: "เงินพิเศษ",
    width: 0.7,
    align: "right",
    padding: 0,
    separateRow: "#SUM#"
  },
  {
    id: "ExtTxt",
    name: "ExtTxt",
    prompt: "รายระเอียด",
    width: 0.9,
    align: "left",
    padding: 0,
    separateRow: "-"
  },
  {
    id: "Total",
    name: "Total",
    prompt: "รวม",
    width: 0.9,
    align: "right",
    padding: 0,
    separateRow: "#SUM#"
  },
  {
    id: "Rmk",
    name: "Rmk",
    prompt: "หมายเหตุ",
    width: 1.0,
    padding: 0,
    separateRow: "-"
  },
]

const checkTakeRtnBaseColumns = [
  {
    field: "No",
    headerName: "No.",
    width: 40,
    align: "center",
  },
  {
    field: "JobOrdId",
    headerName: jobOrderColNm.JobOrdId,
    width: 85,
    align: "center",
  },
  {
    field: "AptTm",
    headerName: "เวลานัด",
    width: 100,
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
  },
  {
    field: "ContNo",
    headerName: jobOrderColNm.ContNo,
    width: 120,
  },
  {
    field: "TakePlc",
    headerName: jobOrderColNm.TakePlc,
    valueGetter: (params) => getPOIPlace(params.row, "TAKE"),
    width: 80,
  },
  {
    field: "Loc",
    headerName: jobOrderColNm.Loc,
    valueGetter: (params) => getPOIPlace(params.row, "LOC"),
    width: 120,
  },
  {
    field: "RtnPlc",
    headerName: jobOrderColNm.RtnPlc,
    valueGetter: (params) => getPOIPlace(params.row, "RTN"),
    width: 80,
  },
  {
    field: "ContSizeFull",
    headerName: jobOrderColNm.ContSize,
    width: 80,
  },
  {
    field: "TlrTyp",
    headerName: jobOrderColNm.TlrTyp,
    width: 80,
  },
  {
    field: "JORmk",
    headerName: "หมายเหตุใบงาน",
    flex: 1,
  },
]

export const checkTakeColumns = (handleRemoveRow) => [
  ...checkTakeRtnBaseColumns,
  {
    field: "TakeDrvId",
    headerName: "รับตู้",
    width: 80,
    valueGetter: ({ row }) => row.TakeNNm
  },
  {
    field: "LocDrvId",
    headerName: "ส่งตุ้",
    width: 80,
    valueGetter: ({ row }) => row.LocNNm
  },
  {
    field: "actions",
    type: "actions",
    width: 30,
    getActions: (params) => [
      <GridActionsCellItem
        icon={<RemoveCircle />}
        label="Delete"
        onClick={handleRemoveRow(params)}
      />
    ]
  },
]

export const checkRtnColumns = (handleRemoveRow) => [
  ...checkTakeRtnBaseColumns,
  {
    field: "BackDrvId",
    headerName: "พักลาน",
    width: 80,
    valueGetter: ({ row }) => row.BackNNm
  },
  {
    field: "RtnDrvId",
    headerName: "คืนตุ้",
    width: 80,
    valueGetter: ({ row }) => row.RtnNNm
  },
  {
    field: "actions",
    type: "actions",
    width: 30,
    getActions: (params) => [
      <GridActionsCellItem
        icon={<RemoveCircle />}
        label="Delete"
        onClick={handleRemoveRow(params)}
      />
    ]
  },
]

export const driverIncomeColumns = [
  {
    field: "No",
    headerName: "No.",
    width: 40,
    align: "center",
  },
  {
    field: "FLNNm",
    headerName: "พนักงานขับรถ",
    width: 200,
  },
  {
    field: "PayTm",
    headerName: "เดือน",
    width: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("MM/YYYY") : "-";
    },
  },
  {
    field: "TakeAmnt",
    headerName: "ค่ารับ",
    width: 90,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormatNoFlotingPoint(+params.value) : "",
  },
  {
    field: "LocAmnt",
    headerName: "ค่าส่ง",
    width: 90,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormatNoFlotingPoint(+params.value) : "",
  },
  {
    field: "RtnAmnt",
    headerName: "ค่าคืน",
    width: 90,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormatNoFlotingPoint(+params.value) : "",
  },
  {
    field: "TakeWaitAmnt",
    headerName: "ค่ารอรับ",
    width: 90,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormatNoFlotingPoint(+params.value) : "",
  },
  {
    field: "LashAmnt",
    headerName: "ค่าคลุมผ้า Lashing",
    width: 90,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormatNoFlotingPoint(+params.value) : "",
  },
  {
    field: "LocWaitAmnt",
    headerName: "ค่ารอส่ง",
    width: 90,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormatNoFlotingPoint(+params.value) : "",
  },
  {
    field: "OTAmnt",
    headerName: "ค่า OT",
    width: 90,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormatNoFlotingPoint(+params.value) : "",
  },
  {
    field: "XrayAmnt",
    headerName: "ค่า Xray",
    width: 90,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormatNoFlotingPoint(+params.value) : "",
  },
  {
    field: "OthAmnt",
    headerName: "ค่าอื่นๆ",
    width: 90,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormatNoFlotingPoint(+params.value) : "",
  },
  {
    field: "OthExp",
    headerName: "รายละเอียดค่าอื่นๆ",
    width: 200,
  },
  {
    field: "TotalAmnt",
    headerName: "รวมเบี้ยเลี้ยง",
    width: 90,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormatNoFlotingPoint(+params.value) : "",
  },
]