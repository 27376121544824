import { Box, Dialog, DialogContent, IconButton, TextField, Typography } from "@mui/material"
import { BoxFC, BoxFR } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useState, useMemo } from "react"
import { initOtherDialogData } from "./initData"
import { jobOrderColNm } from "constants/jobOrderConstant"
import dayjs from "dayjs"
import { otherColumns } from "./columns"
import { AddCircleRounded } from "@mui/icons-material"
import ExpenseDialog from "./ExpenseDialog"
import { allFixColExpIds } from "./constant"
import { addIdForDataGrid } from "utils"
import { alertConfirmDelete } from "components/Alert"
import { pettyCashApiNm } from "pages/Menu1/PettyCash/constant"
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand"
import { branchApiNm } from "branch/constant/branchApiNm"

let seelctedPCTrnsId = null
let hasChange = false

const OtherDialog = ({ jobOrdId, dialogOpen, setDialogOpen, onFinish }) => {

  const { ax } = useContext(GlobalStateContext)
  const [dialogData, setDialogData] = useState({ ...initOtherDialogData })
  const [dataTable, setDataTable] = useState([])
  const [dialogExpenseOpen, setDialogExpenseOpen] = useState(false)

  const textFieldProps =  useCallback((name) =>({
    size: "small",
    fullWidth: true,
    value: dialogData[name],
    label: jobOrderColNm[name]
  }), [dialogData])

  const getData = useCallback(() => {
    
    ax.post(branchApiNm.getDriverIncomeFromDailyJob, { JobOrdId: jobOrdId }).then(value => {
      if (value.data && value.data.length > 0) {
        setDialogData(value.data[0])
        const pcTransactions = value.data[0].PCTrns.filter(item => !allFixColExpIds.includes(item.ExpId))
        setDataTable(addIdForDataGrid(pcTransactions, "PCTrnsId"))
      }
    })
  }, [ax, jobOrdId])
  const showDetail = useCallback((row) => {
    seelctedPCTrnsId = row.PCTrnsId
    setDialogExpenseOpen(true)
  }, [])
  const handleRowDoubleClick = useCallback((params) => {showDetail(params.row)}, [showDetail])


  const handleDelete = useCallback((row) => {
    alertConfirmDelete(()=>{
      ax.post(pettyCashApiNm.deletePCTransaction, { PCTrnsId: row.PCTrnsId }).then(value => {
        if(value.data){
          setDataTable(o=> o.filter(item => item.PCTrnsId !== row.PCTrnsId))
          hasChange = true
        }
      })
    })
  }, [ax ])

  const handleAddExpense = useCallback(()=>{
    seelctedPCTrnsId = null
    setDialogExpenseOpen(true)
  }, [])

  const otherColumnsMemo = useMemo(() => otherColumns({showDetail, handleDelete}), [showDetail, handleDelete])

  const onDialogExpenseFinish = useCallback(()=>{
    getData()
    hasChange= true
  }, [getData])

  const handleDialogClose = useCallback(()=>{
    if(hasChange){
      onFinish()
    }
    setDialogOpen(false)
  }, [onFinish, setDialogOpen])
  useEffect(() => {
    if (dialogOpen) {
      getData()
    } else {
      setDialogData({ ...initOtherDialogData })
      setDataTable([])
      hasChange = false
    }
  }, [dialogOpen, getData])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='md'
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="รายละเอียดค่าใช้จ่ายอื่นๆ"
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFR pt={2} height="100%" sx={{alignItems: "stretch"}} >
          <BoxFC sx={{ gap: 1, width: 220 }}>
            <Typography variant="h6">ข้อมูลใบงาน</Typography>
            <TextField {...textFieldProps("JobOrdId")} />
            <TextField {...textFieldProps("JobSNm")} label="ชื่องาน" />
            <TextField {...textFieldProps("AptTm")} value={dialogData.AptTm ? dayjs(dialogData.AptTm).format("DD/MM/YYYY HH:mm") : "-"} />
            <TextField {...textFieldProps("Loc")} label="สถานที่" />
            <TextField {...textFieldProps("ContSizeFull")} />
            <TextField {...textFieldProps("Rmk")} multiline rows={3} />
          </BoxFC>
          <BoxFC sx={{ gap: 1, flex: 1, height: "100%" }}>
            <BoxFR>
              <Typography variant="h6">รายการค่าใช้จ่ายอื่นๆ</Typography>
              <IconButton sx={{p: 0}}onClick={(handleAddExpense)} >
                <AddCircleRounded color="primary" />
              </IconButton>
            </BoxFR>
            <Box height={325} >
              <DataGridCellExpand
                hideFooter
                hideToolbar
                rows={dataTable}
                columns={otherColumnsMemo}
                onRowDoubleClick={handleRowDoubleClick}
              />
            </Box>
          </BoxFC>
        </BoxFR>
        <ExpenseDialog 
          pcTrnsId={seelctedPCTrnsId} 
          joData={dialogData}
          dialogOpen={dialogExpenseOpen} 
          setDialogOpen={setDialogExpenseOpen} 
          onFinish={onDialogExpenseFinish} />
      </DialogContent>
      <DialogFooter okText={""}
        handleDialogClose={handleDialogClose}
        handleDialogOk={null} />
    </Dialog>
  )
}

export default React.memo(OtherDialog)