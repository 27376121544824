import { ArticleRounded, CloudUploadRounded, ImageRounded } from "@mui/icons-material"
import { Button, IconButton, Tooltip, Typography } from "@mui/material"
import { BoxFR } from "components/BoxCustom";
import dayjs from "dayjs";


const renderSendData = (fn) => (params, isPOD) =>
  params.value ? (
    <BoxFR>
      <Typography variant="body2">{dayjs(params.value).format("DD/MM HH:mm")}</Typography>
      <Tooltip title="ส่งข้อมูล" arrow placement="top">
        <IconButton sx={{ p: 0 }} onClick={() => fn(params.row)}>
          <CloudUploadRounded color="primary" fontSize="small" />
        </IconButton>
      </Tooltip>
    </BoxFR>
  ) : (
    <Button size="small" variant="contained" onClick={() => fn(params.row)}>
      <CloudUploadRounded sx={{ mr: 1 }} />{isPOD? "เลือกรูป":"ส่งข้อมูล"} 
    </Button>
  )

export const columns = ({ handleViewImageClick, sendDispatchTruck, sendATD, sendATA, sendPOD }) => [
  {
    field: 'ShowDetail',
    headerName: '',
    width: 40,
    align: 'center',
    renderCell: (params) => {
      return (
        <IconButton size="small" >
          <ArticleRounded color="primary" />
        </IconButton>
      )
    }
  },
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: 'center',
  },
  {
    field: "AptTm",
    headerName: "เวลานัด",
    width: 120,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "JobOrdId",
    headerName: "เลขที่ใบงาน",
    width: 100,
  },
  {
    field: "businessRefNo",
    headerName: "JOB No. OOCL",
    width: 120,
    editable: true,
  },
  {
    field: "plateNo",
    headerName: "ทะเบียนรถ",
    width: 100,
    editable: true,
  },
  {
    field: "driver",
    headerName: "พนักงานขับรถ",
    width: 100,
    editable: true,
  },
  {
    field: "driverMobile",
    headerName: "หมายเลขโทรศัพท์",
    width: 120,
    editable: true,
  },
  {
    field: "driverIdCardNo",
    headerName: "หมายเลขบัตรประชาชน",
    width: 140,
    editable: true,
  },
  {
    field: "plannedPickupTime",
    headerName: "คาดการณ์เวลารับตู้",
    width: 120,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "plannedArrivalTime",
    headerName: "คาดการณ์เวลาถึง",
    width: 120,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "ContNo",
    headerName: "หมายเลขตู้",
    width: 120,
    editable: true,
  },
  {
    field: "createTime",
    headerName: "เวลาส่งข้อมูลตู้",
    width: 120,
    type: "dateTime",
    // valueFormatter: (params) => {
    //   return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    // },
    renderCell: renderSendData(sendDispatchTruck)
  },
  {
    field: "atd_eventTime",
    headerName: "เวลาออกจากท่า",
    width: 120,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "atd_createTime",
    headerName: "เวลาส่งข้อมูออกจากท่า",
    width: 120,
    type: "dateTime",
    renderCell: renderSendData(sendATD)
  },
  {
    field: "ata_eventTime",
    headerName: "เวลาถึง รง.",
    width: 120,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "ata_createTime",
    headerName: "เวลาส่งข้อมูลถึง รง.",
    width: 120,
    type: "dateTime",
    renderCell: renderSendData(sendATA)
  },
  // {
  //   field: "pod_eventTime",
  //   headerName: "เวลาเซ็นต์ใบงาน",
  //   width: 120,
  //   type: "dateTime",
  //   valueFormatter: (params) => {
  //     return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
  //   },
  // },
  {
    field: "JobOrdFileId",
    headerName: "รูปภาพเซ็นใบงาน",
    width: 120,
    align: 'center',
    renderCell: (params) => (
      <IconButton disabled={!params.value} onClick={()=>handleViewImageClick(params)}>
        <ImageRounded color={params.value?"primary": "action"}/>
      </IconButton>
    )
  },
  {
    field: "pod_createTime",
    headerName: "เวลาส่งข้อมูลเซ็นต์ใบงาน",
    width: 120,
    type: "dateTime",
    renderCell: renderSendData(sendPOD, true)
  },
]