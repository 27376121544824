import { KeyboardDoubleArrowLeftRounded, KeyboardDoubleArrowRightRounded, PersonSearchRounded, PrintRounded, RefreshRounded, SettingsEthernetRounded } from "@mui/icons-material";
import { Box, Button, IconButton, Paper, TextField, Typography } from "@mui/material";
import { BoxFC, BoxFR } from "components/BoxCustom";
import ComboBox from "components/ComboBox";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { UserContext } from "contexts/UserContext";
import React, { useContext, useState, useCallback, useMemo } from "react";
import { driverColumns } from "./columns";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import { blue, blueGrey, green, grey } from "@mui/material/colors";
import DailyJobDialogV2 from "pages/Menu1/DailyJob/DailyJobDialogV2";
// import { branchApiNm } from "branch/constant/branchApiNm";
import dayjs from "dayjs";
import ClearButton from "components/buttons/ClearButton";
import { jobOrderColNm } from "constants/jobOrderConstant";
import { alertError } from "components/Alert";
import { getDataAndPrintJobOrder } from "utils/getDataAndPrintjobOrder";
import CheckboxFormControl from "components/CheckboxFormControl";
import ClearJobOrderDocDialog from "./ClearJobOrderDocDialog";
// import { addInArray } from "./function";


const sxHover = {
  "& .MuiDataGrid-row:hover": {
    bgcolor: grey[300],
    "& .today": { bgcolor: blue[200] },
    "& .tomorrow": { bgcolor: green[200] },
    "& .not-attend": { bgcolor: grey[400] }
  },
  "& .selected": { bgcolor: blue[50] },
  "& .selected:hover": { bgcolor: blue[100] },
  "& .today": { bgcolor: blue[100] },
  "& .tomorrow": { bgcolor: green[100] },
  "& .not-attend": { bgcolor: grey[300] }
}

const boxExpandProps = {
  bgcolor: blueGrey[50],
  borderRadius: 1,
  p: 0,
  border: 1,
  borderColor: blueGrey[200],
  sx: { cursor: "pointer" }
}

let selectedJobOrdId = null

const initFilterData = {
  Bgk: "",
  JobNo: "",
  DrvId: null,
  CusId: null,
  JobId: null,
  FDay: {
    yd: false,
    td: false,
    tm: false,
    atm: false
  }
}

const initColsWidth = driverColumns().reduce((prev, cur) => ({ ...prev, [cur.field]: cur.width }), {})

const DriverData = ({ driverData, driverWithBatt, setDriverData, refreshData, selectionDrvs, setSelectionDrvs
  , setLeftPanelWidth, leftPanelWidth, driverAttendData, onFinish, setJobData }) => {

  const { ax, msData } = useContext(GlobalStateContext)
  const { user } = useContext(UserContext)

  // const [jobCombo, setJobCombo] = useState(msData.jobCombo)

  const [filterData, setFilterData] = useState({ ...initFilterData })
  const [dailyJobDialogOpen, setDailyJobDialogOpen] = useState(false)
  const [selectionPrint, setSelectionPrint] = useState([])
  const [showCardDetail, setShowCardDetail] = useState(false)
  const [colsWidth, setColsWidth] = useState(initColsWidth)
  const [drvIdJobOrder, setDrvIdJobOrder] = useState(null)
  const [dialogClearJobOrderDocOpen, setDialogClearJobOrderDocOpen] = useState(false)

  const filterFDayAllUnchecked = useMemo(() => {
    return Object.keys(filterData.FDay).every(key => filterData.FDay[key] === false)
  }, [filterData.FDay])

  const textFieldProp = useCallback((name) => ({
    label: jobOrderColNm[name],
    size: "small",
    sx: { width: 120 },
    value: filterData[name],
    onChange: (e) => { setFilterData(o => ({ ...o, [name]: e.target.value })) },
  }), [filterData])

  const checkboxProps = useCallback((name) => ({
    value: filterData.FDay[name],
    onChange: (e) => { setFilterData(o => ({ ...o, FDay: { ...o.FDay, [name]: e.target.checked } })) }
  }), [filterData])

  const driverTable = useMemo(() => {
    const drvData = driverWithBatt.map(item => {
      const addnDrv = driverAttendData.filter(attn => attn.DrvId === item.DrvId )
      const attnTd = addnDrv.find(attn => dayjs(attn.DrvAttnDte).date() === dayjs().date())
      const attnTm = addnDrv.find(attn => dayjs(attn.DrvAttnDte).date() === dayjs().add(1, "day").date())
      const attnAtm = addnDrv.find(attn => dayjs(attn.DrvAttnDte).date() === dayjs().add(2, "day").date())
      return {
        id: item.DrvId,
        DrvId: item.DrvId,
        TukCd: item.TukCd,
        NName: item.NName,
        Batt: item.Batt,
        AttnTd: attnTd?.DrvAttnStsId || 1,
        AttnTm: attnTm?.DrvAttnStsId || 1,
        AttnAtm: attnAtm?.DrvAttnStsId || 1,
        yd: [],
        td: [],
        tm: [],
        atm: [],
        all: []
      }
    })
    // const drvData = msData.drivers.filter(item => item.IsSub === 0).map(item => ({
    //   id: item.DrvId,
    //   DrvId: item.DrvId,
    //   TukCd: msData.trucks.find(t => t.TukId === item.TukId)?.Code,
    //   NName: item.NName,
    //   yd: [],
    //   td: [],
    //   tm: [],
    //   atm: [],
    //   all: []
    // }))
    for (const data of driverData) {
      if (!data.DrvId) continue

      const driver = drvData.find(drv => drv.DrvId === data.DrvId)

      if (!driver) continue

      driver.all.push(data)
      if (!data.AptTm) {
        continue
        // addInArray(result.nd, data)
      } else if (dayjs(data.AptTm).isBefore(dayjs().startOf("day"))) {
        driver.yd.push(data)
      } else if (dayjs(data.AptTm).startOf("day").isSame(dayjs().startOf("day"))) {
        driver.td.push(data)
      } else if (dayjs(data.AptTm).startOf("day").isSame(dayjs().add(1, "day").startOf("day"))) {
        driver.tm.push(data)
      } else {
        driver.atm.push(data)
      }
    }
    return drvData
  }, [driverData, driverWithBatt, driverAttendData])

  const filteredDriverData = useMemo(() => {
    let tableResult = driverTable.map(item => ({ ...item }))
    if (filterData.Bgk) {
      tableResult = tableResult.filter(item => {
        return item.all.some(jo => jo.Bkg.indexOf(filterData.Bgk) > -1)
      })
    }
    if (filterData.JobNo) {
      tableResult = tableResult.filter(item => {
        return item.all.some(jo => jo.JobNo.indexOf(filterData.JobNo) > -1)
      })
    }
    if (filterData.CusId) {
      tableResult = tableResult.filter(item => {
        return item.all.some(jo => jo.CusId === filterData.CusId)
      })
    }
    if (filterData.JobId) {
      tableResult = tableResult.filter(item => {
        return item.all.some(jo => jo.JobId === filterData.JobId)
      })
    }
    if (filterData.DrvId) {
      tableResult = tableResult.filter(item => {
        return item.DrvId === filterData.DrvId
      })
    }
    console.log("filterData.FDay::", filterData.FDay)
    if (filterData.FDay.yd) {
      console.log("item.yd::", tableResult)
      tableResult = tableResult.filter(item => {
        return item.yd.length === 0
      })
    }
    if (filterData.FDay.td) {
      tableResult = tableResult.filter(item => {
        return item.td.length === 0
      })
    }
    if (filterData.FDay.tm) {
      tableResult = tableResult.filter(item => {
        return item.tm.length === 0
      })
    }
    if (filterData.FDay.atm) {
      tableResult = tableResult.filter(item => {
        return item.atm.length === 0
      })
    }
    return tableResult
  }, [filterData, driverTable])

  const handleRemoveJobOrder = useCallback((item) => {
    // console.log("in handleRemove item::", item)
    const postData = {}
    const jobOrdId = item.JobOrdId
    const now = dayjs().format("YYYY-MM-DD HH:mm:ss")
    const contStsTemplate = { JobOrdId: jobOrdId, DrvId: null, TukId: null, StsTm: now }
    postData.contStatusData = [
      { ContStsId: 1, ...contStsTemplate },
      { ContStsId: 3, ...contStsTemplate },
      { ContStsId: 5, ...contStsTemplate }
    ]

    // const isYd = dayjs(item.AptTm).isBefore(dayjs().startOf("day"))
    // const isTd = dayjs(item.AptTm).isSame(dayjs(), "day")
    // const isTm = dayjs(item.AptTm).isSame(dayjs().add(1, 'day'), "day")
    // const isAtm = dayjs(item.AptTm).isSame(dayjs().add(2, 'day'), "day")
    // const isNd = dayjs(item.AptTm).isAfter(dayjs().add(2, 'day').endOf('day'))
    // const newItem = {...item, DrvId: null, TukId: null}
    // setJobData(o=>{
    //   const result = {...o}
    //   console.log("result.yd  ::", result.yd)
    //   if(isYd){
    //     console.log("isYd::", isYd)
    //     addInArray(result.yd, newItem) 
    //   }
    //   // isYd && 
    //   // isTd && addInArray(result.td, newItem) 
    //   // isTm && addInArray(result.tm, newItem) 
    //   // isAtm && addInArray(result.atm, newItem) 
    //   // isNd && addInArray(result.nd, newItem) 
    //   return result
    // })

    // setJobData(o=>({
      
    //   all: [...o.all, newItem],
    //   yd: isYd ?  addInArray(o.yd, newItem) : o.yd,
    //   td: isTd ? addInArray(o.td, newItem) : o.td,
    //   tm: isTm ? addInArray(o.tm, newItem) : o.tm,
    //   atm: isAtm ? addInArray(o.atm, newItem) : o.atm,
    //   nd: isNd ? addInArray(o.nd, newItem) : o.nd
    // }))
    // postData.jobOrdArr = [{
    //   JobOrdId: jobOrdId,
    //   ColValues: {
    //     DrvId: null,
    //     TukId: null,
    //     DrvActTm: now,
    //   },
    //   LogRmk: "แก้ไขผู้รับผิดชอบงาน"
    // }]
    // postData.getArgs = { getFn: "getJobPlanning" }


    // ax.post(branchApiNm.updateJobOrderAllDetail, postData).then(value => {
    //   if (value.data) {
    //     onFinish(value.data)
    //   }
    // })
  }, [/*ax, onFinish*/])

  const handleJobOrderClick = useCallback((jobOrdId) => {
    // e.stopPropagation()
    selectedJobOrdId = jobOrdId
    setDailyJobDialogOpen(true)
  }, []);

  const handleBkgClick = useCallback((shpmId) => (e) => {
    e.stopPropagation()
    setDriverData(o => {
      const result = [...o]
      for (const jo of result) {
        jo.hilightShpmId = shpmId
      }
      return result
    })
  }, [setDriverData])

  const onDailyJobDialogFinish = useCallback(() => {
    refreshData()
  }, [refreshData])

  // const handleCusComboChange = useCallback((id) => {
  //   setFilterData(o => ({ ...o, CusId: id }))

  //   setJobCombo(id ? msData.jobCombo.filter(item => item.refId === id) : msData.jobCombo)
  // }, [msData.jobCombo])

  const handleClearFilter = useCallback(() => {
    setFilterData({ ...initFilterData })
    setSelectionDrvs([])
  }, [setSelectionDrvs])

  const handlePrint = useCallback(() => {
    if (selectionPrint.length === 0) {
      alertError("กรุณาเลือกอย่างน้อย 1 ใบงาน")
      return;
    }

    getDataAndPrintJobOrder(ax, msData, selectionPrint, user.FName)
  }, [ax, msData, selectionPrint, user.FName])

  const columns = useMemo(() => {
    const drvFree = { yd: 0, td: 0, tm: 0, atm: 0, all: driverTable.length }
    for (const drv of driverTable) {
      drvFree.yd += drv.yd.length === 0 ? 1 : 0
      drvFree.td += drv.td.length === 0 ? 1 : 0
      drvFree.tm += drv.tm.length === 0 ? 1 : 0
      drvFree.atm += drv.atm.length === 0 ? 1 : 0
    }
    return driverColumns(handleRemoveJobOrder, handleBkgClick, handleJobOrderClick
      , filterData, selectionPrint, setSelectionPrint, showCardDetail, colsWidth, drvFree)
  }, [handleRemoveJobOrder, handleBkgClick, handleJobOrderClick
    , filterData, selectionPrint, setSelectionPrint, showCardDetail, colsWidth, driverTable])

  const handleColumnChnage = useCallback((params) => {
    setColsWidth(o => ({ ...o, [params.colDef.field]: params.width }))
  }, [])

  const openClearJobOrderDocDialog = useCallback(() => {
    setDialogClearJobOrderDocOpen(true)
  }, [])
  console.log("DriverTable render::", driverTable)
  return (
    <BoxFC sx={{ flex: 1 }} position="relative">

      <Paper sx={{ flex: 1, p: 1 }}>
        <BoxFC height="100%" sx={{ gap: 1 }}>
          <BoxFR>
            <Typography variant="h6">ตารางงานของ พขร. </Typography>
            <IconButton onClick={refreshData}>
              <RefreshRounded color="primary" />
            </IconButton>
            <Box flex={1} />


          </BoxFR>
          {leftPanelWidth <= 800 ? (

            <BoxFR >
              {/* <Box flex={1} /> */}
              <Typography variant="body">FILTER: </Typography>
              <TextField {...textFieldProp("JobNo")} />
              <BoxFR sx={{
                border: `${filterFDayAllUnchecked ? 1 : 2}px solid ${filterFDayAllUnchecked ? grey[400] : blue[700]}`,
                borderRadius: 1,
                p: 1,
                position: "relative",
              }}>
                <CheckboxFormControl {...checkboxProps("yd")} label="วันก่อน" />
                <CheckboxFormControl {...checkboxProps("td")} label="วันนี้" />
                <CheckboxFormControl {...checkboxProps("tm")} label="พรุ่งนี้" />
                <CheckboxFormControl {...checkboxProps("atm")} label="วันหน้า" />
                <Typography variant="caption" sx={{
                  position: "absolute", top: -10, left: 10, bgcolor: "white", px: 1
                }}>
                  พนักงานขับรถว่าง
                </Typography>
              </BoxFR>
              <ComboBox sx={{ width: 150 }} options={msData.driverOnlyCombo} label="พนักงานขับรถ"
                selectedId={filterData.DrvId}
                setSelectedId={(id) => setFilterData(o => ({ ...o, DrvId: id }))}
              />
              {/* <ComboBox sx={{ width: 150 }} options={msData.cusCombo} label="ลูกค้า"
                selectedId={filterData.CusId}
                setSelectedId={handleCusComboChange}
              /> */}
              <ComboBox sx={{ width: 120 }} options={msData.jobCombo} label="งาน"
                selectedId={filterData.JobId}
                setSelectedId={(id) => setFilterData(o => ({ ...o, JobId: id }))}
              />
              <ClearButton onClick={handleClearFilter} />
            </BoxFR>
          ) : null}
          <BoxFR>
            <Typography variant="body">เลือกทั้งหมด: {selectionDrvs.length} คน</Typography>
            <CheckboxFormControl value={showCardDetail} onChange={(e) => setShowCardDetail(e.target.checked)} label={"แสดงข้อมูลงาน"} />
            <Box flex={1} />
            <Button variant="contained" onClick={handlePrint}>{<PrintRounded sx={{ mr: 1 }} />} พิมพ์ใบงานที่เลือก</Button>

            <BoxFR >
              <ComboBox options={msData.driverOnlyCombo} label="รับใบงาน" sx={{ width: 120 }}
                selectedId={drvIdJobOrder}
                setSelectedId={(id) => setDrvIdJobOrder(id)} />
              <Button variant="contained" sx={{ minWidth: 0, p: 1 }} onClick={openClearJobOrderDocDialog}>
                <PersonSearchRounded />
              </Button>
            </BoxFR>
          </BoxFR>
          <Box flex={1} sx={sxHover}>
            <DataGridCellExpand
              componentsProps={{
                panel: {
                  sx: {
                    left: '150px !important',
                  },
                },
              }}
              checkboxSelection
              selectionModel={selectionDrvs}
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionDrvs(newSelectionModel);
              }}
              getRowHeight={() => 'auto'}
              disableSelectionOnClick
              hideFooter
              rows={filteredDriverData}
              columns={columns}
              onColumnWidthChange={handleColumnChnage} />
          </Box>
        </BoxFC>

      </Paper>
      <DailyJobDialogV2
        dialogOpen={dailyJobDialogOpen}
        setDialogOpen={setDailyJobDialogOpen}
        selectedId={selectedJobOrdId}
        onFinish={onDailyJobDialogFinish}
        lastFilter={null} />
      <ClearJobOrderDocDialog
        dialogOpen={dialogClearJobOrderDocOpen}
        setDialogOpen={setDialogClearJobOrderDocOpen}
        drvId={drvIdJobOrder}
      />

      <BoxFC position="absolute" top="40%" left={-15}>
        <Box {...boxExpandProps} onClick={() => { setLeftPanelWidth(500) }}>
          <KeyboardDoubleArrowLeftRounded color="primary" sx={{ fontSize: 25, mt: 1 }} />
        </Box>

        <Box {...boxExpandProps} onClick={() => { setLeftPanelWidth(800) }}>
          <SettingsEthernetRounded color="primary" sx={{ fontSize: 25, mt: 1 }} />
        </Box>
        <Box {...boxExpandProps} onClick={() => { setLeftPanelWidth(1100) }}>
          <KeyboardDoubleArrowRightRounded color="primary" sx={{ fontSize: 25, mt: 1 }} />
        </Box>
      </BoxFC>
    </BoxFC>
  )
}

export default DriverData