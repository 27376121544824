

import { readPDFLineData } from './readPDFLineData';
import { toNumber } from 'utils';
import {getDocument, GlobalWorkerOptions} from 'pdfjs-dist/build/pdf';
GlobalWorkerOptions.workerSrc = "https://unpkg.com/pdfjs-dist@4.3.136/build/pdf.worker.min.mjs";

const initRowDataObj = {
  No: 0,
  id: 0,
  Mrk: "",
  Code: "",
  Dscp: "",
  QtyCT: "",
  QtyPCS: "",
  Price: "",
  Amnt: "",
  NW: "",
  QtyM: "",
  GW: "",
  M3: "",
  Rmk: "",
}


const readTable = (lineObjs) => {
  let runLine = 0
  for (let i = 0; i < lineObjs.length; i++) {
    const obj = lineObjs[i]
    if (obj.objs[0].str.toLowerCase().trim() === "package no." ) {
      runLine = i + 1
      break;
    }
  }
  if (runLine === 0) {
    return []
  }

  const rowData = []

  for (let i = runLine; i < lineObjs.length; i++) {
    if (lineObjs[i].y < 108) {
      break
    }
    const rowDataObj = { ...initRowDataObj }
    const packageNo = lineObjs[i].objs[0].str
    if (packageNo.trim().toLowerCase() === "total") {
      break
    }
    /**
     * Some pdf file has not same column position
     * better check x position of header and x position of obj
     */
    for (let j = 0; j < lineObjs[i].objs.length; j++) {
      const obj = lineObjs[i].objs[j]
      if (obj.x >= 58 && obj.x <= 87) {
        if (rowDataObj.QtyCT === "")
          rowDataObj.QtyCT = toNumber(obj.str.trim())
      } else if (obj.x >= 88 && obj.x <= 117) {
        if (rowDataObj.QtyCT === "")
          rowDataObj.QtyCT = toNumber(obj.str.trim())
      } else if (obj.x >= 115 && obj.x <= 140) {
        if (rowDataObj.QtyPCS === "")
          rowDataObj.QtyPCS = toNumber(obj.str.trim())
      } else if (obj.x >= 190 && obj.x <= 220) {
          rowDataObj.Code = obj.str.trim()
      } else if (obj.x >= 280 && obj.x <= 435) {
        if (rowDataObj.Dscp === "") {
          const dscp = obj.str.trim()
          if ((dscp.startsWith("[") && dscp.endsWith("]")) 
            || dscp.toLowerCase().startsWith("order no")
            || ["packaging", "no pallet"].includes(dscp.toLowerCase())) {
            continue
          }
          if(dscp.startsWith("TANK TRUCK HOSE - TANK150 - (1.1")){
            console.log('dscp::', dscp)
          }
          rowDataObj.Dscp = obj.str.trim()
        }
      } else if (obj.x >= 465 && obj.x <= 500) {
        if (rowDataObj.NW === "")
          rowDataObj.NW = toNumber(obj.str.trim())
      } else if (obj.x >= 523 && obj.x <= 560) {
        if (rowDataObj.GW === "")
          rowDataObj.GW = toNumber(obj.str.trim())
      }
    }
    if ((rowDataObj.QtyPCS !== "" || rowDataObj.NW !== "" || rowDataObj.GW !== "") && rowDataObj.Dscp !== "") {
      rowData.push(rowDataObj)
    } else if (rowDataObj.Dscp !== "" && rowDataObj.QtyPCS === "" && rowDataObj.NW === ""
      && rowDataObj.GW === "" && rowData.length >= 1) {
      const lastChar = rowData[rowData.length - 1].Dscp.slice(-1);
      if (/[a-zA-Z0-9]/.test(lastChar)) {
        rowData[rowData.length - 1].Dscp += ` ${rowDataObj.Dscp}`;
      } else {
        rowData[rowData.length - 1].Dscp += rowDataObj.Dscp;
      }
    }
  }
  return rowData
}

export const readPLNCR = async (dataPL) => {
  return new Promise((resolve, reject) => {

    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const contents = e.target.result;
        const pdf = await getDocument(contents).promise
        const numPages = pdf.numPages;
        console.log("numPages::", numPages)
        let rowData = []
        for (let i = 1; i <= numPages; i++) {
          const page = await pdf.getPage(i); // get first page
          const content = await page.getTextContent();
          console.log('page::', page)
          console.log('content::', content)
          const lineObjs = readPDFLineData(content.items)
          console.log('lineObjs::', lineObjs)
          if (lineObjs.length === 0) {
            reject('No data found.')
            return
          }
          // const title = lineObjs[0].objs[0].str
          // if (title.toLowerCase() !== "packing list") {
          //   reject('Wrong File Type')
          //   return
          // }
          rowData = [...rowData, ...readTable(lineObjs)]
        }
        resolve(rowData)
      } catch (err) {
        reject(err.message)
      }
    }
    reader.readAsArrayBuffer(dataPL);
    reader.onerror = (error) => {
      console.log('Error: ', error)
      reject(error)
    };
  })
}