import { Dialog, DialogContent, TextField } from "@mui/material"
import { BoxFC } from "components/BoxCustom"
import ComboBox from "components/ComboBox"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import { UserContext } from "contexts/UserContext"
import dayjs from "dayjs"
import { pettyCashApiNm } from "pages/Menu1/PettyCash/constant"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { bahtProps } from "utils/bahtProps"

const initDialogData = {
  UsrAccId: null,
  ExpId: null,
  Dscp: "",
  Amnt: "",
}
const ExpenseDialog = ({ pcTrnsId, joData, dialogOpen, setDialogOpen, onFinish }) => {

  const { user } = useContext(UserContext)
  const { ax, msData } = useContext(GlobalStateContext)
  const [dialogData, setDialogData] = useState({ ...initDialogData })

  const suggestedDrvCombo = useMemo(() => {
    const drvIds = joData.ContStses?.reduce((prev, cur) => {
      if (cur.DrvId) prev.push(cur.DrvId)
      return prev
    }, []) || []
    if (drvIds.length > 0) {
      const suggestDrvs = msData.driverOnlyCombo.filter(item => drvIds.includes(item.id))
      return [
        ...suggestDrvs.map(item => ({ ...item, group: "แนะนำ" }))
        , ...msData.driverOnlyCombo.filter(item => !drvIds.includes(item.id)).map(item => ({ ...item, group: "พนักงานขับรถ" }))
      ]
    } else {
      return msData.driverOnlyCombo
    }
  }, [joData.ContStses, msData.driverOnlyCombo])

  const handleDelete = useCallback(() => {
    ax.post(pettyCashApiNm.deletePCTransaction, { PCTrnsId: pcTrnsId }).then(value => {
      if (value.data) {
        onFinish()
        setDialogOpen(false)
      }
    })
  }, [ax, pcTrnsId, onFinish, setDialogOpen])

  const handleDialogOK = useCallback(() => {
    const postData = {
      argsArr: [{
        AdmAccId: user.AccId,
        UsrAccId: dialogData.UsrAccId,
        InOutTyp: "O",
        ExpId: dialogData.ExpId,
        PCJnlId: 100,
        JobOrdId: joData.JobOrdId,
        PayTm: dayjs(joData.AptTm).endOf('month').format("YYYY-MM-DD 23:59:59"),
        Amnt: dialogData.Amnt,
        Dscp: dialogData.Dscp,
        IsCost: 1,
        IsNoDupJOEX: 1
      }]
    }
    ax.post(pettyCashApiNm.insertPCTransactionBulk, postData).then(value => {
      if (value.data) {
        onFinish()
        setDialogOpen(false)
      }
    })
  }, [ax, dialogData, joData, onFinish, setDialogOpen, user.AccId])
  useEffect(() => {
    if (dialogOpen) {
      if (pcTrnsId) {
        ax.post(pettyCashApiNm.getPettyCashTable, { PCTrnsIdExat: pcTrnsId }).then(value => {
          if (value.data && value.data.length > 0) {
            setDialogData(value.data[0])
          }
        })
      }
    } else {
      setDialogData({ ...initDialogData })
    }
  }, [dialogOpen, ax, pcTrnsId])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xs'
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="รายละเอียดค่าใช้จ่าย"
        onDeleteClick={handleDelete}
      />
      <DialogContent>
        <BoxFC pt={2} >
          <ComboBox sx={{ width: "100%" }} options={suggestedDrvCombo} label="พนักงานขับรถ"
            selectedId={dialogData.UsrAccId}
            setSelectedId={(id) => setDialogData(o => ({ ...o, UsrAccId: id }))}
          />
          <ComboBox sx={{ width: "100%" }} options={msData.expOprCombo} label="ชื่อค่าใช้จ่าย"
            selectedId={dialogData.ExpId}
            setSelectedId={(id) => setDialogData(o => ({ ...o, ExpId: id }))}
          />
          <TextField size="small" fullWidth label="จำนวนเงิน" type="number"
            {...bahtProps}
            value={dialogData.Amnt || ""}
            onChange={(e) => setDialogData(o => ({ ...o, Amnt: e.target.value }))} />
          <TextField fullWidth label="รายละเอียดเพิ่มเติม" value={dialogData.Dscp || ""} multiline rows={3}
            onChange={(e) => setDialogData(o => ({ ...o, Dscp: e.target.value }))} />
        </BoxFC>
      </DialogContent>
      <DialogFooter okText="บันทึก"
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleDialogOK} />
    </Dialog>
  )
}

export default React.memo(ExpenseDialog)