import { Dialog, DialogContent, TextField } from "@mui/material"
import { BoxFC } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import React, { useCallback,  useEffect, useState } from "react"

const EditCCEmailDialog = ({ initCCEmail, dialogOpen, setDialogOpen, onFinish }) => {

  const [ccEmail, setCCEmail] = useState("")
  
  const handleDialogOk = useCallback(() => {
    setDialogOpen(false)
    onFinish(ccEmail)
  }, [onFinish, ccEmail, setDialogOpen])
  useEffect(() => {
    if (dialogOpen) {
      setCCEmail(initCCEmail)
    } else {
      setCCEmail("")
    }
  }, [dialogOpen, initCCEmail])
  
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='sm' 
      >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="แก้ไข CC Emails"
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC mt={2} >
          <TextField size="small" label="CC Email" variant="outlined" fullWidth multiline rows={3}
          value={ccEmail}
          onChange={(e)=>setCCEmail(e.target.value)}
          helperText="หากมีหลาย Email คั่นด้วยจุลภาค (,)"/>
        </BoxFC>
      </DialogContent>
      <DialogFooter okText={"ตกลง"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleDialogOk} />
    </Dialog>
  )
}

export default React.memo(EditCCEmailDialog)