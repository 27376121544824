
import { readPL } from './readPL';
import { readInvNCR } from './readInvNCR';
import { readPLNCR } from './readPLNCR';
import { alertError, alertWarning } from 'components/Alert';
import { toNumber } from 'utils';

export const getPLData = async (file, nowShpInvId) => {
  const xlsxFiles = file.filter(file => file.ShpFileTypId === 1 && file.FileNm.toLowerCase().endsWith(".xlsx"))
    .map(file => `/file/shipping/${nowShpInvId}/${file.FileNm}`);

  const fetchData = await fetch(xlsxFiles[0]);
  console.log("fetchData::", fetchData);

  const plData = await readPL(fetchData);
  return plData;
}


export const getNCRPLData = async (files, shpInvId, msData) => {
  console.log("ingetNCRPLData::", files, shpInvId, msData)
  const invFile = files
    .filter(file => file.ShpFileTypId === 1
      && file.FileNm.toLowerCase().endsWith("pdf")
      && (file.Label.toLowerCase().endsWith("inv") || file.Label.toLowerCase().endsWith("inv.pdf")))
    .map(file => `/file/shipping/${shpInvId}/${file.FileNm}`)
  if (!invFile || invFile.length === 0) {
    alertError("ไม่พบไฟล์ Invoice")
    return
  }
  console.log('invFile::', invFile)

  const plFile = files
    .filter(file => file.ShpFileTypId === 1
      && file.FileNm.toLowerCase().endsWith("pdf")
      && (file.Label.toLowerCase().endsWith("pl") || file.Label.toLowerCase().endsWith("pl.pdf")))
    .map(file => `/file/shipping/${shpInvId}/${file.FileNm}`)
  if (!plFile || plFile.length === 0) {
    alertError("ไม่พบไฟล์ Packing List")
    return
  }

  let plData
  let fileIndex = 0
  for (const file of invFile) {
    const invRawData = await fetch(file)
    const invBlob = await invRawData.blob()
    console.log("invRawData::", invRawData)
    if (fileIndex === 0) {
      plData = await readInvNCR(invBlob, true).catch(err => {
        console.log('err::', err)
        alertError(`ไม่สามารถอ่านข้อมูลจากไฟล์ Invoice ได้: ${err}`)
      })
    } else {
      let morePLData = await readInvNCR(invBlob, true).catch(err => {
        console.log('err::', err)
        alertError(`ไม่สามารถอ่านข้อมูลจากไฟล์ Invoice ได้: ${err}`)
      })
      if (morePLData) {
        plData.rowData = [...plData.rowData, ...morePLData.rowData]
      }
    }
    fileIndex++
  }
  // const invRawData = await fetch(invFile[0])
  // const invBlob = await invRawData.blob()
  const plRawData = await fetch(plFile[0])
  const plBlob = await plRawData.blob()


  if (plData) {
    plData.Csgn = msData.customers.find(cus => cus.CusId === 29626)?.Name
  } else {
    return
  }
  let rowData = await readPLNCR(plBlob, true).catch(err => {
    console.log('err::', err)
    alertError(`ไม่สามารถอ่านข้อมูลจากไฟล์ Packing List ได้: ${err}`)

  })
  if (!rowData) return
  const errMsg = []

  for (const row of rowData) {
    // if (row.Dscp === "TANK TRUCK HOSE - TANK150 - (1.1/4\")32X42.5mmX100FT (30.49M)") {
    //   console.log("row::", row)
    // }
    // const filtered = plData.rowData.filter(r => r.Dscp.replace(/\s+/g, '') === row.Dscp.replace(/\s+/g, '')
    //   && r.QtyPCS !== r.QtyFromPL)
    const filtered = plData.rowData[0].Code ? plData.rowData.filter(r => r.Code === row.Code)
      : plData.rowData.filter(r => r.Dscp.replace(/\s+/g, '') === row.Dscp.replace(/\s+/g, '') && r.QtyPCS !== r.QtyFromPL)


    if (filtered.length > 0) {
      // if (filtered[0].Dscp === "TANK TRUCK HOSE - TANK150 - (2.1/2\")63.5X75mmX100FT (30.49M)") {
      //   debugger
      //   console.log("filtered::", filtered)
      // }
      // if (filtered.length === 1) {
      //   const found = filtered[0]
      //   if (row.QtyPCS === found.QtyPCS) {
      //     found.QtyFromPL = toNumber(row.QtyPCS)
      //     found.NW = toNumber(row.NW)
      //     found.GW = toNumber(row.GW)
      //   } else {
      //     found.QtyFromPL = toNumber(found.QtyFromPL) + toNumber(row.QtyPCS)
      //     found.NW = toNumber(row.NW) + toNumber(found.NW)
      //     found.GW = toNumber(row.NW) + toNumber(found.GW)
      //   }
      // } else {
      //   const foundSameQty = filtered.find(r => r.QtyPCS === row.QtyPCS)
      //   if (foundSameQty) {
      //     foundSameQty.QtyFromPL = toNumber(row.QtyPCS)
      //     foundSameQty.NW = toNumber(row.NW)
      //     foundSameQty.GW = toNumber(row.GW)
      //   } else {
      //     filtered[0].QtyFromPL = toNumber(filtered[0].QtyFromPL) + toNumber(row.QtyPCS)
      //     filtered[0].NW = toNumber(row.NW) + toNumber(filtered[0].NW)
      //     filtered[0].GW = toNumber(row.GW) + toNumber(filtered[0].GW)
      //   }
      // }
    } else {
      errMsg.push(`ไม่พบใน Inv "${row.Dscp}" `)
    }
  }
  const isFromCode = Boolean(plData.rowData[0].Code)

  plData.rowData.forEach((item, index) => {
    item.No = index + 1
    item.id = index + 1

    // if (item.QtyFromPL && item.QtyPCS !== item.QtyFromPL) {
    //   errMsg.push(`จำนวน Qty(PCS) ไม่ตรง "${item.Dscp}" (Inv: ${item.QtyPCS} PL: ${item.QtyFromPL})`)
    // }

    const found = isFromCode ? rowData.find(r => r.Code === item.Code)
      : rowData.find(r => r.Dscp.replace(/\s+/g, '') === item.Dscp.replace(/\s+/g, ''))

    if(found){
      // found.QtyFromPL = toNumber(row.QtyPCS)
      item.NW = toNumber(found.NW / found.QtyPCS * item.QtyPCS)
      item.GW = toNumber(found.GW / found.QtyPCS * item.QtyPCS)
    }
  })

  console.log("plData::", plData)
  console.log("rowData::", rowData)
  if (errMsg.length > 0) {
    alertWarning(`
    <p style="margin: 0 0 4px 0"><strong>ข้อมูลผิดพลาด</strong></p>
    <div style="width: 100%; white-space: nowrap;font-size: 0.9rem;line-height: 1.4">
      ${errMsg.join("<br />")}
    </div>`, { width: 650 })
  }
  return plData
}