
import { BoxFC, BoxFR } from 'components/BoxCustom';
import { GlobalStateContext } from 'contexts/GlobalStateContext';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom';
import Logo from "branch/resource/emily_blue_256.png"
import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import { shippingApiNm } from '../ShippingProcess/shippingApiNm';
import TaskRoundedIcon from '@mui/icons-material/TaskRounded';
import { grey } from '@mui/material/colors';
import { CancelRounded } from '@mui/icons-material';
import { packingListColNm } from './constant';
import { plColumns } from './columns';
import DataGridCellExpand from 'components/DataGridCellExpand/DataGridCellExpand';
import { initExcelData } from './initData';
import { alertError, alertSuccess } from 'components/Alert';
import { addSumRow, getSumColorClassName, sxSumColorClass } from "utils/highlightSumColor";
import dayjs from 'dayjs';
import Loading from 'components/Loading';
import { addIdForDataGrid } from 'utils';


const PackingListConfirm = ({ mShpInvId, onFinish, lastFilter }) => {

  const { shpInvId, token } = useParams()
  const { ax } = useContext(GlobalStateContext)

  const [validLink, setValidLink] = useState(null)
  const [pageData, setPageData] = useState({ InvNo: "", Files: [] })
  const [pdfData, setPdfData] = useState([])
  const [excelData, setExcelData] = useState({ ...initExcelData })
  const [dataTable, setDataTable] = useState([])
  const [rejectMsg, setRejectMsg] = useState("")
  const [rejectFile, setRejectFile] = useState(null)
  const [hideEdit, setHideEdit] = useState(false)
  const [localIsLoading, setLocalIsLoading] = useState(false)

  const modDataTable = useMemo(() => {
    if (dataTable.length > 0 && dataTable[dataTable.length - 1].id === -1) {
      return dataTable
    }
    // return dataTable
    return addSumRow({
      dataTable: dataTable,
      sumColNms: ["QtyCT", "QtyPCS", "Amnt", "NW", "QtyM", "GW", "M3"],
      sumColTotal: "Mrk"
    })
  }, [dataTable])

  const textFieldProps = useCallback((name) => ({
    size: "small",
    variant: "outlined",
    label: packingListColNm[name],
    value: excelData[name],
  }), [excelData])

  const getData = useCallback(() => {
    const nowShpInvId = shpInvId || mShpInvId
    setLocalIsLoading(true)

    ax.post(shippingApiNm.getShippingInvoiceDetail, { ShpInvId: nowShpInvId || mShpInvId }).then(async value => {
      if (value.data && value.data.length > 0) {
        const data = value.data[0]
        // console.log(data.Tokn, token)
        if (data.EDCfs.length > 0) {
          const edCf = data.EDCfs.reduce((prev, cur) => dayjs(cur.CreTm).isAfter(dayjs(prev.CreTm)) ? cur : prev, data.EDCfs[0])
          data.EDCf = edCf
          edCf?.IsCf !== null && edCf?.IsCf !== undefined && setHideEdit(true)
        }

        if ((data.EDCfs.length === 0 || data.EDCf?.Tokn !== token) && !mShpInvId) {
          setValidLink(0)
        } else {
          setValidLink(1)
          setPageData(data)

          const pdfFiles = data.Files
            .filter(file => file.ShpFileTypId === 2)
            .map(file => `/file/shipping/${nowShpInvId}/${file.FileNm}`)
            setPdfData(pdfFiles)

          setExcelData({
            IP_BkgNo: data.IP_BkgNo,
            IP_CoC: data.IP_CoC,
            IP_CoD: data.IP_CoD,
            IP_CoL: data.IP_CoL,
            IP_CoO: data.IP_CoO,
            IP_Csgn: data.IP_Csgn,
            IP_ETD: data.IP_ETD ? dayjs(data.IP_ETD).format("DD/MM/YYYY") : "",
            IP_FinlDst: data.IP_FinlDst,
            IP_InvDte: data.IP_InvDte ? dayjs(data.IP_InvDte).format("DD/MM/YYYY") : "",
            IP_InvNo: data.IP_InvNo,
            IP_NmPlc: data.IP_NmPlc,
            IP_NofyPrty: data.IP_NofyPrty,
            IP_PayTrm: data.IP_PayTrm,
            IP_PO: data.IP_PO,
            IP_PoD: data.IP_PoD,
            IP_PoL: data.IP_PoL,
            IP_Rmk: data.IP_Rmk,
            IP_ShpmTrm: data.IP_ShpmTrm,
            IP_Shpr: data.IP_Shpr,
            IP_Vssl: data.IP_Vssl,
            EDAprvTm: data.EDAprvTm
          })
          setDataTable(addIdForDataGrid(data.ShpInvPLItms, "ShpInvPLItmId"))
        }

      }

      setLocalIsLoading(false)
    })
  }, [ax, token, shpInvId, mShpInvId])

  const handleConfirm = useCallback((isConfirm) => async () => {
    if (!isConfirm) {
      if (rejectMsg === "" && rejectFile === null) {
        alertError("กรุณาระบุสาเหตุใบขนสินค้าไม่ถูกต้อง หรือ แนบไฟล์เพิ่มเติม")
        return
      }
    }
    setLocalIsLoading(true)

    /**Have to upload file first coz update time will send email with attached file */
    if (!isConfirm && rejectFile) {
      const formData = new FormData()
      formData.append("ShpInvId", shpInvId)
      formData.append("ShpEDCfId", pageData.EDCf.ShpEDCfId)
      formData.append("ShpFileTypId", 4)
      formData.append("Label", rejectFile.name)
      formData.append("file", rejectFile)
      // console.log("formData::", formData)
      await ax.post(shippingApiNm.uploadFile, formData)
    }

    const postData = {
      ShpInvId: shpInvId,
      ColNm: "EDCfTm",
      ShpEDCfId: pageData.EDCf.ShpEDCfId,
      IsCf: isConfirm,
      Msg: rejectMsg,
    }
    ax.post(shippingApiNm.updateShippingInvoiceTime, postData).then(async (value) => {
      if (value.data) {

        setRejectMsg("")
        setRejectFile(null)
        alertSuccess("บันทึกข้อมูลเรียบร้อยแล้ว")
        getData()
      }

      setLocalIsLoading(false)
    })
  }, [getData, shpInvId, pageData.EDCf, rejectMsg, ax, rejectFile])

  const handleEditClick = useCallback(() => {
    setHideEdit(false)
  }, [])

  const handleApproveClick = useCallback(() => {
    const postData = {
      ShpInvId: mShpInvId,
      ColNm: "EDAprvTm",
      getArgs: lastFilter
    }
    ax.post(shippingApiNm.updateShippingInvoiceTime, postData).then(value => {
      if (value.data) {
        if(onFinish){
          onFinish(value.data)
        }
      }
    })
  }, [ax, mShpInvId, lastFilter, onFinish])

  const handleFileChange = useCallback((e) => {
    setRejectFile(e.target.files[0])
  }, [])

  useEffect(() => {
    getData()
  }, [getData])

  console.log("IsCF::", pageData?.EDCf?.IsCf);
  console.log('excelData::', excelData)
  return (
    <BoxFC width="100%" height={shpInvId?"100vh":"100%"}>
      {

        validLink == null ? <></>
          : validLink === 0 ?
            <BoxFC alignItems="center">
              <img src={Logo} alt="Logo" width={300} />
              <Typography variant='h4'>ขออภัยไม่สามารถยืนยันใบขนได้</Typography>
              <Typography variant='body'>Link นี้อาจไม่ถูกต้อง หรือ หมดอายุแล้ว</Typography>
              <Link to="/client/Shipping" style={{ color: "blue" }}>
                ท่านสามารถตรวจสอบสถานะการขนส่งโดยรวมได้ที่นี่
              </Link>
            </BoxFC>
            :
            <BoxFC width="100%" flex={1} alignItems="center" pt={0} sx={{ gap: 0 }}>
              <Typography variant="h4"> {mShpInvId ? "การตรวจสอบใบขนสินค้า" : "การยืนยันใบขนสินค้า"} </Typography>
              {
                pageData?.EDCf?.IsCf !== null && pageData?.EDCf?.IsCf !== undefined && !mShpInvId &&
                <BoxFC alignItems="center" sx={{ gap: 0 }} pt={1}>
                  <Typography variant="body"> *** ใบขนสินค้านี้ได้รับการ <strong>{pageData.EDCf.IsCf ? "ยืนยัน" : "ปฏิเสธ"} </strong> เรียบร้อยแล้ว ***</Typography>
                  <Link to="/client/Shipping" style={{ color: "blue", margin: "8px 0" }}>
                    ท่านสามารถตรวจสอบสถานะการขนส่งโดยรวมได้ที่นี่
                  </Link>
                </BoxFC>
              }
              <BoxFR width="100%" flex={1} minHeight={shpInvId? 750:700} sx={{ alignItems: "stretch" }} pt={0}>
                <BoxFC height="100%" flex="1 0 700px" bgcolor="white" p={1} border="1px solid lightgrey" borderRadius={2} sx={{gap: 1}}>
                  <BoxFR>
                    <Typography variant='h6'>ข้อมูล Invoice-PackingList</Typography>
                    <Typography variant='h6' color="primary"> #<strong>{pageData.IP_InvNo}</strong></Typography>
                  </BoxFR>
                  <BoxFR mt={-1}>
                    <BoxFC flex={1} sx={{gap:1}}>
                      <TextField {...textFieldProps("IP_Shpr")} multiline rows={3} />
                      <TextField {...textFieldProps("IP_Csgn")} multiline rows={3} />
                      <TextField {...textFieldProps("IP_NofyPrty")} multiline rows={2} />
                    </BoxFC>
                    <BoxFC flex={1} sx={{gap: 1}}>
                      <BoxFR>
                        <TextField {...textFieldProps("IP_InvNo")} />
                        <TextField {...textFieldProps("IP_InvDte")} />
                      </BoxFR>
                      <BoxFR>
                        <TextField {...textFieldProps("IP_PayTrm")} />
                        <TextField {...textFieldProps("IP_NmPlc")} />
                      </BoxFR>
                      <BoxFR>
                        <TextField {...textFieldProps("IP_CoO")} />
                        <TextField {...textFieldProps("IP_CoC")} />
                      </BoxFR>
                      <BoxFR>
                        <TextField {...textFieldProps("IP_PO")} />
                        <TextField {...textFieldProps("IP_PoD")} />
                      </BoxFR>
                      <TextField {...textFieldProps("IP_Rmk")} multiline rows={2} />
                    </BoxFC>
                  </BoxFR>
                  <BoxFR>
                    <TextField {...textFieldProps("IP_PoL")} />
                    <TextField {...textFieldProps("IP_CoL")} />
                    <TextField {...textFieldProps("IP_FinlDst")} />
                    <TextField {...textFieldProps("IP_CoD")} />
                  </BoxFR>
                  <BoxFR>
                    <TextField {...textFieldProps("IP_Vssl")} />
                    <TextField {...textFieldProps("IP_ETD")} />
                    <TextField {...textFieldProps("IP_BkgNo")} />
                    <TextField {...textFieldProps("IP_ShpmTrm")} />
                  </BoxFR>
                  <Box width='100%' flexGrow={1} sx={sxSumColorClass}>
                    <DataGridCellExpand
                      hideFooter
                      density="compact"
                      getRowClassName={getSumColorClassName}
                      rows={modDataTable}
                      columns={plColumns(null)} />
                  </Box>
                </BoxFC>
                <BoxFC sx={{ gap: 0 }} flex="1 0 700px" height="100%" p={1} border="1px solid lightgrey" borderRadius={2} bgcolor="white">
                  <BoxFR justifyContent="space-between">
                    <Typography variant='h6'>ข้อมูล ใบขนสินค้า</Typography>
                    <Typography variant="caption">จัดทำโดย: <strong>{`${pageData.EDCreAccFNm} ${pageData.EDCreAccLNm}`}</strong></Typography>
                  </BoxFR>
                  <object aria-label="pdf document" id="pdf" data={pdfData.length > 0 ? pdfData[0] : null} type="application/pdf" width="100%" height="100%">

                  </object>
                  {/* <embed id="pdf" src="https://dev.emily.bittosen.com/file/shipping/9/142992062-c7SLOjKz.pdf" width="100%" height="100%" /> */}
                </BoxFC>
                {/* <BoxFC alignItems="center" bgcolor="white" p={2} border="1px solid lightgrey" borderRadius={2}>

                  <img src={Logo} alt="Logo" width={200} />
                  <Typography variant='h4'>ยืนยัน Draft ใบขนสินค้า</Typography>
                  <Typography variant='h5' color="primary">INV. {pageData.InvNo}</Typography>
                  <BoxFR>
                    <Button variant="contained"><TaskRoundedIcon sx={{ mr: 1 }} /> ข้อมูลถูกต้อง</Button>
                    <Button variant="contained" color="error"><CancelRounded sx={{ mr: 1 }} />ข้อมูลไม่ถูกต้อง</Button>
                  </BoxFR>
                  <TextField label="สาเหตุใบขนสินค้าไม่ถูกต้อง" fullWidth multiline rows={8} />
                  <BoxFR>
                    <Typography variant="caption">ไฟล์เพิ่มเติม</Typography>  <input type="file" style={{ backgroundColor: "lightgray" }} />
                  </BoxFR>
                  <Divider width={300} sx={{ my: 1, backgroundColor: grey[500] }} />
                  <Link to="/client/Shipping" style={{ color: "blue" }}>
                    ท่านสามารถตรวจสอบสถานะการขนส่งโดยรวมได้ที่นี่
                  </Link>
                </BoxFC> */}
              </BoxFR>
              <Divider width="80%" sx={{ my: 1, backgroundColor: grey[500] }} />
              {
                mShpInvId ?
                  <Button variant="contained" color="secondary" onClick={handleApproveClick} disabled={excelData.EDAprvTm}>
                    ใบขนสินค้าถูกต้อง
                  </Button>
                  :
                  hideEdit ?
                    <BoxFC alignItems="center" sx={{ gap: 0 }} mt={2}>
                      <Typography variant="body"> *** ใบขนสินค้านี้ได้รับการ <strong>{pageData.EDCf?.IsCf ? "ยืนยัน" : "ปฏิเสธ"} </strong> เรียบร้อยแล้ว ***</Typography>
                      <Button variant='contained' sx={{ mt: 1 }} onClick={handleEditClick}>แก้ไขข้อมูล</Button>
                    </BoxFC>
                    :
                    <BoxFC alignItems="center">
                      {(pageData.EDCf?.IsCf !== null && pageData.EDCf?.IsCf !== undefined) &&
                        <Typography variant='caption' sx={{ color: "red" }}>***การยืนยันหรือปฏิเสธข้อมูลอีกครั้งจะทำการแทนที่ข้อมูลเดิม***</Typography>
                      }
                      <BoxFR>
                        <Button variant="contained" onClick={handleConfirm(1)}><TaskRoundedIcon sx={{ mr: 1 }} /> ข้อมูลใบขนสินค้าถูกต้อง</Button>
                        <Button variant="contained" onClick={handleConfirm(0)} color="error"><CancelRounded sx={{ mr: 1 }} />ข้อมูลใบขนสินค้าไม่ถูกต้อง</Button>
                      </BoxFR>
                      <TextField
                        value={rejectMsg}
                        onChange={e => setRejectMsg(e.target.value)}
                        label="สาเหตุใบขนสินค้าไม่ถูกต้อง"
                        sx={{ width: 450, my: 1 }} multiline rows={3} />
                      <BoxFR>
                        <Typography variant="caption">ไฟล์เพิ่มเติม</Typography>
                        <input type="file" style={{ backgroundColor: "lightgray" }} onChange={handleFileChange} />
                      </BoxFR>
                    </BoxFC>
              }
              {
                !mShpInvId &&
                <Link to="/client/Shipping" style={{ color: "blue", margin: "8px 0" }}>
                  ท่านสามารถตรวจสอบสถานะการขนส่งโดยรวมได้ที่นี่
                </Link>
              }

              {/* <BoxFR width="100%" justifyContent="center">
                <BoxFC>
                  <Typography variant="body">ผู้จัดทำใบขนสินค้า: <strong>{`${pageData.EDCreAccFNm} ${pageData.EDCreAccLNm}`}</strong></Typography>

                </BoxFC>

                <TextField label="สาเหตุใบขนสินค้าไม่ถูกต้อง" sx={{ width: 300 }} multiline rows={3} />
                <Typography variant="caption">ไฟล์เพิ่มเติม</Typography>  <input type="file" style={{ backgroundColor: "lightgray" }} />
              </BoxFR>
              <Link to="/client/Shipping" style={{ color: "blue", margin: "8px 0" }}>
                ท่านสามารถตรวจสอบสถานะการขนส่งโดยรวมได้ที่นี่
              </Link> */}
            </BoxFC>
      }
      {
        shpInvId && token && localIsLoading && <Loading />
      }
    </BoxFC>
  )
}

export default PackingListConfirm;

