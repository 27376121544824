import { BoxFR } from "components/BoxCustom";
import React, { useCallback, useContext, useEffect, useState } from "react";
import JobOrderNoDriver from "./JobOrderNoDriver";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { UserContext } from "contexts/UserContext";
import { checkAndAddInArray } from "./function";
import { branchApiNm } from "branch/constant/branchApiNm";
import AddDataDialog from "./AddDataDialog";
import DriverData from "./DriverData";
import { alertError } from "components/Alert";
import dayjs from "dayjs";
import { getDataAndPrintJobOrder } from "utils/getDataAndPrintjobOrder";
import { Box } from "@mui/material";

const initJobData = { hasNo: [], hasTake: [], hasLoc: [], hasBack: [], all: [] }

let selectedRow = null

const JobPlaningV2 = () => {

  const { ax, msData } = useContext(GlobalStateContext)
  const { user } = useContext(UserContext)

  const [jobData, setJobData] = useState({ ...initJobData })
  const [driverData, setDriverData] = useState([])
  const [driverWithBatt, setDirverWithBatt] = useState([])
  const [dialogAddDataOpen, setDialogAddDataOpen] = useState(false)
  const [selectionDrvs, setSelectionDrvs] = useState([])
  const [leftPanelWidth, setLeftPanelWidth] = useState(850)
  const [driverAttendData, setDriverAttendData] = useState([])
  const [truckAttendData, setTruckAttendData] = useState([])

  const setAllData = useCallback((data) => {
    /**
     * use data that Order by AptTm to group data with jobId together so they will order by first aptTm of each job
     */
    const groupdData = data.jobOrderNoDrivers.reduce((prev, cur) => {
      const found = prev.find(item => item.CusId === cur.CusId)
      if (found) {
        found.Data.push(cur)
      } else {
        prev.push({CusId: cur.CusId, Data: [cur]})
      }
      return prev
    }, [])
    // console.log("groupdData::", groupdData)
    const result =[]
    for(const item of groupdData){
      result.push(...item.Data)
    }
    setJobData(checkAndAddInArray(result))
    // setJobData(checkAndAddInArray(data.jobOrderNoDrivers))
    setDriverData(data.jobOrders)
    setDirverWithBatt(data.driverWithBatt)
    setDriverAttendData(data.driverAttend)
    setTruckAttendData(data.truckAttend)
  }, [])

  const getData = useCallback(() => {
    ax.post(branchApiNm.getJobPlaningV2, {}).then(value => {
      if (value.data) {
        setAllData(value.data)
      }
    })
  }, [ax, setAllData])

  const handleSelect = useCallback((row) => {
    // if(selectionDrvs.length === 0){
    //   alertError("กรุณาเลือกพนักงานขับรถก่อน")
    //   return
    // }
    if (row.JobOrdIds.length < selectionDrvs.length) {
      alertError("จำนวนงานมีน้อยกว่าจำนวนพนักงานขับรถที่เลือก")
      return
    }
    selectedRow = row
    setDialogAddDataOpen(true)
  }, [selectionDrvs])

  const confirmSelect = useCallback(({ row, data, contStses, isPrint }) => {
    console.log("in confirmSelect row::", row)
    const postData = {}
    const now = dayjs().format("YYYY-MM-DD HH:mm:ss")

    const jobOrdArr = []
    const contStatusData = []
    const drvs = selectionDrvs.length === 0 ? [data.DrvId] : selectionDrvs


    let i = 0;
    for (const drvId of drvs) {
      const tukId = msData.drivers.find(driver => driver.DrvId === drvId)?.TukId
      for (let j = 0; j < data.NumJobOrd; j++) {
        const jobOrdId = row.JobOrdIds[i]
        jobOrdArr.push({
          JobOrdId: jobOrdId,
          ColValues: {
            DrvId: drvId,
            TukId: tukId,
            DrvActTm: now,
            AptTm: data.AptTm.format("YYYY-MM-DD HH:mm:ss")
          },
          LogRmk: "แก้ไขเวลานัดและผู้รับผิดชอบงาน"
        })
        if (drvs.length === 1) {
          for (const stsId in contStses) {
            if (contStses[stsId].IsChk) {
              contStatusData.push({
                ...contStses[stsId],
                ContStsId: stsId,
                JobOrdId: row.JobOrdIds[i]
              })
            }
          }
        } else {
          const contStsTemplate = { JobOrdId: jobOrdId, DrvId: drvId, TukId: tukId, StsTm: now }
          for (const stsId in contStses) {
            if (contStses[stsId].IsChk) {
              contStatusData.push({ ContStsId: 1, ...contStsTemplate })
            }
          }
        }
        i++
      }
    }

    postData.jobOrdArr = jobOrdArr
    postData.contStatusData = contStatusData
    postData.getArgs = {
      getFn: "getJobPlanningV2",
    }

    ax.post(branchApiNm.updateJobOrderAllDetail, postData).then(value => {
      if (value.data) {
        setAllData(value.data)
        if (isPrint) {
          getDataAndPrintJobOrder(ax, msData, jobOrdArr.map(jo => jo.JobOrdId), user.FName)
        }
      }
    })
  }, [ax, msData, setAllData, selectionDrvs, user.FName])

  const onFinish = useCallback((data) => {
    if (data) {
      setAllData(data)
    } else {
      getData()
    }
  }, [getData, setAllData])

  useEffect(() => {
    getData()
  }, [getData])

  return (
    <Box height="100%" width="100%">
      <BoxFR height="100%" width="100%" sx={{ alignItems: "stretch" }}>
        <JobOrderNoDriver
          jobData={jobData}
          handleSelect={handleSelect}
          selectionDrvs={selectionDrvs}
          leftPanelWidth={leftPanelWidth}
          confirmSelect={confirmSelect} />
        <DriverData
          driverData={driverData}
          driverWithBatt={driverWithBatt}
          setDriverData={setDriverData}
          refreshData={getData}
          selectionDrvs={selectionDrvs}
          setSelectionDrvs={setSelectionDrvs}
          setLeftPanelWidth={setLeftPanelWidth}
          leftPanelWidth={leftPanelWidth}
          onFinish={onFinish}
          driverAttendData={driverAttendData}
          truckAttendData={truckAttendData} />
        <AddDataDialog
          dialogOpen={dialogAddDataOpen}
          setDialogOpen={setDialogAddDataOpen}
          row={selectedRow}
          confirmSelect={confirmSelect}
          selectionDrvs={selectionDrvs} />
      </BoxFR>
    </Box>
  )
}

export default JobPlaningV2;