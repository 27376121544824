import { extraPageMenu, extraPageName } from "branch/pages/ExtraMainLayoutConstant"


const pageName = {
  AddJobOrder: `พิมพ์และเพิ่มใบงาน`,
  AddJobOrderChild: `พิมพ์และเพิ่มใบงาน`,
  DailyJob: `งานประจำวัน`,
  SendEmail: `ส่ง Email`,
  GiveJobOrd: `รับจ่ายใใบงาน`,
  ContainerDoc: `รับใบกำกับ`,
  DailyExpense: `บัญชีเงินสด`,
  ShipmentManagement: `การจัดการใบแจ้งลาก`,
  CustomerManagement: `การจัดการลูกค้า`,
  JobManagement: `การจัดการงาน`,
  DriverManagement: `การจัดการพนักงานขับรถ`,
  TruckManagement: `การจัดการรถ`,
  ContactManagement: `การจัดการผู้ติดต่องาน`,
  AddJobOrderExpense: `ลงรายได้`,
  AddJobOrderExpenseV2: `ลงรายได้`,
  ExpenseManagement: `การจัดการค่าใช้จ่าย`,
  ExpenseJob: `ค่าใช้จ่ายลูกค้า(Invoice)`,
  ExpensePOI: `ค่าใช้จ่ายดำเนินงาน`,
  InvoiceManagement: `การจัดการใบแจ้งหนี้`,
  BillingNoteManagement: `การจัดการใบวางบิล`,
  ReceiptManagement: `การจัดการใบเสร็จ`,
  ReceiveVoucherManagement: `การรับชำระเงินและใบเสร็จ`,
  ExpenseInspection: `ตรวจสอบรายได้`,
  AccountManagement: `การจัดการบัญชีผู้ใช้งาน`,
  AccountClientManagement: `การจัดการบัญชีสำหรับลูกค้า`,
  ServerDataManagement: `การจัดการข้อมูลระบบ`,
  UserSetting: `ตั้งค่าผู้ใช้งาน`,
  BankAccountManagement: `การจัดการสมุดบัญชี`,
  ShoreManagement: `การจัดการค่าชอ`,
  ShoreManagementV2: `การจัดการค่าชอ`,
  ContainerStatusSummary: `สรุปรายงานสถานะตู้`,
  ReceiveVoucherReport: `ข้อมูลการรับชำระเงิน`,
  MonthlyJobReport: `รายงานจำนวนงาน 5ปี`,
  SubcontractReport: `รายงานรถร่วม`,
  MonthlyIncomeReport: `รายงานรายได้ 5ปี`,
  KnowYourCustomer: `รายละเอียดลูกค้า`,
  OverDueReport: `รายงานลูกหนี้คงค้าง`,
  EmilyExpress: `เชื่อมโยงข้อมูล Express`,
  SubcontractJob: `รถร่วม-งาน`,
  SubcontractInvoice: `จ่ายเงินรถร่วม`,
  SubcontractManagement: `การจัดการรถร่วม`,
  ContainerClosingSummary: `สรุปเวลาปิดรับตู้ส่งออก`,
  DriverStatus: `สถานะพนักงานขับรถ`,
  PunctualReport: `รายงานตรงต่อเวลา`,
  DriverJobReport: `รายงานพนักงานขับรถ-งาน`,
  TagManagement: `การจัดการ Tag`,
  DriverPerformance: `รายงานประสิทธิภาพ พขร.`,
  CreditNoteManagement: `การจัดการใบลดหนี้`,
  LogManagement: `ประวัติข้อมูล`,
  JobOrderImageManagement: `รูปประกอบใบงาน`,
  BookingManagement: `การจองรถ`,
  FuelManagement: `การเติมเชื้อเพลิง`,
  POIManagement: `การจัดการสถานที่`,
  Map: `แผนที่`,
  FuelReport: `รายงานการเติมเชื้อเพลิง`,
  CheckupManagement: `การจัดการตรวจสภาพรถ`,
  RepairOrderManagement: `การจัดการแจ้งซ่อม`,
  DriverAttend: `เช็คชื่อ ประจำวัน`,
  TruckAttend: `เช็ครถ ประจำวัน`,
  CheckupRecord: `บันทึกการตรวจสภาพรถ`,
  NotificationCenter: `ศูนย์รวมการแจ้งเตือน`,
  PettyCash: `เงินสดย่อย`,
  PettyCashBalance: `สรุปกรายการเงินสดย่อย`,
  TrailerManagement: `การจัดการหางพ่วง`,
}
if (extraPageName) {
  for (const name in extraPageName) {
    pageName[name] = extraPageName[name]
  }
}

export const navMenuItems = [
  { page: "HEADER", num: "1.", menuName: "งานประจำวัน" },
  { page: "ShipmentManagement", num: "1.1", menuName: pageName.ShipmentManagement },
  { page: "AddJobOrder", num: "1.2", menuName: pageName.AddJobOrder },
  { page: "DailyJob", num: "1.3", menuName: pageName.DailyJob },
  { page: "SendEmail", num: "1.4", menuName: pageName.SendEmail },
  { page: "ContainerDoc", num: "1.5", menuName: pageName.ContainerDoc },
  { page: "DailyExpense", num: "1.6", menuName: pageName.DailyExpense },
  { page: "-", num: "", menuName: `` },
  { page: "HEADER", num: "2.", menuName: "ข้อมูลงาน" },
  { page: "CustomerManagement", num: "2.1", menuName: pageName.CustomerManagement },
  { page: "JobManagement", num: "2.2", menuName: pageName.JobManagement },
  { page: "DriverManagement", num: "2.3", menuName: pageName.DriverManagement },
  { page: "TruckManagement", num: "2.4", menuName: pageName.TruckManagement },
  { page: "-", num: "", menuName: `` },
  { page: "HEADER", num: "3.", menuName: "บัญชีและการเงิน" },
  { page: "AddJobOrderExpenseV2", num: "3.1", menuName: pageName.AddJobOrderExpenseV2 },
  { page: "InvoiceManagement", num: "3.2", menuName: pageName.InvoiceManagement },
  { page: "BillingNoteManagement", num: "3.3", menuName: pageName.BillingNoteManagement },
  { page: "ReceiveVoucherManagement", num: "3.4", menuName: pageName.ReceiveVoucherManagement },
  { page: "ReceiptManagement", num: "3.5", menuName: pageName.ReceiptManagement },
  { page: "ExpenseInspection", num: "3.6", menuName: pageName.ExpenseInspection },
  { page: "-", num: "", menuName: `` },
  { page: "HEADER", num: "4.", menuName: "ข้อมูลการเงิน" },
  { page: "ExpenseManagement", num: "4.1", menuName: pageName.ExpenseManagement },
  { page: "ExpenseJob", num: "4.2", menuName: pageName.ExpenseJob }
]
const navMenuItemsHeader = [
  {
    num: 1, menuName: "งานประจำวัน", permission: "OPR", children: [
      { page: "BookingManagement", num: "1.0", menuName: pageName.BookingManagement, prNum: 1, badgeName:"booking" },
      { page: "ShipmentManagement", num: "1.1", menuName: pageName.ShipmentManagement, prNum: 1 },
      { page: "AddJobOrder", num: "1.2", menuName: pageName.AddJobOrder, prNum: 1 },
      { page: "DailyJob", num: "1.3", menuName: pageName.DailyJob, prNum: 1 },
      { page: "JobOrderImageManagement", num: "1.4", menuName: pageName.JobOrderImageManagement, prNum: 1, badgeName:"jobOrderImage" },
      { page: "SendEmail", num: "1.5", menuName: pageName.SendEmail, prNum: 1 },
      { page: "ContainerDoc", num: "1.6", menuName: pageName.ContainerDoc, prNum: 1 },
      { page: "PettyCash", num: "1.7", menuName: pageName.PettyCash, prNum: 1 },
      // { page: "DailyExpense", num: "1.7.0", menuName: pageName.DailyExpense, prNum: 1 },
      { page: "PettyCashBalance", num: "1.7.1", menuName: pageName.PettyCashBalance, prNum: 1 },
      // { page: "ShoreManagement", num: "1.8", menuName: pageName.ShoreManagement, prNum: 1 },
      { page: "ShoreManagementV2", num: "1.8", menuName: pageName.ShoreManagementV2, prNum: 1 },
      { page: "ContainerStatusSummary", num: "1.9", menuName: pageName.ContainerStatusSummary, prNum: 1 },
      { page: "ContainerClosingSummary", num: "1.10", menuName: pageName.ContainerClosingSummary, prNum: 1 },
      { page: "DriverStatus", num: "1.11", menuName: pageName.DriverStatus, prNum: 1 },
      { page: "FuelManagement", num: "1.12", menuName: pageName.FuelManagement, prNum: 1, badgeName:"fuel" },
      { page: "Map", num: "1.13", menuName: pageName.Map, prNum: 1 },
      { page: "DriverAttend", num: "1.14", menuName: pageName.DriverAttend, prNum: 1 },
      { page: "TruckAttend", num: "1.15", menuName: pageName.TruckAttend, prNum: 1 },
      { page: "NotificationCenter", num: "1.16", menuName: pageName.NotificationCenter, prNum: 1 },
      { page: "LogManagement", num: "1.17", menuName: pageName.LogManagement, prNum: 1 },
      { page: "AddJobOrderChild", num: "", hide: true, menuName: pageName.AddJobOrderChild, prNum: 1 },
    ]
  },
  {
    num: 2, menuName: "ข้อมูลงาน", permission: "OPR", children: [
      { page: "CustomerManagement", num: "2.1", menuName: pageName.CustomerManagement, prNum: 2 },
      { page: "JobManagement", num: "2.2", menuName: pageName.JobManagement, prNum: 2 },
      { page: "DriverManagement", num: "2.3", menuName: pageName.DriverManagement, prNum: 2 },
      { page: "TruckManagement", num: "2.4", menuName: pageName.TruckManagement, prNum: 2 },
      { page: "SubcontractManagement", num: "2.5", menuName: pageName.SubcontractManagement, prNum: 2 },
      { page: "TagManagement", num: "2.6", menuName: pageName.TagManagement, prNum: 2 },
      { page: "POIManagement", num: "2.7", menuName: pageName.POIManagement, prNum: 2 },
      { page: "TrailerManagement", num: "2.8", menuName: pageName.TrailerManagement, prNum: 2 },
    ]
  },
  {
    num: 3, menuName: "บัญชีและการเงิน", permission: "FIN", children: [
      { page: "AddJobOrderExpenseV2", num: "3.1", menuName: pageName.AddJobOrderExpenseV2, prNum: 3 },
      { page: "InvoiceManagement", num: "3.2", menuName: pageName.InvoiceManagement, prNum: 3 },
      { page: "CreditNoteManagement", num: "3.3", menuName: pageName.CreditNoteManagement, prNum: 3 },
      { page: "BillingNoteManagement", num: "3.4", menuName: pageName.BillingNoteManagement, prNum: 3 },
      { page: "ReceiveVoucherManagement", num: "3.5", menuName: pageName.ReceiveVoucherManagement, prNum: 3 },
      { page: "ExpenseInspection", num: "3.6", menuName: pageName.ExpenseInspection, prNum: 3 },
      { page: "ReceiveVoucherReport", num: "3.7", menuName: pageName.ReceiveVoucherReport, prNum: 3 },
      { page: "SubcontractInvoice", num: "3.8", menuName: pageName.SubcontractInvoice, prNum: 3 },
    ]
  },
  {
    num: 4, menuName: "ข้อมูลการเงิน", permission: "FIN", children: [
      { page: "ExpenseManagement", num: "4.1", menuName: pageName.ExpenseManagement, prNum: 4 },
      { page: "ExpenseJob", num: "4.2", menuName: pageName.ExpenseJob, prNum: 4 },
      { page: "ExpensePOI", num: "4.3", menuName: pageName.ExpensePOI, prNum: 4 },
      { page: "BankAccountManagement", num: "4.4", menuName: pageName.BankAccountManagement, prNum: 4 },
      { page: "EmilyExpress", num: "4.5", menuName: pageName.EmilyExpress, prNum: 4 },
      { page: "SubcontractJob", num: "4.6", menuName: pageName.SubcontractJob, prNum: 4 },
    ]
  },
  {
    num: 5, menuName: "รายงาน", permission: "ADM;FIN;OPR;EXE", children: [
      { page: "PunctualReport", num: "5.1", menuName: pageName.PunctualReport, prNum: 5, permission: "FIN;OPR" },
      { page: "DriverJobReport", num: "5.2", menuName: pageName.DriverJobReport, prNum: 5, permission: "FIN;OPR" },
      { page: "MonthlyJobReport", num: "5.3", menuName: pageName.MonthlyJobReport, prNum: 5, permission: "FIN;OPR" },
      { page: "SubcontractReport", num: "5.4", menuName: pageName.SubcontractReport, prNum: 5, permission: "FIN;OPR" },
      { page: "OverDueReport", num: "5.5", menuName: pageName.OverDueReport, prNum: 5, permission: "FIN" },
      { page: "MonthlyIncomeReport", num: "5.6", menuName: pageName.MonthlyIncomeReport, prNum: 5, permission: "EXE" },
      { page: "KnowYourCustomer", num: "5.7", menuName: pageName.KnowYourCustomer, prNum: 5, permission: "EXE" },
      { page: "DriverPerformance", num: "5.8", menuName: pageName.DriverPerformance, prNum: 5 },
      { page: "FuelReport", num: "5.9", menuName: pageName.FuelReport, prNum: 5 },
    ]
  },
  {
    num: 6, menuName: "Admin", permission: "ADM", children: [
      { page: "AccountManagement", num: "6.1", menuName: pageName.AccountManagement, prNum: 6 },
      { page: "AccountClientManagement", num: "6.2", menuName: pageName.AccountClientManagement, prNum: 6 },
      { page: "ServerDataManagement", num: "6.3", menuName: pageName.ServerDataManagement, prNum: 6 },
    ]
  },
  {
    num: 7, menuName: "ซ่อมบำรุง", permission: "MNT", children: [
      { page: "CheckupManagement", num: "7.1", menuName: pageName.CheckupManagement, prNum: 7 },
      { page: "CheckupRecord", num: "7.2", menuName: pageName.CheckupRecord, prNum: 7 },
      { page: "RepairOrderManagement", num: "7.3", menuName: pageName.RepairOrderManagement, prNum: 7 },
    ]
  },
  {
    num: 8, menuName: "เพิ่มเติม", permission: "OPR;FIN", children: []
  },
]
if (extraPageMenu) {
  for (const menu of extraPageMenu) {
    navMenuItemsHeader[7].children.push(menu)
  }
}


export { pageName, navMenuItemsHeader }
