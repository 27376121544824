import React, { useCallback, useContext, useMemo, useState } from "react";
import { Box, Dialog, DialogContent, FormControl, FormControlLabel, FormLabel, InputAdornment, MenuItem, Radio, RadioGroup, TextField } from '@mui/material'
import ModAccName from 'components/ModAccName';
import { DialogFooter, DialogHeader } from 'components/dialog/DialogHeader';
import { initDialogData } from "./initData";
import { validate, generateMenuComboItem, selectOnFocus } from "utils";
import PaperComponent from "components/PaperComponent";
import { useEffect } from "react";
import { BoxFR } from "components/BoxCustom";
import ComboBox from "components/ComboBox";
import { shipmentColNm } from "constants/shipmentConstant";
import { expensePOIApiNm, expensePOIColNm } from "./constant";
// import useFinanceMsData from "hooks/useFinanceMsData";
import CheckboxFormControl from "components/CheckboxFormControl";
import { isNumeric } from "utils";
import { alertConfirmDelete, alertError } from "components/Alert";
import { GlobalStateContext } from "contexts/GlobalStateContext";


const ExpensePOIDialog = ({ dialogOpen, selectedId, lastFilter, setDialogOpen, onFinish, initData }) => {
  const { ax, msData } = useContext(GlobalStateContext);
  // const { ax, msData } = useFinanceMsData();

  const [dialogData, setDialogData] = useState(initDialogData)
  const [jobCombo, setJobCombo] = useState(msData.jobCombo);
  const [isCloseDialogAfterSave, setIsCloseDialogAfterSave] = useState(true);

  const validation = useMemo(() => ({
    ExpId: dialogData.ExpId === null,
    UntPr: dialogData.UntPr === '',
    Prty: dialogData.Prty === '',
  }), [dialogData]);

  const comboProp = useCallback((name, label, options, isRequire) => ({
    options: options,
    label: label,
    required: isRequire,
    sx: { width: "100%" },
    selectedId: dialogData[name],
    setSelectedId: (id) => setDialogData(oldState => {
      if (name === "CusId") {
        setJobCombo(msData.jobCombo.filter(item => item.refId === id))
        return { ...oldState, [name]: id }
      } else if (name === "ExpId") {
        console.log("id::", id)
        const found = msData.expenses.find(exp => exp.ExpId === id)
        console.log("found::", found)
        if (found) {
          return {
            ...oldState,
            ExpId: id,
            VatPrct: found.VatPrct,
            WhtPrct: found.WhtPrct,
            UntPr: found.UntPr,
            IsOwnRcpt: found.IsOwnRcpt,
            IsOprHide: found.IsOprHide,
          }
        } else {
          return { ...oldState, ExpId: id }
        }
      } else {
        return { ...oldState, [name]: id }
      }
    })
  }), [dialogData, msData, setDialogData])

  const dialogDataProp = useCallback((name) => ({
    variant: 'outlined',
    size: 'small',
    name: name,
    label: expensePOIColNm[name],
    value: dialogData[name],
    error: validation[name],
    onChange: (e) => { setDialogData((oldState) => ({ ...oldState, [name]: e.target.value })) }
  }), [dialogData, validation])



  const insertUpdateExpensePOI = useCallback((dialogData, isCloseDialogAfterSave) => {
    if (!isNumeric(dialogData.WhtPrct)) {
      alertError("หัก ณ ที่จ่ายเป็นตัวเลขเท่านั้น")
      return;
    }
    if (!isNumeric(dialogData.UntPr)) {
      alertError("ราคาเป็นตัวเลขเท่านั้น")
      return;
    }

    let result;

    const postData = {
      ...dialogData,
      getArgs: lastFilter
    }
    if (dialogData.ExpPOIId > 0) {
      result = ax.post(expensePOIApiNm.updateExpensePOI, postData);
    } else {
      result = ax.post(expensePOIApiNm.insertExpensePOI, postData);
    }

    result.then(value => {
      if (value.data) {
        if(onFinish){
          onFinish(value.data)
        }
        if (isCloseDialogAfterSave) {
          setDialogOpen(false);
        }
      }
    })
  }, [ax, lastFilter, onFinish, setDialogOpen])

  const deleteExpensePOI = useCallback((id) => {
    alertConfirmDelete(() => {
      ax.post(expensePOIApiNm.deleteExpensePOI, { ExpPOIId: id, getArgs: lastFilter }).then(value => {
        if (value.data) {
          if(onFinish){
            onFinish(value.data)
          }
          setDialogOpen(false);
        }
      })
    })
  }, [ax, lastFilter, onFinish, setDialogOpen])

  useEffect(() => {
    if (dialogOpen) {
      if (selectedId) {
        ax.post(expensePOIApiNm.getExpensePOI, { ExpPOIId: selectedId }).then(value => {
          if (value.data) {
            setDialogData(value.data[0])
          }
        })
      }else if(initData){
        setDialogData({...initDialogData, ...initData})
      }
    } else {
      setDialogData({ ...initDialogData })
      setIsCloseDialogAfterSave(true)
    }
  }, [ax, selectedId, dialogOpen, msData.jobCombo, initData])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth={"sm"} >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={dialogData.ExpPOId  ? "แก้ไขข้อมูล" : "เพิ่มข้อมูล"}
        onDeleteClick={dialogData.ExpPOIId  ? () => deleteExpensePOI(dialogData.ExpPOIId) : null}
      />
      <DialogContent>
        <BoxFR mt={2} sx={{ alignItems: "stretch", justifyContent: "center" }} width="100%">
          <Box display='flex' flexDirection='column' gap={1} key={dialogData.ExpId} >
            <BoxFR>
              <TextField {...dialogDataProp('IsCost')} select fullWidth label="ต้นทุน-รายได้(เก็บเงินลูกค้าได้)" >
                <MenuItem value={1}>ต้นทุน</MenuItem>
                <MenuItem value={0}>รายได้</MenuItem>
              </TextField>
              
              <ComboBox {...comboProp("ExpId", expensePOIColNm.ExpNm, msData.expOprCombo, true)}
                disabled={dialogData.ExpPOId?true:false} />
            </BoxFR>
            <BoxFR>
              <ComboBox {...comboProp("POIId", "สถานที่", msData.poiCombo, false)} />
              <TextField select {...dialogDataProp("ShpmTypId")} fullWidth
                value={dialogData.ShpmTypId || ""}
                onChange={e => setDialogData(o => ({ ...o, ShpmTypId: e.target.value || null }))}>
                <MenuItem value="">ไม่ระบุ</MenuItem>
                {generateMenuComboItem(msData.ShpmTypCombo)}
              </TextField>
            </BoxFR>
            <BoxFR>
              <ComboBox {...comboProp("CusId", shipmentColNm.CusSNm, msData.cusCombo, false)} />
              <ComboBox {...comboProp("JobId", shipmentColNm.JobSNm, jobCombo, false)} />
            </BoxFR>
            <BoxFR>
              <TextField select {...dialogDataProp("ContSize")} fullWidth >
                <MenuItem value="">ไม่ระบุ</MenuItem>
                {generateMenuComboItem(msData.contSizeCombo)}
              </TextField>
              <TextField select {...dialogDataProp("ContTyp")} fullWidth >
                <MenuItem value="">ไม่ระบุ</MenuItem>
                {generateMenuComboItem(msData.contTypCombo)}
              </TextField>
            </BoxFR>
            <BoxFR>
              <ComboBox {...comboProp("TlrTypId", "ประเภทหาง", msData.tlrTypCombo, false)} />
              <TextField {...dialogDataProp('UntPr')} fullWidth type="number" onFocus={selectOnFocus} required
                onChange={(e) => { setDialogData((oldState) => ({ ...oldState, UntPr: e.target.value })) }}
                inputProps={{ style: { textAlign: "right" } }}
                InputProps={{ endAdornment: <InputAdornment position="end">บาท</InputAdornment> }} />
            </BoxFR>

            <BoxFR>
              <TextField {...dialogDataProp('WhtPrct')} type="number" onFocus={selectOnFocus}
                inputProps={{ style: { textAlign: "right" } }} fullWidth
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }} />

              <TextField {...dialogDataProp('VatPrct')} type="number" onFocus={selectOnFocus}
                inputProps={{ style: { textAlign: "right" } }} fullWidth
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }} />
            </BoxFR>
            <BoxFR>

              <Box width="100%" />
            </BoxFR>
            {dialogData.IsCost === 0 &&
              <FormControl component="fieldset">
                <FormLabel component="legend">{expensePOIColNm.IsOwnRcpt}</FormLabel>
                <RadioGroup row name='IsOwnRcpt' value={dialogData.IsOwnRcpt}
                  onChange={(e) => { setDialogData((oldState) => ({ ...oldState, IsOwnRcpt: e.target.value })) }}>
                  <FormControlLabel value={1} control={<Radio />} label="ใบเสร็จบริษัท" />
                  <FormControlLabel value={0} control={<Radio />} label="ใบเสร็จลูกค้า" />
                </RadioGroup>
              </FormControl>
            }
            <BoxFR>
              <TextField {...dialogDataProp('Prty')} required fullWidth onFocus={selectOnFocus} />
              <Box width="100%" />
            </BoxFR>
            <TextField {...dialogDataProp('Rmk')} multiline rows={3} />
            {!dialogData.ExpPOIId ?
              <CheckboxFormControl label="ปิดหน้าต่างหลังจากเพิ่มข้อมูล"
                checked={isCloseDialogAfterSave}
                onChange={e => setIsCloseDialogAfterSave(e.target.checked)} /> : null
            }

            {
              dialogData.ExpPOIId > 0 && <ModAccName modDte={dialogData.ModDte} modAcc={dialogData.ModAcc} />
            }
          </Box>
        </BoxFR>

      </DialogContent>
      <DialogFooter okText={selectedId > 0 ? "แก้ไขข้อมูล" : "เพิ่มข้อมูล"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={() => validate(validation) && insertUpdateExpensePOI(dialogData, isCloseDialogAfterSave)} />
    </Dialog>
  )
}

export default React.memo(ExpensePOIDialog)