

import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import NotFound from 'pages/NotFound';
import MainLayout from 'components/MainLayout';
import ClientLayout from 'components/ClientLayout/ClientLayout';
import Login from 'pages/Login';
import UserContextProvider from 'contexts/UserContext';
import GlobalStateContextProvider from 'contexts/GlobalStateContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs as DateAdapter } from '@mui/x-date-pickers/AdapterDayjs';

import "./App.css"
import { LicenseInfo } from '@mui/x-data-grid-pro';

import 'dayjs/locale/th'
import dayjs from "dayjs";
import FullScreenImage from 'pages/FullScreenImage/FullScreenImage';
import { extraMainRoute } from 'branch/pages/ExtraMainLayoutConstant';

dayjs.locale('th') // use locale globally

LicenseInfo.setLicenseKey(
  '86ad9556a21ad110b72e0821a1ecfcdaT1JERVI6NDA3NjQsRVhQSVJZPTE2ODAyNTgyMzMwMDAsS0VZVkVSU0lPTj0x',
);


function App() {

  return (
    <>
      <CssBaseline />
      <UserContextProvider>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <GlobalStateContextProvider >
            <Router>
              <Switch>
                <Route exact path="/login/:url"><Login /></Route>
                <Route exact path="/"><Login /></Route>
                <Route path="/emily"><MainLayout /></Route>
                <Route path="/client"><ClientLayout /></Route>
                <Route path="/image/:imgUrl"><FullScreenImage /></Route>
                {/* <Route path="/mobile/print-job-order/:accId/:token"><MobilePrintJobOrder /></Route>" */}
                <Route path="/404" ><NotFound /> </Route>
                {[...extraMainRoute]}
                <Route path="*"><Redirect to="/404" /></Route>
              </Switch>
            </Router>
          </GlobalStateContextProvider>
        </LocalizationProvider>
      </UserContextProvider>
    </>
  );
}

export default App;
