import { Route } from "react-router-dom/cjs/react-router-dom"
import ShippingProcess from "../ShippingProcess/ShippingProcess"

export const extraMenuData = [
  { path: "/client/Shipping", label: "Shipping" },
]

export const extraRoute = [
  
  <Route key={"shipping"} path={`/client/Shipping`} ><ShippingProcess /> </Route>,
]