import { Button, Dialog, DialogContent } from "@mui/material"
import { BoxFC, BoxFR } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { shippingApiNm } from "./shippingApiNm"
import { alertError } from "components/Alert"
import FileDropPanel from "components/FileUplaod/FileDropPanel"

const AddFileWithSendDialog = ({ shpmFileTypId, title, colNm, selectedId, dialogOpen, setDialogOpen, lastFilter, onFinish }) => {

  const { ax } = useContext(GlobalStateContext)
  const [selectedFiles, setSelectedFiles] = useState([])


  const saveFile = useCallback(async () => {
    if (selectedFiles.length === 0) {
      alertError("กรุณาเลือกไฟล์ก่อน")
      return
    }

    if(["EDCreTm", "ED0209CreTm", "ED0309CreTm", "ED0409CreTm"].includes(colNm)){
      await ax.post(shippingApiNm.deleteFileByType, { ShpInvId: selectedId, ShpFileTypId: 2 })
    }
    
    const postData = {
      ShpInvId: selectedId,
      ColNm: colNm
    }
    await ax.post(shippingApiNm.updateShippingInvoiceTime, postData)

    for (const file of selectedFiles) {
      const data = new FormData();
      data.append("file", file);
      data.append("ShpFileTypId", shpmFileTypId);
      data.append("ShpInvId", selectedId);
      data.append("Label", file.label);
      await ax.post(shippingApiNm.uploadFile, data)
    }
  }, [ax, selectedFiles, selectedId, shpmFileTypId, colNm])
  const handleDialogSave = useCallback(async () => {

    await saveFile()

    setDialogOpen(false)
    onFinish()
  }, [onFinish, setDialogOpen, saveFile])

  const handleSaveAndSendEmail = useCallback(async () => {
    await saveFile()

    let sndColNm = ""
    if (colNm === "EDCreTm") {
      sndColNm = "EDSndTm"
    } else if (colNm === "ED0309CreTm") {
      sndColNm = "ED0309SndTm"
    } else if (colNm === "ED0409CreTm") {
      sndColNm = "ED0409SndTm"
    } else if (colNm === "CDCreTm") {
      sndColNm = "CDSndTm"
    }
    const postData = {
      ShpInvId: selectedId,
      ColNm: sndColNm,
      getArgs: lastFilter
    }
    ax.post(shippingApiNm.sendEmail, postData).then(value => {
      if (value.data) {
        setDialogOpen(false)
        onFinish(value.data)
      }
    })

  }, [saveFile, ax, colNm, selectedId, setDialogOpen, onFinish, lastFilter])
  useEffect(() => {
    if (dialogOpen) {
      setSelectedFiles([])
    }
  }, [dialogOpen, ax, selectedId])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xs' >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={title}
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC mt={2} >
          <BoxFR sx={{ alignItems: "stretch" }} justifyContent="space-around" >
            <FileDropPanel width="100%" height={200} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} />

          </BoxFR>

        </BoxFC>
      </DialogContent>
      <DialogFooter okText={"บันทึก"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleDialogSave} >
        {colNm !== "PayCstmTm" && <Button variant="contained" color="secondary" onClick={handleSaveAndSendEmail}>บันทึกและส่ง Email</Button>}
      </DialogFooter>
    </Dialog>
  )
}

export default AddFileWithSendDialog