export const packingListColNm = {
  IP_Shpr: "Shipper/Exporter",
  IP_Csgn: "Consignee/Importer",
  IP_NofyPrty: "Notify Party",
  IP_PoL: "Port of Loading",
  IP_CoL: "Country of Loading",
  IP_Vssl: "Vessel/Voyage",
  IP_ETD: "Sailing on about",
  IP_InvNo: "Invoice No.",
  IP_InvDte: "Date of Invoice",  
  IP_PayTrm: "Payment Terms",
  IP_NmPlc: "Name Place",
  IP_CoO: "Country of Origin",
  IP_CoC: "Country of Consignee",
  IP_Rmk: "Remark",
  IP_FinlDst: "Final Destination",
  IP_CoD: "Country of Destination",
  IP_BkgNo: "Mode",
  IP_ShpmTrm: "Shipment Terms",
  IP_Mrk: "Marks & Nos.",
  IP_Dscp: "Description of Goods",
  IP_QtyCT: "Qty(C/T)",
  IP_QtyPCS: "Qty(PCS)",
  IP_Unit: "Unit",
  IP_Price: "Price",
  IP_Amnt: "Amount",
  IP_NW: "N/W(KGS)",
  IP_GW: "G/W(KGS)",
  IP_M3: "Measurement(CBM)",
  IP_QtyM: "Qty(M)",
  IP_PO: "PO No.",
  IP_PoD: "Place of Delivery",
}