import isFileOverSize from "./isFileOverSize"

export const handleFileDrop = (e, setFile) => {
  e.preventDefault()
  let files = []
  if (e.dataTransfer.items) {
    if (e.dataTransfer.items.length > 0 && e.dataTransfer.items[0].kind === 'file') {
      files = Array.from(e.dataTransfer.items).map(item => item.getAsFile())
    } else {
      return
    }
  } else {
    if (e.dataTransfer.files.length > 0) {
      files = Array.from(e.dataTransfer.files)
    } else {
      return
    }
  }

  for (const file of files) {
    if (isFileOverSize(file)) return
    
    // const fileNameOnly = file.name.substring(0, file.name.lastIndexOf('.')) || file.name
    file.label = file.name
    file.imgLink= URL.createObjectURL(file)
  }
  setFile(files)
}