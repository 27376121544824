

export const addInArray = (arr, data) => {
  const found = arr.find(item => data.ShpmId === item.ShpmId
    && data.TakePOIId === item.TakePOIId
    && data.RtnPOIId === item.RtnPOIId
    && data.LocPOIId === item.LocPOIId
    && data.ContSizeFull === item.ContSizeFull
    && data.AptTm === item.AptTm)
  if (found) {
    found.JobOrdIds.push(data.JobOrdId)
  } else {
    data.No = arr.length + 1
    data.id = data.JobOrdId
    data.JobOrdIds = [data.JobOrdId]
    arr.push(data)
  }
}

export const checkAndAddInArray = (joData) => {
  const result = {
    hasNo: [],
    hasTake: [],
    hasLoc: [],
    hasBack: [],
    all:[],
  }
  for (const data of joData) {
    
    if(!data.ContSts1 && !data.ContSts3 && !data.ContSts4 && !data.ContSts5){
      addInArray(result.hasNo, data)
      result.all.push(data)
    }else if(!data.ContSts3 && !data.ContSts4 && !data.ContSts5){
      result.hasTake.push({...data,id: data.JobOrdId, JobOrdIds: [data.JobOrdId]})
      result.all.push(data)
    } else if(!data.ContSts4 && !data.ContSts5 && ![3,4].includes(data.ShpmTypId)){
      result.hasLoc.push({...data,id: data.JobOrdId, JobOrdIds: [data.JobOrdId]})
      result.all.push(data)
    } else if(!data.ContSts5 && ![3,4].includes(data.ShpmTypId)){
      result.hasBack.push({...data,id: data.JobOrdId, JobOrdIds: [data.JobOrdId]})
      result.all.push(data)
    }
    // if(!data.AptTm){
    //   addInArray(result.nd, data)
    // } else if(dayjs(data.AptTm).isBefore(dayjs().startOf("day"))){
    //   addInArray(result.yd, data)
    // } else if(dayjs(data.AptTm).startOf("day").isSame(dayjs().startOf("day"))){
    //   addInArray(result.td, data)
    // } else if(dayjs(data.AptTm).startOf("day").isSame(dayjs().add(1, "day").startOf("day"))){
    //   addInArray(result.tm, data)
    // } else {
    //   addInArray(result.atm, data)
    // }
  }
  return result
}