import { Box, Button, Dialog, DialogContent, Divider, MenuItem, Paper, TextField, Typography } from "@mui/material"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { shippingApiNm } from "./shippingApiNm"
import { shippingInvoiceColNm } from "./constant"
import { BoxFC, BoxFR } from "components/BoxCustom"
import { initApproveCDData } from "./initData"
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand"
import { approveCDColumns } from "./columns"
import { addSumRow, getSumColorClassName, sxSumColorClass } from "utils/highlightSumColor"
import { addIdForDataGrid, numberFormat, numberFormatNoFlotingPoint, toNumber } from "utils"
import { blueGrey } from "@mui/material/colors";

const ApproveCDDialog = ({ selectedId, dialogOpen, setDialogOpen, onFinish, lastFilter }) => {

  const { ax, setIsLoading } = useContext(GlobalStateContext)

  const [dialogData, setDialogData] = useState({ ...initApproveCDData })
  const [dataTable, setDataTable] = useState([])
  const [selectedCDFile, setSelectedCDFile] = useState("")
  const [selectionModel, setSelectionModel] = useState([])

  const textFieldProps = useCallback((name) => ({
    size: "small",
    variant: "outlined",
    label: shippingInvoiceColNm[name],
    value: dialogData[name],
    sx: { flex: 1 },
  }), [dialogData])

  const modDataTable = useMemo(() => {
    if (dataTable.length > 0 && dataTable[dataTable.length - 1].id === -1) {
      return dataTable
    }
    // return dataTable
    return addSumRow({
      dataTable: dataTable,
      sumColNms: ["GrossWt", "PSC", "CNT", "CBM"],
      sumColTotal: "ContNo"
    })
  }, [dataTable])

  const addRowDataToDialogData = useCallback((data, rowData) => {
    return {
      ...data,
      ContNo: rowData.ContNo,
      ISOCd: rowData.ISOCd,
      CT: `${numberFormatNoFlotingPoint(toNumber( rowData.CNT))} CT`,
      KGM: `${numberFormat(toNumber( rowData.GW))} KGM`,
      TotalKGM: `${numberFormat(toNumber(rowData.GW) + toNumber(rowData.TareWt))} KGM`,
    }
  }, [])

  const handleCDFileChange = useCallback((e) => {
    setSelectedCDFile(e.target.value)
    const label = dialogData.CDFiles.find(file => file.FileNm === e.target.value)?.Label || ""

    for (const row of dataTable) {
      console.log("row.ContNo::", row.ContNo)
      console.log("label::", label)
      if (label.indexOf(row.ContNo) >= 0) {
        setDialogData(addRowDataToDialogData(dialogData, row))
        setSelectionModel([row.id])
        return
      }
    }
  }, [addRowDataToDialogData, dialogData, dataTable])

  const handleSelectionModelChange = useCallback((ids) => {
    setSelectionModel(ids)
    const rowData = dataTable.find(row => row.id === ids[0])
    setDialogData(addRowDataToDialogData(dialogData, rowData))
    if (dialogData.CDFiles && dialogData.CDFiles.length > 0) {
      setSelectedCDFile(dialogData.CDFiles.find(file => file.Label.indexOf(rowData.ContNo) >= 0)?.FileNm || "")
    }
  }, [dialogData, dataTable, addRowDataToDialogData])

  const handleConfirm = useCallback(() => {
    const postData = {
      ShpInvId: selectedId,
      ColNm: "CDAprvTm",
      getArgs: lastFilter
    }
    ax.post(shippingApiNm.updateShippingInvoiceTime, postData).then(value => {
      if (value.data) {
        if (onFinish) {
          onFinish(value.data)
        }
        setDialogOpen(false)
      }
    })
  }, [onFinish, ax, selectedId, lastFilter, setDialogOpen])
  useEffect(() => {
    if (dialogOpen) {
      ax.post(shippingApiNm.getApproveCDData, { ShpInvId: selectedId }).then(async value => {
        if (value.data && value.data.length > 0) {
          const data = value.data[0]
          data.Conts = data.Conts ? JSON.parse(data.Conts) : []

          let rowData = []
          setIsLoading(true)
          if (data.MTFiles && data.MTFiles.length > 0) {
            rowData = addIdForDataGrid(data.Conts, "JobOrdId")
          } else if(data.Conts.length > 0) {
            rowData = [{
              id: 1,
              No: 1,
              ContNo: data.Conts[0].ContNo,
              ISOCd: data.Conts[0].ISOCd,
              TareWt: data.Conts[0].TareWt,
              GW: data.GW,
              PSC: data.PSC,
              CNT: data.CNT,
              CBM: data.CBM,
            }]
          }
          setIsLoading(false)
          setDataTable(rowData)
          if (rowData.length > 0) {
            data.ContNo = rowData[0].ContNo

            setDialogData(addRowDataToDialogData(data, rowData[0]))
            setSelectionModel([rowData[0].id])

            if (data.CDFiles && data.CDFiles.length > 0) {
              setSelectedCDFile(data.CDFiles.find(file => file.Label.indexOf(rowData[0].ContNo) >= 0)?.FileNm || "")
            }
          }
        }


      })
    } else {
      setDialogData({ ...initApproveCDData })
      setDataTable([])
      setSelectedCDFile("")
      setSelectionModel([])
    }
  }, [dialogOpen, ax, selectedId, addRowDataToDialogData, setIsLoading])

  console.log("ApproveCD dialogData::", dialogData)
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth={false} fullWidth
      PaperProps={{
        style: {
          height: "95%"
        }
      }}>
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={"ตรวจสอบ ใบกำกับตู้"}
        onDeleteClick={null}
      />
      <DialogContent height="100%" sx={{ bgcolor: blueGrey[50] }}>
        <BoxFC py={2} width="100%" height="100%" pb={2}>
          <BoxFR width="100%" flex={1}>
            <Paper sx={{ p: 2, flex: 1, height: "100%" }}>
              <BoxFC height="100%" overflow="auto">
                <Typography variant="h6">ข้อมูลในระบบ</Typography>
                <BoxFR>
                  <Box flex={1} /><TextField {...textFieldProps("ContNo")} /><TextField {...textFieldProps("ISOCd")} />
                </BoxFR>
                <BoxFR>
                  <Box flex={1} /><TextField {...textFieldProps("RtnPlc")} /><Box flex={1} />
                </BoxFR>
                <BoxFR>
                  <Box flex={1} /><Box flex={1} /><TextField {...textFieldProps("Voy")} />
                </BoxFR>
                <BoxFR>
                  <TextField {...textFieldProps("Vssl")} /><Box flex={1} /><Box flex={1} />
                </BoxFR>
                <BoxFR>
                  <TextField {...textFieldProps("Port")} /><Box flex={1} /><Box flex={1} />
                </BoxFR>
                <BoxFR>
                  <TextField {...textFieldProps("EDNo")} />
                  <TextField {...textFieldProps("CT")} />
                  <TextField {...textFieldProps("KGM")} />
                </BoxFR>
                <BoxFR>
                  <Box flex={1} /><TextField {...textFieldProps("IP_InvNo")} /><Box flex={1} />
                </BoxFR>
                <BoxFR>
                  <TextField {...textFieldProps("TotalKGM")} /><Box flex={1} /><Box flex={1} />
                </BoxFR>
                <BoxFR>
                  <TextField {...textFieldProps("Bkg")} /><Box flex={1} /><Box flex={1} />
                </BoxFR>
                <Divider />
                <Typography variant="h6">ข้อมูล PL / Multi Container</Typography>
                <Box width="100%" flex={1} minHeight={300} sx={sxSumColorClass}>
                  <DataGridCellExpand
                    selectionModel={selectionModel}
                    onSelectionModelChange={handleSelectionModelChange}
                    columns={approveCDColumns(false)}
                    rows={modDataTable}
                    hideFooter
                    disableSelectionOnClick={false}
                    getRowClassName={getSumColorClassName}
                  />
                </Box>
              </BoxFC>
            </Paper>
            <Paper sx={{ p: 2, flex: 1, height: "100%" }}>
              <BoxFC height="100%">
                <BoxFR>
                  <Typography variant="h6">ใบกำกับตู้</Typography>
                  <TextField size="small" select
                    sx={{ flex: 1 }}
                    value={selectedCDFile}
                    onChange={handleCDFileChange}>
                    {dialogData.CDFiles.map((file, index) => (
                      <MenuItem key={index} value={file.FileNm}>{file.Label}</MenuItem>
                    ))}
                  </TextField>
                </BoxFR>
                <object aria-label="pdf document" id="pdf"
                  data={`/file/shipping/${selectedId}/${selectedCDFile}`} type="application/pdf" width="100%" height="100%" />
              </BoxFC>
            </Paper>
          </BoxFR>
          <Box pb={2} alignSelf="center">
            <Button sx={{ width: 200 }} disabled={dialogData.CDAprvTm?true:false}
              variant="contained" color="secondary" onClick={handleConfirm}>ข้อมูลถูกต้อง
              </Button>
          </Box>
        </BoxFC>
      </DialogContent>
      <DialogFooter okText={""}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={null} />
    </Dialog>
  )
}

export default ApproveCDDialog