import { Dialog, DialogContent } from "@mui/material"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect } from "react"
import PackingListConfirm from "../PackingListConfirm/PackingListConfirm"

const ApproveDialog = ({ selectedId, dialogOpen, setDialogOpen, onFinish, lastFilter }) => {

  const { ax } = useContext(GlobalStateContext)
  useEffect(() => {
    if (dialogOpen) {
    }
  }, [dialogOpen, ax])

  const modOnFinish = useCallback((data) => {
    if(onFinish){
      onFinish(data)
    }
    setDialogOpen(false)
  }, [onFinish, setDialogOpen])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth={false} fullWidth
      PaperProps={{
        style: {
          height: "95%"
        }
      }}>
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={"ตรวจสอบ Draf ใบขน"}
        onDeleteClick={null}
      />
      <DialogContent >
        <PackingListConfirm mShpInvId={selectedId} onFinish={modOnFinish} lastFilter={lastFilter}/>
      </DialogContent>
      <DialogFooter okText={""}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={null} />
    </Dialog>
  )
}

export default ApproveDialog