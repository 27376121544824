import { CloudUploadRounded, PhotoRounded } from "@mui/icons-material"
import { Button, Dialog, DialogContent, ImageList, ImageListItem, ImageListItemBar, Typography } from "@mui/material"
import { blueGrey, grey } from "@mui/material/colors"
import { branchApiNm } from "branch/constant/branchApiNm"
import { alertError } from "components/Alert"
import { BoxFC, BoxFR } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { jobOrderApiNm } from "constants/jobOrderConstant"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import dayjs from "dayjs"
import FullScreenImage from "pages/FullScreenImage/FullScreenImage"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"

const SelectImageDialog = ({ jobOrdId, businessRefNo, dialogOpen, setDialogOpen, onFinish, lastFilter }) => {

  const { ax } = useContext(GlobalStateContext)

  const [jobOrdFiles, setJobOrdFiles] = useState([])
  const [curImage, setCurImage] = useState(null)

  const curImageSrc = useMemo(() => {
    if (!curImage) return ""
    return `/file/job-order/${jobOrdId}/${curImage.FileNm}`
  }, [curImage, jobOrdId])
  const getData = useCallback(() => {
    ax.post(jobOrderApiNm.getJobOrderFile, { JobOrdIds: jobOrdId }).then(value => {
      if (value.data && value.data.length > 0) {
        const files = value.data
        let contNo = "";
        let sealNo = "";
        let tareWt = "";
        for (const file of files) {
          if (file.OCRTxt) {
            if (contNo === "" || sealNo === "" || tareWt === "") {
              // console.log("in if")
              try {
                const ocrObj = JSON.parse(file.OCRTxt)
                // console.log("ocrObj::", ocrObj)
                if (ocrObj.ContNo && contNo === "") contNo = ocrObj.ContNo
                if (ocrObj.SealNo && sealNo === "") sealNo = ocrObj.SealNo
                if (ocrObj.TareWt && tareWt === "") tareWt = ocrObj.TareWt
              } catch (e) {
                console.log("parse error", e)
              }
            } else {
              break;
            }
          }

          file.checked = false;
        }
        setJobOrdFiles(files)
        // setJobOrdFiles([files[0], files[1]])
      }
    })
  }, [ax, jobOrdId])

  const handleSave = useCallback(() => {
    if(!curImage){
      alertError("กรุณาเลือกรูปภาพก่อน")
      return
    }
    const postData = {
      JobOrdId: jobOrdId,
      businessRefNo: businessRefNo,
      eventTime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      sequence: 3,
      JobOrdFileId: curImage.JobOrdFileId,
      getArgs: lastFilter
    }
    console.log("postData::", postData) 
    ax.post(branchApiNm.sendMileStone, postData).then(value => {
      if (value.data) {
        onFinish(value.data)
        setDialogOpen(false)
      }
    })
  }, [onFinish, ax, businessRefNo, jobOrdId, lastFilter, curImage, setDialogOpen])

  useEffect(() => {
    if (dialogOpen) {

      getData()
    } else {

      setJobOrdFiles([])
      setCurImage(null)
    }
  }, [getData, dialogOpen])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='lg'
      PaperProps={{
        style: {
          maxHeight: 950,
          minHeight: 950,
        }
      }}
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="เลือกรูปภาพ"
        onDeleteClick={null}
      />
      <DialogContent sx={{ pb: 0 }}>
        <BoxFR mt={1} height="100%" sx={{ alignItems: "stretch" }}>
          <BoxFC sx={{ gap: 0, width: 500 }}>
            <ImageList cols={2} rowHeight={300} sx={{ width: "100%", height: 810, m: 0, bgcolor: blueGrey[50], p: 1, borderRadius: 1 }}>
              {jobOrdFiles.map((file) => {

                const src = `/file/job-order/${jobOrdId}/${file.FileNm}`
                const linkUrl = src.replaceAll("/", "~")
                const ocrObj = JSON.parse(file.OCRTxt) || {}
                console.log("linkUrl::", linkUrl)
                console.log("key file.FileNm::", file.FileNm)
                return (
                  <ImageListItem key={file.FileNm} sx={{ position: "relative", "& .MuiImageListItemBar-root": { px: 5 } }} >
                    <img alt={file.Label} src={src} loading="lazy" onClick={() => { setCurImage(file) }} style={{ height: 300 }} />

                    <ImageListItemBar
                      sx={{ px: 6, "& .MuiImageListItemBar-titleWrap": { px: 0, py: 0.5 }, }}
                      title={`${file.Label}`}
                      subtitle={
                        <BoxFC sx={{ gap: 0.5 }}>
                          <span>by: {file.DrvNNm} {dayjs(file.AddTm).format("DD/MM/YYYY HH:mm")}</span>
                          {Object.keys(ocrObj).map(key => (<span key={key}>{key}: {ocrObj[key]}</span>))}
                          {/* <div>{file.OCRTxt || "-"}</div> */}
                        </BoxFC>
                      }
                    />
                  </ImageListItem>
                )
              })}
            </ImageList>

          </BoxFC>
          <BoxFC flex={1}>
            <BoxFC bgcolor={blueGrey[50]} borderRadius={1} height={760} width="100%"
              sx={{ justifyContent: "center", alignItems: "center" }}>
              {curImage ?
                <FullScreenImage linkUrl={curImageSrc} />
                :
                <BoxFC sx={{ gap: 0 }} justifyContent="center" alignItems="center" width="100%" height="100%">
                  <PhotoRounded sx={{ fontSize: 50, color: grey[500] }} />
                  <Typography variant="h6" sx={{ color: grey[500] }}>กรุณาเลือกรูป</Typography>
                </BoxFC>
              }

            </BoxFC>
            <BoxFC >
              <Button variant="contained" onClick={() => handleSave()}>
                <CloudUploadRounded sx={{ mr: 1 }} /> ส่งรูปภาพนี้
              </Button>
            </BoxFC>
          </BoxFC>

        </BoxFR>
      </DialogContent>
      <DialogFooter okText={""}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={null} />
    </Dialog>
  )
}

export default React.memo(SelectImageDialog)