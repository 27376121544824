import { Dialog, DialogContent, MenuItem, TextField } from "@mui/material"
import { BoxFC, BoxFR } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { shippingInvoiceColNm } from "./constant"
import { shippingApiNm } from "./shippingApiNm"
import { initPLDailogData } from "./initData"
import dayjs from "dayjs"
import { alertError } from "components/Alert"
import FileDropPanel from "components/FileUplaod/FileDropPanel"
import DateTimePickerCustomTime from "components/DateTimePickerCustomTime"
import { UserContext } from "contexts/UserContext"
import { DesktopDatePicker } from "@mui/x-date-pickers-pro"
import { readPL } from "../PackingListConfirm/readPL"
import { readInvNCR } from "../PackingListConfirm/readInvNCR"

const PackingListDialog = ({ selectedId, selectedJobId, shpmId, dialogOpen, setDialogOpen, cusId, onFinish, shippingAccounts }) => {

  const { ax, msData } = useContext(GlobalStateContext)
  const { user } = useContext(UserContext)
  const [dialogData, setDialogData] = useState({ ...initPLDailogData })
  const [selectedFiles, setSelectedFiles] = useState([])

  const textFieldProps = useCallback((name) => ({
    size: "small",
    label: shippingInvoiceColNm[name],
    value: dialogData[name] || "",
    onChange: (e) => setDialogData(o => ({ ...o, [name]: e.target.value }))
  }), [dialogData])

  const datePickerProp = useMemo(() => ({
    label: "วันที่ Invoice",
    inputFormat: "DD/MM/YYYY",
    mask: "__/__/____",
    value: dialogData.IP_InvDte,
    onChange: (newValue) => { setDialogData(o => ({ ...o, InvDte: newValue })) },
    renderInput: (params) => <TextField size="small"  {...params} />,
  }), [dialogData])

  const handleDialogOk = useCallback(async () => {
    let success = true
    let hasChange = false
    if (dialogData.InvNo === "" || dialogData.OwnAccEmail === "" || selectedFiles.length === 0) {
      alertError("กรุณาตรวจสอบข้อมูลให้ครบถ้วน")
      return
    }

    hasChange = true
    const postData = {
      ShpInvId: selectedId,
      ColValues: {
        OwnAccEmail: dialogData.OwnAccEmail,
        OwnAccNm: dialogData.OwnAccNm,
        OwnAccId: dialogData.OwnAccId,
        IP_Csgn: dialogData.IP_Csgn,
        IP_InvNo: dialogData.IP_InvNo,
        IP_InvDte: dialogData.IP_InvDte ? dayjs(dialogData.IP_InvDte).format("YYYY-MM-DD") : null,
        IP_PO: dialogData.IP_PO,
        IP_ETD: dialogData.IP_ETD ? dayjs(dialogData.IP_ETD).format("YYYY-MM-DD HH:mm:ss") : null,
        PLCreTm: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        IP_Shpr: dialogData.IP_Shpr,
        IP_NofyPrty: dialogData.IP_NofyPrty,
        IP_PoL: dialogData.IP_PoL,
        IP_CoL: dialogData.IP_CoL,
        IP_Vssl: dialogData.IP_Vssl,
        IP_PayTrm: dialogData.IP_PayTrm,
        IP_NmPlc: dialogData.IP_NmPlc,
        IP_BkgNo: dialogData.IP_BkgNo,
        IP_ShpmTrm: dialogData.IP_ShpmTrm,
        IP_CoO: dialogData.IP_CoO,
        IP_CoC: dialogData.IP_CoC,
        IP_CoD: dialogData.IP_CoD,
        IP_PoD: dialogData.IP_PoD,
        IP_FinlDst: dialogData.IP_FinlDst,
        IP_Rmk: dialogData.IP_Rmk,
        LogRmk: "Update packing list data"
      },
    }
    const value = await ax.post(shippingApiNm.updateShippingInvoiceColumns, postData)

    if (value.data) {
      success = true
    }

    for (const file of selectedFiles) {
      const data = new FormData();
      data.append("file", file);
      data.append("ShpFileTypId", 1);
      data.append("ShpInvId", selectedId);
      data.append("Label", file.label);
      const result = await ax.post(shippingApiNm.uploadFile, data)
      if (result.data) {
        success = success && true
      }
    }

    if (success) {
      setDialogOpen(false)
    }
    if (hasChange) {
      onFinish()
    }

  }, [ax, dialogData, onFinish, selectedFiles, setDialogOpen, selectedId])

  const handleOwnAccNameChange = useCallback((e) => {
    const acc = shippingAccounts.find(acc => acc.AccId === e.target.value)
    setDialogData(o => ({ ...o, OwnAccId: e.target.value, OwnAccEmail: acc?.Email || "" }))
  }, [shippingAccounts])

  const handleSelectedFileChange = useCallback(async (files) => {
    if (files && files.length > 0) {
      console.log('files::', files)
      let plData = null
      if (cusId === 29626) {
        for (const file of files) {
          if (file.name.toLowerCase().endsWith("inv.pdf")) {
            plData = await readInvNCR(file).catch(err => {
              console.log(err)
              alertError("ไม่สามารถอ่านข้อมูลจากไฟล์ได้")
            })
            if (plData?.InvNo) {
              plData.Csgn = msData.customers.find(cus => cus.CusId === 29626)?.Name
              break
            }
          }
        }
      } else {
        plData = await readPL(files[0])
        console.log("plData::", plData)
      }
      if (plData) {
        setDialogData(o => ({
          ...o,
          IP_InvNo: o.IP_InvNo ||  plData.IP_InvNo,
          IP_InvDte: o.IP_InvDte || plData.IP_InvDte ? dayjs(plData.IP_InvDte, "YYYY.MM.DD") : null,
          IP_Csgn: o.IP_Csgn || plData.IP_Csgn.split("\n")[0],
          IP_PO: o.IP_PO || plData.IP_PO,
          IP_ETD: o.IP_ETD || plData.IP_ETD ? dayjs(plData.IP_ETD, "YYYY.MM.DD") : null,
          IP_Shpr: o.IP_Shpr || plData.IP_Shpr,
          IP_NofyPrty: o.IP_NofyPrty || plData.IP_NofyPrty,
          IP_PoL: o.IP_PoL || plData.IP_PoL,
          IP_CoL: o.IP_CoL || plData.IP_CoL,
          IP_Vssl: o.IP_Vssl || plData.IP_Vssl,
          IP_PayTrm: o.IP_PayTrm || plData.IP_PayTrm,
          IP_NmPlc: o.IP_NmPlc || plData.IP_NmPlc,
          IP_BkgNo: o.IP_BkgNo || plData.IP_BkgNo,
          IP_ShpmTrm: o.IP_ShpmTrm || plData.IP_ShpmTrm,
          IP_CoO: o.IP_CoO || plData.IP_CoO,
          IP_CoC: o.IP_CoC || plData.IP_CoC,
          IP_CoD: o.IP_CoD || plData.IP_CoD,
          IP_PoD: o.IP_PoD || plData.IP_PoD,
          IP_FinlDst: o.IP_FinlDst || plData.IP_FinlDst,
          IP_Rmk: o.IP_Rmk || plData.IP_Rmk
        }))
      }
    }
  }, [cusId, msData.customers])

  const shippingAccountMemo = useMemo(()=>{

    return shippingAccounts.filter((acc)=>{
      const jobIds = acc.JobIds.split(",").map(id=>parseInt(id))
      return jobIds.includes(selectedJobId)
    })
  }, [selectedJobId, shippingAccounts])
  useEffect(() => {
    if (dialogOpen) {
      setDialogData({
        ...initPLDailogData,
        OwnAccId: user.AccTypId === 2 ? user.AccId : null,
        OwnAccNm: user.AccTypId === 2 ? user.FName : null,
        OwnAccEmail: user.AccTypId === 2 ? user.Email : ""
      })
      setSelectedFiles([])
    }
  }, [dialogOpen, ax, selectedId, user])

  console.log("in PackingListDialog selectedId::", selectedId)
  console.log("in PackingListDialog shpmId::", shpmId)
  console.log("dialogData::", dialogData)
  console.log("user::", user)
  console.log('cusId::', cusId)
  console.log('shippingAccounts::', shippingAccounts)
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='sm' >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={"ข้อมูล Packing List"}
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC mt={2} >
          <BoxFR sx={{ alignItems: "flex-start" }} justifyContent="space-around" >
            <FileDropPanel width={300} height={380}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              onChange={handleSelectedFileChange} />
            <BoxFC>
              <TextField {...textFieldProps("IP_InvNo")} required error={!dialogData.IP_InvNo} />
              <DesktopDatePicker {...datePickerProp} />
              <TextField {...textFieldProps("OwnAccId")} required error={!dialogData.OwnAccId}
                disabled={user.AccTypId !== 1} select onChange={handleOwnAccNameChange}>
                {shippingAccountMemo.map((acc) => (
                  <MenuItem key={acc.AccId} value={acc.AccId}>{acc.FName}</MenuItem>
                ))}
              </TextField>
              <TextField {...textFieldProps("OwnAccEmail")} required error={!dialogData.OwnAccEmail} disabled={user.AccTypId !== 1} />
              <TextField {...textFieldProps("IP_Csgn")} />
              <TextField {...textFieldProps("IP_PO")} />
              <DateTimePickerCustomTime label="ETD"
                inputFormat="DD/MM/YYYY HH:mm"
                mask="__/__/____ __:__"
                value={dialogData.IP_ETD}
                onChange={(newValue) => { setDialogData(o => ({ ...o, IP_ETD: newValue })) }}
                renderInput={(params) => <TextField size="small"  {...params} />} />
            </BoxFC>
          </BoxFR>

        </BoxFC>
      </DialogContent>
      <DialogFooter okText={"บันทึก"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleDialogOk} />
    </Dialog>
  )
}

export default PackingListDialog