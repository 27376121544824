import { ArticleRounded } from "@mui/icons-material";
import { Box, Paper, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import { BoxFC, BoxFR } from "components/BoxCustom";
import PopoverDefault from "components/PopoverDefault";
import { numberFormat, numberFormatNoFlotingPoint } from "utils";

const renderData = (data) => {
  const dataCharge = +data.OwnRcptAmnt ? data.OwnRcptAmnt : data.TrnsUntPr
  const dataTotal = dataCharge + +data.CusRcptAmnt
  return (
  <BoxFR sx={{ gap: 1 }}>
    {data.NumJob === 1 ?
      `${data.TakePlc||""}-${data.Loc||""}-${data.RtnPlc||""}`
      :
      <BoxFR>จำนวนงาน: {data.NumJob} งาน
        <PopoverDefault icon={<ArticleRounded color="primary" />}>
          <Paper sx={{ p: 2 }}>
            <BoxFC sx={{alignItems: "center", gap: 1}}>
              {data.JobOrders.map((jobOrd, index) => {
                const charge = +jobOrd.OwnRcptAmnt ? jobOrd.OwnRcptAmnt : jobOrd.TrnsUntPr
                const total = charge + +jobOrd.CusRcptAmnt
                return (
                  <BoxFR key={index}>
                    <Typography variant="body2">{jobOrd.TakePlc||""}-{jobOrd.Loc||""}-{jobOrd.RtnPlc||""}</Typography>

                    <Typography variant="body2" color={blue[800]}>
                      <strong>$</strong>(C{numberFormatNoFlotingPoint(charge)} | A{numberFormatNoFlotingPoint(+jobOrd.CusRcptAmnt)} | T{numberFormatNoFlotingPoint(total)})
                    </Typography>
                  </BoxFR>
                )
              })}

            </BoxFC>
          </Paper>
        </PopoverDefault>
      </BoxFR>
    }
    <Box color={blue[800]}>
      <strong>$</strong>(C{numberFormatNoFlotingPoint(dataCharge)} | A{numberFormatNoFlotingPoint(+data.CusRcptAmnt)} | T{numberFormatNoFlotingPoint(dataTotal)})
    </Box>
  </BoxFR>
)}
export const columns = [
  {
    field: "No",
    headerName: "No",
    width: 50,
  },
  {
    field: "TukCd",
    headerName: "เลขรถ",
    width: 80,
  },
  {
    field: "TukAttnStsId",
    headerName: "สถานะรถ",
    width: 150,
    valueGetter: ({row, value}) => row.id <= 0 || !value ?"": value===1? "": `${row.TukAttnStsNm} ${row.Dscp?`(${row.Dscp})`:''}`
  },
  {
    field: "DrvNNm",
    headerName: "พนักงานขับรถ",
    width: 80,
  },
  {
    field: "DrvAttnSts",
    headerName: "สถานะ พขร",
    width: 100,
  },
  {
    field: "NumJob",
    headerName: "จำนวนเที่ยว",
    valueFormatter: (params) => `${params.value}`,
    width: 90,
    align: "center",
  },
  {
    field: "JobOrd1",
    headerName: "เที่ยวที่ 1",
    flex: 1,
    minWidth: 200,
    renderCell: (params) => {
      if (!params.value) return ""
      return renderData(params.value)
    }
  },
  {
    field: "JobOrd2",
    headerName: "เที่ยวที่ 2",
    flex: 1,
    minWidth: 200,
    renderCell: (params) => {
      if (!params.value) return ""
      return renderData(params.value)
    }
  },
  {
    field: "JobOrd3",
    headerName: "เที่ยวที่ 3++",
    flex: 1,
    minWidth: 200,
    renderCell: (params) => {
      if (!params.value) return ""
      return renderData(params.value)
    }
  },
  {
    field: "OwnRcptAmnt",
    headerName: "รวมใบเสร็จบริษัท",
    valueGetter: (params) => params.value ? +params.value : 0,
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "0.00",
    align: "right",
    width: 120,
  },
  {
    field: "CusRcptAmnt",
    headerName: "รวมใบเสร็จลูกค้า",
    valueGetter: (params) => params.value ? +params.value : 0,
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "0.00",
    align: "right",
    width: 120,
  },
  {
    field: "Total",
    headerName: "รวม",
    valueGetter: (params) => +params.row.OwnRcptAmnt + +params.row.CusRcptAmnt,
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "0.00",
    align: "right",
    width: 120,
  },
]