
import React, { useMemo, useState, useCallback, useEffect, useContext } from "react"
import { Box, Button, Dialog, DialogContent, TextField } from "@mui/material"
import { BoxFC, BoxFR } from "components/BoxCustom"
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { addSumRow, getSumColorClassName, sxSumColorClass } from "utils/highlightSumColor";
import { plColumns } from "../PackingListConfirm/columns"
import { packingListColNm } from "../PackingListConfirm/constant"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import { shippingApiNm } from "./shippingApiNm"
import { getNCRPLData, getPLData } from "../PackingListConfirm/getPLData"
import { AddCircleRounded, ContentPasteRounded, FileCopyRounded } from "@mui/icons-material"
import { addIdForDataGrid, moveRowTo, toNumber } from "utils"
import { alertError } from "components/Alert"
import dayjs from "dayjs"

const initDialogData = {
  IP_Shpr: "",
  IP_Csgn: "",
  IP_NofyPrty: "",
  IP_PoL: "",
  IP_CoL: "",
  IP_Vssl: "",
  IP_ETD: "",
  IP_InvNo: "",
  IP_InvDte: "",
  IP_PayTrm: "",
  IP_NmPlc: "",
  IP_CoO: "",
  IP_CoC: "",
  IP_Rmk: "",
  IP_FinlDst: "",
  IP_CoD: "",
  IP_BkgNo: "",
  IP_ShpmTrm: "",
  IP_PO: "",
  IP_PoD: "",
}

let isEditing = false;

const PackingListDataDialog = ({ shpInvId, files, dialogModDte, cusId, dialogOpen, setDialogOpen }) => {

  const [dialogData, setDialogData] = useState({ ...initDialogData })
  const [excelData, setExcelData] = useState([])

  const { ax, msData } = useContext(GlobalStateContext)
  const textFieldProps = useCallback((name) => ({
    size: "small",
    variant: "outlined",
    label: packingListColNm[name],
    value: dialogData[name] ?? "",
    onChange: (e) => setDialogData(o => ({ ...o, [name]: e.target.value }))
  }), [dialogData])

  const modDataTable = useMemo(() => {
    console.log('in PackingListDataDialog excelData::', excelData)
    if (excelData.length > 0 && excelData[excelData.length - 1].id === -1) {
      return excelData
    }
    // return excelData
    return addSumRow({
      dataTable: excelData,
      sumColNms: ["QtyCT", "QtyPCS", "Amnt", "NW", "QtyM", "GW", "M3"],
      sumColTotal: "Mrk"
    })
  }, [excelData])

  const loadFileData = useCallback(async () => {
    let plData
    if (cusId === 29626) {
      plData = await getNCRPLData(files, shpInvId, msData)
    } else {
      plData = await getPLData(files, shpInvId)
    }
    if (plData) {
      console.log('plData::', plData)
      setExcelData(plData.rowData.map(item=>({...item})))
      delete plData.rowData
      setDialogData(o=>({
        ...o,
        IP_InvNo: o.IP_InvNo ||  plData.IP_InvNo,
        IP_InvDte: o.IP_InvDte || plData.IP_InvDte ? dayjs(plData.IP_InvDte, "YYYY.MM.DD") : null,
        IP_Csgn: o.IP_Csgn || plData.IP_Csgn.split("\n")[0],
        IP_PO: o.IP_PO || plData.IP_PO,
        IP_ETD: o.IP_ETD || plData.IP_ETD ? dayjs(plData.IP_ETD, "YYYY.MM.DD") : null,
        IP_Shpr: o.IP_Shpr || plData.IP_Shpr,
        IP_NofyPrty: o.IP_NofyPrty || plData.IP_NofyPrty,
        IP_PoL: o.IP_PoL || plData.IP_PoL,
        IP_CoL: o.IP_CoL || plData.IP_CoL,
        IP_Vssl: o.IP_Vssl || plData.IP_Vssl,
        IP_PayTrm: o.IP_PayTrm || plData.IP_PayTrm,
        IP_NmPlc: o.IP_NmPlc || plData.IP_NmPlc,
        IP_BkgNo: o.IP_BkgNo || plData.IP_BkgNo,
        IP_ShpmTrm: o.IP_ShpmTrm || plData.IP_ShpmTrm,
        IP_CoO: o.IP_CoO || plData.IP_CoO,
        IP_CoC: o.IP_CoC || plData.IP_CoC,
        IP_CoD: o.IP_CoD || plData.IP_CoD,
        IP_PoD: o.IP_PoD || plData.IP_PoD,
        IP_FinlDst: o.IP_FinlDst || plData.IP_FinlDst,
        IP_Rmk: o.IP_Rmk || plData.IP_Rmk
      }))
    }
  }, [msData, files, shpInvId, cusId])

  const pasteDataFromClipboard = useCallback(() => {
    navigator.clipboard.readText().then(clipText => {
      setExcelData(o => {
        const rows = clipText.trim().split('\n').map(row => row.split('\t'));
        console.log('rows::', rows)
        if (rows[0].length !== 12) {
          alert('ข้อมูลที่วางต้องมี 12 คอลัมน์เท่านั้น')
          return o
        }
        let runId = o.length
        const results = []
        for (const row of rows) {
          for (let i = 0; i < row.length; i++) {
            console.log('row[i]::', i, row[i], typeof row[i])
            row[i] = row[i].trim()
            if ([2, 3, 5, 6, 7, 8, 9, 10].includes(i)) {
              row[i] = toNumber(row[i])
            }

          }
          runId++
          row.id = runId
          results.push({
            id: `tmp-${runId}`,
            No: runId,
            Mrk: row[0],
            Dscp: row[1],
            QtyCT: row[2],
            QtyPCS: row[3],
            Unit: row[4],
            Price: row[5],
            Amnt: row[6],
            NW: row[7],
            GW: row[8],
            QtyM: row[9],
            M3: row[10],
            Rmk: row[11],
          })
        }
        return [...o, ...results]
      })
    });
  }, [])

  const handleRowOrderChange = useCallback((params) => {
    setExcelData(o => moveRowTo(o, params.oldIndex, params.targetIndex))
  }, [])


  const handleSave = useCallback(() => {
    console.log("isEditing::", isEditing)
    if (isEditing) {
      alertError("กำลังจัดการข้อมูลที่แก้ไขอยู่ กรุณากดบันทีกอีกครั้ง")
      return
    }
    const postData = {
      ...dialogData,
      ShpInvId: shpInvId,
      ModDte: dialogModDte,
      argsArr: excelData.map(row => {
        return {
          ShpInvId: shpInvId,
          Mrk: row.Mrk,
          Dscp: row.Dscp,
          QtyCT: row.QtyCT || 0,
          QtyPCS: row.QtyPCS || 0,
          Unit: row.Unit,
          Price: row.Price || 0,
          Amnt: row.Amnt || 0,
          NW: row.NW || 0,
          GW: row.GW || 0,
          QtyM: row.QtyM || 0,
          M3: row.M3 || 0,
          Rmk: row.Rmk,
        }
      })
    }
    ax.post(shippingApiNm.insertShippingInvWithPLItem, postData).then(value => {
      if (value.data) {
        setDialogOpen(false)
      }
    })
    setDialogOpen(false)

  }, [ax, setDialogOpen, dialogData, excelData, dialogModDte, shpInvId])

  const handleRowEditStart = useCallback((params) => {
    console.log("rowEidt start params::", params)
    isEditing = true
  }, [])

  const handleRowEidtStop = useCallback((params) => {
    console.log('params::', params)

    setExcelData(o => {
      return o.map(oRow => {
        if (params.id === oRow.id) {
          return params.row
        } else {
          return oRow
        }
      })
    })
    isEditing = false
  }, [])

  const handleDeleteRow = useCallback((row) => {
    setExcelData(data => {
      const result = data.filter(d => d.id !== row.id)
      result.forEach((item, index) => { item.No = index + 1 })
      return result
    })
  }, [])

  const handleAddEmptyRow = useCallback(() => {
    
    setExcelData(o => [...o, {
      id: `tmp-${o.lenght}`,
      No: o.length,
      Mrk: "",
      Dscp: "",
      QtyCT: "",
      QtyPCS: "",
      Unit: "",
      Price: "",
      Amnt: "",
      NW: "",
      GW: "",
      QtyM: "",
      M3: "",
      Rmk: "",
    }])
  }, [])

  useEffect(() => {
    if (dialogOpen) {
      ax.post(shippingApiNm.getShippingInvPLItem, { ShpInvId: shpInvId }).then(value => {
        if (value.data) {
          setDialogData(value.data)
          setExcelData(addIdForDataGrid(value.data.ShpInvPLItms, "ShpInvPLItmId"))
        }
      })
    } else {
      setDialogData({ ...initDialogData })
      setExcelData([])
      isEditing = false
    }
  }, [dialogOpen, ax, shpInvId, setDialogOpen])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth
      PaperProps={{
        style: {
          height: '90%',
          minWidth: 1700,
          maxWidth: 1700
        }
      }}
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="ข้อมูล Invoice-Packing List"
        onDeleteClick={null}
      />
      <DialogContent sx={{ pt: 2 }}>
        <BoxFC height="100%">
          <BoxFR sx={{ alignItems: "flex-start" }}>
            <BoxFC width={800} sx={{ gap: 1, mt: 2 }}>
              <BoxFR mt={-1}>
                <BoxFC flex={1} sx={{ gap: 1 }}>
                  <TextField {...textFieldProps("IP_Shpr")} multiline rows={3} />
                  <TextField {...textFieldProps("IP_Csgn")} multiline rows={3} />
                  <TextField {...textFieldProps("IP_NofyPrty")} multiline rows={3} />
                </BoxFC>
                <BoxFC flex={1} sx={{ gap: 1 }}>
                  <BoxFR>
                    <TextField {...textFieldProps("IP_InvNo")} />
                    <TextField {...textFieldProps("IP_InvDte")} />
                  </BoxFR>
                  <BoxFR>
                    <TextField {...textFieldProps("IP_PayTrm")} />
                    <TextField {...textFieldProps("IP_NmPlc")} />
                  </BoxFR>
                  <BoxFR>
                    <TextField {...textFieldProps("IP_CoO")} />
                    <TextField {...textFieldProps("IP_CoC")} />
                  </BoxFR>
                  <BoxFR>
                    <TextField {...textFieldProps("IP_PO")} />
                    <TextField {...textFieldProps("IP_PoD")} />
                  </BoxFR>
                  <TextField {...textFieldProps("IP_Rmk")} multiline rows={3} />
                </BoxFC>
              </BoxFR>
              <BoxFR>
                <TextField {...textFieldProps("IP_PoL")} />
                <TextField {...textFieldProps("IP_CoL")} />
                <TextField {...textFieldProps("IP_FinlDst")} />
                <TextField {...textFieldProps("IP_CoD")} />
              </BoxFR>
              <BoxFR>
                <TextField {...textFieldProps("IP_Vssl")} />
                <TextField {...textFieldProps("IP_ETD")} />
                <TextField {...textFieldProps("IP_BkgNo")} />
                <TextField {...textFieldProps("IP_ShpmTrm")} />
              </BoxFR>
            </BoxFC>
            <BoxFC pt={1} height="100%">
              <Button variant="contained" onClick={loadFileData}>
                <FileCopyRounded sx={{ mr: 1 }} />
                ดึงข้อมูล Inv-PL จาก File(s)
              </Button>
              <Box flex={1} />
              <BoxFR>
                <Button variant="contained" onClick={pasteDataFromClipboard}>
                  <ContentPasteRounded sx={{ mr: 1 }} />
                  วางข้อมูลจาก Clipboard
                </Button>

                <Button variant="contained" onClick={handleAddEmptyRow}>
                  <AddCircleRounded sx={{ mr: 1 }} />
                  เพิ่มข้อมูลแถวเปล่า
                </Button>
              </BoxFR>
            </BoxFC>
          </BoxFR>

          <Box width='100%' flex={1} sx={sxSumColorClass}>
            <DataGridCellExpand
              hideFooter
              getRowClassName={getSumColorClassName}
              rows={modDataTable}
              columns={plColumns(handleDeleteRow)}
              rowReordering
              editMode="row"
              onRowEditStart={handleRowEditStart}
              onRowEditStop={handleRowEidtStop}
              onRowOrderChange={handleRowOrderChange} />
          </Box>
        </BoxFC>
      </DialogContent>
      <DialogFooter okText={"บันทึก"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleSave} />
    </Dialog>
  )
}

export default React.memo(PackingListDataDialog)