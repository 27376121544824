import React, { useEffect, useState, useCallback, useContext } from "react";
import { Box, Dialog, DialogContent, TextField } from "@mui/material"
import { DialogHeader, DialogFooter } from "components/dialog/DialogHeader";
import PaperComponent from "components/PaperComponent";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { shippingApiNm } from "./shippingApiNm";
import { shippingInvoiceColNm } from "./constant";

const UpdateTextDialog = ({ selectedId, colNm, oldData, onFinish, setDialogOpen, dialogOpen, lastFilter }) => {
  const [colData, setColData] = useState("");

  const { ax } = useContext(GlobalStateContext)

  const dialogDataProp = useCallback((name) => ({
    sx: { mt: 1 },
    variant: 'outlined',
    size: 'small',
    name: name,
    
    label: shippingInvoiceColNm[name] || "",
    value: colData || "",
    onChange: (e) => {
      setColData(e.target.value)
    }
  }), [colData])

  useEffect(() => {
    if (dialogOpen) {
      setColData(oldData)
    } else {
      setColData("")
    }
  }, [oldData, dialogOpen])

  const handleUpdateColumn = () => {
    const postData = {
      ShpInvId: selectedId,
      ColValues: { [colNm]: colData },
      getArgs: lastFilter
    }
    ax.post(shippingApiNm.updateShippingInvoiceColumns, postData).then(value => {
      if (value.data) {
        onFinish(value.data)
        setDialogOpen(false)
      }
    })
  }
  // console.log("in update text jobOrdIds::", jobOrdIds)
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='sm' >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={`แก้ไขข้อมูล ${shippingInvoiceColNm[colNm]}`}
        onDeleteClick={null}
      />
      <DialogContent>
        <Box display='flex' flexDirection='column' gap={1} mt={1}>
          <TextField autoFocus {...dialogDataProp(colNm)} multiline={["Rmk", "Note"].includes(colNm)} rows={10}/>
        </Box>
      </DialogContent>
      <DialogFooter okText={"แก้ไขข้อมูล"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleUpdateColumn} />
    </Dialog>
  )
}

export default React.memo(UpdateTextDialog)