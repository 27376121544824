
export const clearFilterData = {
  Bkg: null,
  IP_InvNo: null,
  Sts: null,
  CusId: null,
  AptTmSt: null,
  AptTmEn: null,
  OwnAccNm: null,
  PLCreTmSt: null,
  PLCreTmEn: null,
  EDCreTmSt: null,
  EDCreTmEn: null,
}
export const initFitlterData = {
  ...clearFilterData,
  Sts: "NotFin",
}
export const initPLDailogData = {
  IP_InvNo: "",
  IP_InvDte: null, 
  OwnAccId: null,
  OwnAccEmail: "",
  IP_Csgn: "",
  IP_PO:"",
  IP_ETD: null,
  PLCreTm: null,
  PLFile: null,
  IP_Shpr: "",
  IP_NofyPrty: "",
  IP_PoL: "",
  IP_CoL: "",
  IP_Vssl: "",
  IP_PayTrm: "",
  IP_NmPlc: "",
  IP_BkgNo: "",
  IP_ShpmTrm: "",
  IP_CoO: "",
  IP_CoC: "",
  IP_CoD: "",
  IP_PoD: "",
  IP_FinlDst: "",
  IP_Rmk: "",
}

export const initShippingInvoiceData = {
  ShpInvId: null,
  ShpmId: null,
  Note: "",
  InvNo: "",
  InvDte: null,
  Csgn: "",
  PO: "",
  ETD: null,
  OwnAccId: null,
  OwnAccNm: null,
  OwnAccEmail: "",
  PLCreTm: null,
  EDCreTm: null,
  EDCreAccId: null,
  EDNo: "",
  EDAprvTm: null,
  EDSndTm: null,
  EDCfTm: null,
  ED0209CreTm:null,
  ED0209SndTm:null,
  CDCreTm: null,
  CDCreAccId: null,
  CDAprvTm: null,
  CDSndTm: null,
  ED0309CreTm:null,
  ED0309SndTm:null,
  ED0409CreTm:null,
  ED0409SndTm:null,
  PayCstmTm: null,
  Rmk: null,
  ModDte: null,
  ModAccId: null,
  LogRmk: null,
}

export const initApproveCDData = {
  Bkg: "",
  EDNo: "",
  ContNos: [],
  ContNo: "",
  ISOCds: "",
  ISOCd: "",
  Port: "",
  RtnPlc: "",
  TakePlc: "",
  Vssl: "",
  Voy: "",
  IP_InvNo: "",
  CT: "",
  KGM: "",
  TotalKGM: "",
  CDFiles: [],
  Files: []
}