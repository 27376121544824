import { numberFormat } from "utils";


export const columns = [
  {
    field: "No",
    headerName: "No",
    width: 60,
  },
  {
    field: "NName",
    headerName: "พนักงานขับรถ",
    valueGetter: (params) => `${params.row.FName} ${params.row.LName} (${params.row.NName})`, 
    flex: 1,
  },
  {
    field: "NumJobOrd",
    headerName: "จำนวนเที่ยววิ่ง",
    align: "center",
    width: 100,
  },
  {
    field: "Tag1",
    headerName: "จำนวนเที่ยวตัด",
    align: "center",
    width: 100,
  },
  {
    field: "Prct",
    headerName: "อัตราส่วน",
    align: "center",
    valueGetter: (params) => (+params.row.Tag1/+params.row.NumJobOrd)*100,
    valueFormatter: (params) => `${numberFormat(params.value)}%`,
    width: 100,
  },
]